import { Card, CardContent, CardHeader } from "@mui/material";

interface SummaryPageCardProps {
  title: string;
  minHeight?: number;
  minWidth?: number;
}

export const SummaryPageCard: React.FC<SummaryPageCardProps> = ({
  title,
  minHeight,
  minWidth,
  children,
}) => {
  return (
    <Card style={{ minHeight, minWidth, zIndex: 1 }}>
      <CardHeader title={title} titleTypographyProps={{ variant: "h5" }} />
      <CardContent>{children}</CardContent>
    </Card>
  );
};
