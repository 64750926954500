import React, { useState } from "react";
import { CardContainer } from "../../components/CardContainer";
import { ConfigurationsTable } from "../../components/Tables/ConfigurationTable";
import { withRequireLogin } from "../../contexts/RequireLogin";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { hasPermission } from "../../utils/has-permission";
import {
  Role,
  useConfigurationTableMetricsSubscription,
} from "../../graphql/generated";
import { useRole } from "../../hooks/useRole";
import { RBACWrapper, withRBAC } from "../../contexts/RBAC";
import { withEENavBar } from "../../components/EENavBar";
import { Link, useLocation } from "react-router-dom";
import { DEFAULT_CONFIGURATION_TABLE_QUERY_PERIOD } from "../../components/MeasurementControlBar/MeasurementControlBar";
import { Box, Button, Stack, Typography } from "@mui/material";
import { PlusCircleIcon } from "../../components/Icons";
import { DeleteDialog } from "../../components/Tables/ConfigurationTable/DeleteDialog";

import mixins from "../../styles/mixins.module.scss";

export const CONFIGURATIONS_PAGE_QUERY_PARAM = "query";

export const ConfigurationsPageContent: React.FC = () => {
  // Selected is an array of names of configurations.
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const role = useRole();
  const location = useLocation();

  const initQuery =
    new URLSearchParams(location.search).get(CONFIGURATIONS_PAGE_QUERY_PARAM) ??
    "";

  const { data: configurationMetrics } =
    useConfigurationTableMetricsSubscription({
      variables: {
        period: DEFAULT_CONFIGURATION_TABLE_QUERY_PERIOD,
      },
    });

  return (
    <CardContainer>
      <Box height="calc(100vh - 200px)">
        <Stack height="100%">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            height="48px"
            marginBottom="16px"
          >
            <Typography variant="h5">Configurations</Typography>
            {selected.length > 0 && (
              <RBACWrapper requiredRole={Role.User}>
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  onClick={() => setDeleteOpen(true)}
                >
                  Delete {selected.length} Configuration
                  {selected.length > 1 && "s"}
                </Button>
              </RBACWrapper>
            )}

            {selected.length === 0 && (
              <RBACWrapper requiredRole={Role.User}>
                <Button
                  size="small"
                  component={Link}
                  to="/configurations/new"
                  variant="contained"
                  classes={{ root: mixins["float-right"] }}
                  startIcon={<PlusCircleIcon height={20} width={20} />}
                  data-testid="create-configuration-button"
                >
                  Create Configuration
                </Button>
              </RBACWrapper>
            )}
          </Stack>
          <Stack height="calc(100% - 64px)">
            <ConfigurationsTable
              allowSelection={hasPermission(Role.User, role)}
              onSelectionChange={(newSelection) => setSelected(newSelection)}
              enableDelete={true}
              initQuery={initQuery}
              urlQuerySearchParam={CONFIGURATIONS_PAGE_QUERY_PARAM}
              configurationMetrics={
                configurationMetrics?.overviewMetrics?.metrics
              }
              onboardingNoRowsOverlay
            />
          </Stack>
        </Stack>
      </Box>

      <DeleteDialog
        onClose={() => setDeleteOpen(false)}
        selected={selected}
        open={deleteOpen}
        onDeleteSuccess={() => setSelected([])}
      />
    </CardContainer>
  );
};

export const ConfigurationsPage = withRequireLogin(
  withRBAC(withEENavBar(ConfigurationsPageContent)),
);
