import { Stack, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PlusCircleIcon } from "../../components/Icons";
import { RBACWrapper } from "../../contexts/RBAC";
import { Role } from "../../graphql/generated";
import { LinkButton } from "../../components/LinkButton";

import styles from "./agent-page.module.scss";

export interface NoConfigViewProps {
  configsAvailable: boolean;
  setEditing: (editing: boolean) => void;
}

export const NoConfigView: React.FC<NoConfigViewProps> = ({
  configsAvailable,
  setEditing,
}) => {
  const navigate = useNavigate();
  return (
    <Stack
      direction={"row"}
      minHeight={500}
      className={styles.grid}
      justifyContent={"center"}
    >
      <Stack justifyContent={"center"} spacing={2}>
        <RBACWrapper requiredRole={Role.User}>
          <Button
            size="large"
            variant="contained"
            data-testid="create-configuration-button"
            startIcon={<PlusCircleIcon height={20} width={20} />}
            onClick={() => navigate("/configurations/new")}
          >
            Create Configuration
          </Button>
          {configsAvailable && (
            <LinkButton
              buttonClassName={styles["apply-configuration-button"]}
              data-testid="apply-configuration-button"
              onClick={() => setEditing(true)}
            >
              Apply Existing Configuration
            </LinkButton>
          )}
        </RBACWrapper>
      </Stack>
    </Stack>
  );
};
