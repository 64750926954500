import { useLocation, useNavigate } from "react-router-dom";
import { SubNavInfo, SubNavRoutes } from "./types";
import { useGetFeatureGateQuery } from "../../graphql/generated";
import { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";

import styles from "./sub-nav.module.scss";

// subNavInfo contains the information about the sub navigation for each route.
const subNavInfo: SubNavRoutes = {
  overview: {
    subNavItems: [
      {
        label: "Visualize",
        path: "/overview",
      },
      {
        label: "Summary",
        path: "/overview/summary",
      },
    ],
  },
};

export const SubNav: React.FC = () => {
  const location = useLocation();
  const info = getInfoForPath(location.pathname);
  const [enabled, setEnabled] = useState(false);

  const navigate = useNavigate();

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    // preserve the search params
    const to = newValue + location.search;
    navigate(to);
  };

  // SubNav is only used on the overview page for the feature gated
  // summary page.  For now we can hard code the feature gate id.
  useGetFeatureGateQuery({
    variables: {
      input: "overview-summary-page",
    },
    onCompleted: (data) => {
      setEnabled(data.enabled);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  if (info == null) {
    return null;
  }

  if (!enabled) {
    return null;
  }

  return (
    <Box className={styles.box}>
      <Tabs
        onChange={handleChange}
        value={location.pathname}
        classes={{ scroller: styles.scroller }}
      >
        {info.subNavItems.map((item) => (
          <Tab
            key={item.path}
            label={item.label}
            value={item.path}
            classes={{
              root: styles.tab,
              selected: styles.selected,
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

// getInfoForPath returns the SubNavInfo for the given path.  If
// there is no SubNavInfo for the given path it returns null
function getInfoForPath(path: string): SubNavInfo | null {
  // get the base path for the given path
  const basePath = path.split("/")[1];
  const info = subNavInfo[basePath];
  if (info == null) {
    return null;
  }
  return info;
}
