import { gql } from "@apollo/client";
import { Box, Button, Stack, Typography } from "@mui/material";
import { DataGridProProps, GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CardContainer } from "../../components/CardContainer";
import { ConfirmDeleteResourceDialog } from "../../components/ConfirmDeleteResourceDialog";
import { withEENavBar } from "../../components/EENavBar";
import { DEFAULT_CONFIGURATION_TABLE_QUERY_PERIOD } from "../../components/MeasurementControlBar/MeasurementControlBar";
import { RBACWrapper } from "../../components/RBACWrapper/RBACWrapper";
import { SearchBar } from "../../components/SearchBar";
import {
  DestinationsDataGrid,
  DestinationsTableField,
} from "../../components/Tables/DestinationsTable/DestinationsDataGrid";
import { EditDestinationDialog } from "../../components/Tables/DestinationsTable/EditDestinationDialog";
import { FailedDeleteDialog } from "../../components/Tables/DestinationsTable/FailedDeleteDialog";
import { withRBAC } from "../../contexts/RBAC";
import { withRequireLogin } from "../../contexts/RequireLogin";
import {
  ConfigurationTableMetricsSubscription,
  Role,
  useConfigurationTableMetricsSubscription,
  useDestinationsQuery,
} from "../../graphql/generated";
import { useRole } from "../../hooks/useRole";
import { ResourceKind, ResourceStatus } from "../../types/resources";
import { hasPermission } from "../../utils/has-permission";
import {
  MinimumDeleteResource,
  deleteResources,
} from "../../utils/rest/delete-resources";

gql`
  query Destinations($query: String, $filterUnused: Boolean) {
    destinations(query: $query, filterUnused: $filterUnused) {
      kind
      metadata {
        id
        name
        version
      }
      spec {
        type
      }
    }
  }
`;

export interface DestinationsPageContentProps
  extends Omit<DataGridProProps, "rows" | "columns"> {
  destinationsPage: boolean;
  // grid selection model
  selected: GridRowSelectionModel;
  // function to set grid selection model
  setSelected: (selected: GridRowSelectionModel) => void;
  columnFields?: DestinationsTableField[];
  minHeight?: string;
  maxHeight?: string;
  editingDestination: string | null;
  setEditingDestination: (dest: string | null) => void;
  allowSelection: boolean;
  configurationMetrics?: ConfigurationTableMetricsSubscription["overviewMetrics"]["metrics"];
}

export const DestinationsPageSubContent: React.FC<
  DestinationsPageContentProps
> = ({
  destinationsPage,
  selected,
  setSelected,
  columnFields,
  minHeight,
  maxHeight,
  editingDestination,
  setEditingDestination,
  allowSelection,
  configurationMetrics,
  ...dataGridProps
}) => {
  // Used to control the delete confirmation modal.
  const [open, setOpen] = useState<boolean>(false);

  const [failedDeletes, setFailedDeletes] = useState<ResourceStatus[]>([]);
  const [failedDeletesOpen, setFailedDeletesOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { data, refetch, error } = useDestinationsQuery({
    variables: {
      filterUnused: !destinationsPage,
    },
    fetchPolicy: "cache-and-network",
    refetchWritePolicy: "merge",
  });

  const debouncedRefetch = debounce((query: string) => {
    refetch({
      filterUnused: !destinationsPage,
      query: query,
    });
  }, 100);

  useEffect(() => {
    if (error != null) {
      enqueueSnackbar("There was an error retrieving data.", {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, error]);

  useEffect(() => {
    if (failedDeletes.length > 0) {
      setFailedDeletesOpen(true);
    }
  }, [failedDeletes, setFailedDeletesOpen]);

  function onAcknowledge() {
    setFailedDeletesOpen(false);
  }

  function handleEditSaveSuccess() {
    refetch();
    setEditingDestination(null);
  }

  async function deleteDestinations() {
    try {
      const items = resourcesFromSelected(selected);
      const { updates } = await deleteResources(items);
      setOpen(false);

      const failures = updates.filter((u) => u.status !== "deleted");
      setFailedDeletes(failures);

      refetch();
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to delete destinations.", { variant: "error" });
    }
  }

  return (
    <Stack height="100%">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        height="48px"
        marginBottom="16px"
      >
        <Typography variant="h5">Destinations</Typography>
        {destinationsPage && selected.length > 0 && (
          <RBACWrapper requiredRole={Role.User}>
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={() => setOpen(true)}
            >
              Delete {selected.length} Destination
              {selected.length > 1 && "s"}
            </Button>
          </RBACWrapper>
        )}
      </Stack>
      <Stack spacing={1} height="calc(100% - 64px)">
        <SearchBar
          suggestions={[]}
          onQueryChange={debouncedRefetch}
          suggestionQuery={""}
          initialQuery={""}
          placeholder={"Filter by destination name"}
        />
        <DestinationsDataGrid
          {...dataGridProps}
          loading={data == null}
          setSelectionModel={setSelected}
          selectionModel={selected}
          disableRowSelectionOnClick
          checkboxSelection
          onEditDestination={(name: string) => setEditingDestination(name)}
          columnFields={columnFields}
          minHeight={minHeight}
          maxHeight={maxHeight}
          destinations={data?.destinations}
          allowSelection={allowSelection}
          configurationMetrics={configurationMetrics}
          data-testid="destinations-table"
        />
      </Stack>
      <ConfirmDeleteResourceDialog
        open={open}
        onClose={() => setOpen(false)}
        onDelete={deleteDestinations}
        onCancel={() => setOpen(false)}
        action={"delete"}
      >
        <Typography>
          Are you sure you want to delete {selected.length} destination
          {selected.length > 1 && "s"}?
        </Typography>
      </ConfirmDeleteResourceDialog>

      <FailedDeleteDialog
        open={failedDeletesOpen}
        failures={failedDeletes}
        onAcknowledge={onAcknowledge}
        onClose={() => setFailedDeletesOpen(false)}
      />

      {editingDestination && (
        <EditDestinationDialog
          name={editingDestination}
          onCancel={() => setEditingDestination(null)}
          onSaveSuccess={handleEditSaveSuccess}
        />
      )}
    </Stack>
  );
};

export const DestinationsPageContent: React.FC = () => {
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);
  const [editingDestination, setEditingDestination] = useState<string | null>(
    null,
  );
  const location = useLocation();
  const role = useRole();

  const isDestinationsPage = location.pathname.includes("destinations");
  const { data: configurationMetrics } =
    useConfigurationTableMetricsSubscription({
      variables: {
        period: DEFAULT_CONFIGURATION_TABLE_QUERY_PERIOD,
      },
    });

  return (
    <CardContainer>
      <Box height="calc(100vh - 200px)">
        <DestinationsPageSubContent
          allowSelection={hasPermission(Role.Admin, role)}
          destinationsPage={isDestinationsPage}
          selected={selected}
          setSelected={setSelected}
          editingDestination={editingDestination}
          setEditingDestination={setEditingDestination}
          configurationMetrics={configurationMetrics?.overviewMetrics?.metrics}
          initialState={{
            sorting: {
              sortModel: [{ field: DestinationsTableField.TYPE, sort: "asc" }],
            },
          }}
          maxHeight="100%"
          minHeight="0"
        />
      </Box>
    </CardContainer>
  );
};

export const DestinationsPage = withRequireLogin(
  withRBAC(withEENavBar(DestinationsPageContent)),
);

export function resourcesFromSelected(
  selected: GridRowSelectionModel,
  kind: ResourceKind = ResourceKind.DESTINATION,
): MinimumDeleteResource[] {
  return selected.reduce<MinimumDeleteResource[]>((prev, cur) => {
    if (typeof cur !== "string") {
      console.error(`Unexpected type for GridRowId: ${typeof cur}"`);
      return prev;
    }
    const name = cur;

    prev.push({ kind, metadata: { name } });
    return prev;
  }, []);
}
