import { Chip } from "@mui/material";
import { classes } from "../../utils/styles";

import styles from "./count-chip.module.scss";

interface CountChipProps {
  count: number;
  className?: string;
}

/**
 * CountChip displays a number in a round, styled chip.
 */
export const CountChip: React.FC<CountChipProps> = ({ count, className }) => {
  return (
    <Chip
      classes={{
        root: classes([styles.root, className]),
        label: styles.label,
      }}
      size="small"
      label={count}
    />
  );
};
