import { gql } from "@apollo/client";
import {
  Stack,
  Alert,
  AlertTitle,
  Button,
  Tooltip,
  Box,
  Tab,
  Tabs,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CardContainer,
  TwoStepBreadcrumb,
} from "../../components/CardContainer";
import { ManageConfigForm } from "../../components/ManageConfigForm";
import { AgentTable } from "../../components/Tables/AgentTable";
import {
  PipelineType,
  useAgentDetailsChangedSubscription,
  useGetAgentPageConfigurationsQuery,
  useGetAgentQuery,
  useGetConfigurationQuery,
  useGetLatestMeasurementIntervalQuery,
} from "../../graphql/generated";
import { useSnackbar } from "notistack";
import {
  hasAgentFeature,
  AgentFeatures,
  AgentStatus,
} from "../../types/agents";
import { withRequireLogin } from "../../contexts/RequireLogin";
import { RecentTelemetryDialog } from "../../components/RecentTelemetryDialog/RecentTelemetryDialog";
import { PipelineGraph } from "../../components/PipelineGraph/PipelineGraph";
import { Config } from "../../components/ManageConfigForm/types";
import {
  DEFAULT_PERIOD,
  DEFAULT_TELEMETRY_TYPE,
  MeasurementControlBar,
} from "../../components/MeasurementControlBar";
import { UpgradeError } from "../../components/UpgradeError";
import { withEENavBar } from "../../components/EENavBar";
import { withRBAC } from "../../contexts/RBAC";
import { YamlEditor } from "../../components/YamlEditor";
import { platformIsContainer } from "./InstallPage/InstallWizard/utils";
import { AgentHealthTab } from "./AgentHealthTab";
import { NoConfigView } from "./NoConfigView";
import { AgentTableControls } from "./AgentTableControls";
import { filterConfigsByPlatform } from "../../components/ManageConfigForm/util";

import { classes } from "../../utils/styles";
import styles from "./agent-page.module.scss";
import mixins from "../../styles/mixins.module.scss";
import { SnapshotContextProvider } from "../../components/SnapShotConsole/SnapshotContext";

gql`
  query GetAgent($agentId: ID!) {
    agent(id: $agentId) {
      id
      name
      architecture
      operatingSystem
      labels
      hostName
      platform
      version
      macAddress
      remoteAddress
      status
      connectedAt
      disconnectedAt
      errorMessage
      configuration {
        Collector
      }
      configurationResource {
        metadata {
          id
          version
          name
        }
        rendered
      }
      upgrade {
        status
        version
        error
      }
      upgradeAvailable
      features
    }
  }
  query GetAgentPageConfigurations {
    configurations {
      configurations {
        metadata {
          id
          name
          version
          labels
        }
        spec {
          raw
        }
      }
    }
  }

  subscription AgentDetailsChanged($agentID: String) {
    agentDetailsChanged(agentID: $agentID)
  }
`;

export const AgentPageContent: React.FC = () => {
  const { id } = useParams();
  const snackbar = useSnackbar();
  const [recentTelemetryOpen, setRecentTelemetryOpen] = useState(false);
  const [selectedTelemetry, setSelectedTelemetry] = useState(
    DEFAULT_TELEMETRY_TYPE,
  );
  const [period, setPeriod] = useState<string>();
  const [measurementPeriods, setMeasurementPeriods] = useState<string[]>();
  const [tab, setTab] = useState<"health" | "configuration">("health");

  const { data, refetch } = useGetAgentQuery({
    variables: { agentId: id ?? "" },
    fetchPolicy: "network-only",
    onCompleted(data) {
      // If there's no configuration, or the user detaches the config,
      // display the "configuration" tab.
      if (!data?.agent?.configurationResource) {
        setTab("configuration");
      }
    },
  });

  useAgentDetailsChangedSubscription({
    variables: { agentID: id ?? "" },
    onData() {
      refetch();
    },
  });

  const { data: configData } = useGetAgentPageConfigurationsQuery({
    fetchPolicy: "network-only",
  });

  // set the default value for the configuration dropdown once we have the data
  useEffect(() => {
    setSelectedConfig(
      configData?.configurations.configurations.find(
        (c) =>
          c.metadata.name === data?.agent?.configurationResource?.metadata.name,
      ),
    );
  }, [
    configData?.configurations.configurations,
    data?.agent?.configurationResource?.metadata.name,
  ]);

  const navigate = useNavigate();

  const currentConfig = useMemo(() => {
    if (data?.agent == null || configData?.configurations == null) {
      return null;
    }

    const configName = data.agent.configurationResource?.metadata.name;
    if (configName == null) {
      return null;
    }

    return configData.configurations.configurations.find(
      (c) => c.metadata.name === configName,
    );
  }, [data?.agent, configData?.configurations]);

  const currentConfigName = useMemo(() => {
    if (data?.agent == null || configData?.configurations == null) {
      return null;
    }

    const configName = data.agent.configurationResource?.metadata.name;
    if (configName == null) {
      return null;
    }
    return configName;
  }, [data?.agent, configData?.configurations]);

  // Get Configuration Data

  const configQuery = useGetConfigurationQuery({
    variables: { name: currentConfigName ?? "" },
    fetchPolicy: "cache-and-network",
  });

  useGetLatestMeasurementIntervalQuery({
    skip:
      configQuery.data?.configuration != null &&
      configQuery.data.configuration.spec.raw !== "",
    variables: {
      name: currentConfigName ?? "",
    },
    onCompleted(data) {
      if (data.configuration?.spec?.measurementInterval != null) {
        switch (data.configuration.spec.measurementInterval) {
          case "1m":
            setMeasurementPeriods(["1m", "5m", "1h", "24h"]);
            setPeriod("1m");
            break;
          case "5m":
            setMeasurementPeriods(["5m", "1h", "24h"]);
            setPeriod("5m");
            break;
          case "1h":
            setMeasurementPeriods(["1h", "24h"]);
            setPeriod("1h");
            break;
          case "24h":
            setMeasurementPeriods(["24h"]);
            setPeriod("24h");
            break;
          default:
            setMeasurementPeriods(["10s", "1m", "5m", "1h", "24h"]);
            setPeriod(DEFAULT_PERIOD);
        }
      }
    },
  });

  const configGraph = configQuery.data?.configuration;
  const [editing, setEditing] = useState(false);

  const [selectedConfig, setSelectedConfig] = useState<Config | undefined>();
  const rawConfig = configGraph?.spec?.raw;

  const viewTelemetryButton = useMemo(() => {
    if (currentConfig?.spec.raw !== "") {
      return null;
    }

    let disableReason: string | null = null;

    if (
      data?.agent == null ||
      data.agent?.status === AgentStatus.DISCONNECTED
    ) {
      disableReason = "Cannot view recent telemetry, agent is disconnected.";
    }

    if (
      disableReason == null &&
      !hasAgentFeature(data!.agent!, AgentFeatures.AGENT_SUPPORTS_SNAPSHOTS)
    ) {
      disableReason =
        "Upgrade Agent to v1.8.0 or later to view recent telemetry.";
    }

    if (disableReason == null && data?.agent?.configurationResource == null) {
      disableReason =
        "Cannot view recent telemetry for an agent with an unmanaged configuration.";
    }

    if (disableReason != null) {
      return (
        <Tooltip title={disableReason} disableInteractive>
          <div style={{ display: "inline-block" }}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setRecentTelemetryOpen(true)}
              disabled
            >
              View Recent Telemetry
            </Button>
          </div>
        </Tooltip>
      );
    } else {
      return (
        <Button
          variant="outlined"
          size="small"
          onClick={() => setRecentTelemetryOpen(true)}
        >
          View Recent Telemetry
        </Button>
      );
    }
  }, [currentConfig?.spec.raw, data]);

  // Here we use the distinction between graphql returning null vs undefined.
  // If the agent is null then this agent doesn't exist, redirect to agents.
  if (data?.agent === null) {
    navigate("/agents");
    return null;
  }

  // Data is loading, return null for now.
  if (data === undefined || data.agent == null || configData === undefined) {
    return null;
  }

  const upgradeError = data.agent?.upgrade?.error;
  // Only display configurations whose platform label matches the agent
  const matchingConfigs = filterConfigsByPlatform(
    configData.configurations.configurations,
    data.agent.platform ?? "",
  );

  return (
    <>
      <ManageConfigForm
        agent={data.agent}
        configurations={matchingConfigs}
        editing={editing}
        setEditing={setEditing}
        selectedConfig={selectedConfig}
        setSelectedConfig={setSelectedConfig}
      />
      <TwoStepBreadcrumb
        navLink="/agents"
        navLabel="Agents"
        current={data.agent.name}
      />
      <CardContainer>
        <AgentTableControls
          setEditing={setEditing}
          configsAvailable={matchingConfigs.length > 0}
          isContainerized={platformIsContainer(data.agent.platform ?? "")}
          supportsRemoteConfig={hasAgentFeature(
            data.agent,
            AgentFeatures.AGENT_SUPPORTS_REMOTE_CONFIGURATION,
          )}
        >
          {viewTelemetryButton}
        </AgentTableControls>

        <AgentTable agent={data.agent} />

        {upgradeError && (
          <Box marginTop="8px">
            <UpgradeError
              agentId={data.agent.id}
              upgradeError={data.agent?.upgrade?.error}
              onClearFailure={() => {
                snackbar.enqueueSnackbar("Oops! Something went wrong.", {
                  variant: "error",
                  key: "clear-upgrade-error",
                });
              }}
              onClearSuccess={() => {
                refetch();
              }}
            />
          </Box>
        )}
      </CardContainer>

      <CardContainer>
        <TabContext value={tab}>
          <Box className={styles.box}>
            <Tabs
              value={tab}
              onChange={(_e, v) => setTab(v)}
              classes={{ scroller: styles.scroller }}
            >
              <Tab value={"health"} label="Health" />
              <Tab value={"configuration"} label="Configuration" />
            </Tabs>
          </Box>
          <TabPanel value={"health"} sx={{ padding: 0 }}>
            <AgentHealthTab
              errorMessage={data.agent.errorMessage}
              id={id || ""}
            />
          </TabPanel>
          <TabPanel value={"configuration"} sx={{ padding: 0 }}>
            <Stack>
              {data.agent.errorMessage && (
                <Alert
                  severity="error"
                  className={classes([mixins["mt-5"], mixins["mb-0"]])}
                >
                  <AlertTitle>Error</AlertTitle>
                  {data.agent.errorMessage}
                </Alert>
              )}
              {rawConfig && (
                <YamlEditor value={rawConfig} readOnly limitHeight />
              )}
              {!rawConfig && configGraph && (
                <div className={styles.grid}>
                  <MeasurementControlBar
                    telemetry={selectedTelemetry}
                    onTelemetryTypeChange={setSelectedTelemetry}
                    period={period ?? DEFAULT_PERIOD}
                    onPeriodChange={setPeriod}
                    periods={measurementPeriods}
                  />
                  <PipelineGraph
                    agentId={data.agent.id}
                    configurationName={`${data.agent.configurationResource?.metadata.name}:${data.agent.configurationResource?.metadata.version}`}
                    selectedTelemetry={selectedTelemetry}
                    period={period ?? DEFAULT_PERIOD}
                    readOnly
                  />
                </div>
              )}
              {!rawConfig && !configGraph && (
                <NoConfigView
                  setEditing={setEditing}
                  configsAvailable={matchingConfigs.length > 0}
                />
              )}
            </Stack>
          </TabPanel>
        </TabContext>
      </CardContainer>

      {currentConfig?.spec.raw === "" && (
        <SnapshotContextProvider
          pipelineType={PipelineType.Logs}
          agentID={id!}
          skipQuery={!recentTelemetryOpen}
        >
          <RecentTelemetryDialog
            open={recentTelemetryOpen}
            onClose={() => setRecentTelemetryOpen(false)}
            agentID={id!}
          />
        </SnapshotContextProvider>
      )}
    </>
  );
};

export const AgentPage = withRequireLogin(
  withRBAC(withEENavBar(() => <AgentPageContent />)),
);
