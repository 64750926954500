import { gql } from "@apollo/client";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useGetConfigurationSummaryQuery } from "../../graphql/generated";
import { SparkLineChart } from "@mui/x-charts";
import { formatBytes } from "../../utils/graph/utils";
import {
  SummaryInterval,
  useSummaryPageContext,
} from "../../pages/overview/SummaryPage/SummaryPageContext";
import { enqueueSnackbar } from "notistack";
import { SearchLink } from "../SearchLink";
import { xMin } from "../DataSummaryGraph";

import colors from "../../styles/colors";

gql`
  query GetConfigurationSummary(
    $period: String!
    $interval: String!
    $telemetryType: String!
  ) {
    configurationSummary(
      period: $period
      interval: $interval
      telemetryType: $telemetryType
    ) {
      name
      totalIngest
      totalEgress
      dataset {
        timestamp
        s0Value
        d1Value
      }
    }
  }
`;

export const ConfigurationSummarySection: React.FC = () => {
  const { period, measurementInterval, telemetryType } =
    useSummaryPageContext();
  const { data, loading } = useGetConfigurationSummaryQuery({
    variables: {
      period: period,
      interval: measurementInterval,
      telemetryType: telemetryType!,
    },
    pollInterval:
      measurementInterval === SummaryInterval.OneHour ? 60 * 1000 : 0,
    skip: telemetryType === undefined,
    onError: (error) => {
      enqueueSnackbar("Error loading configuration summary", {
        variant: "error",
      });
      console.error(error);
    },
  });

  return (
    <>
      {data == null || loading ? (
        <Stack height={200} alignItems={"center"} justifyContent={"center"}>
          <CircularProgress size={100} />
        </Stack>
      ) : (
        <>
          {data?.configurationSummary.length === 0 ? (
            <Typography variant="subtitle1">No data</Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Configuration</TableCell>
                    <TableCell align="left">Data in</TableCell>
                    <TableCell align="left">Total collected</TableCell>
                    <TableCell align="left">Data out</TableCell>
                    <TableCell align="left">Total sent</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.configurationSummary.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell>
                        <SearchLink
                          path={`/configurations/${row.name}`}
                          displayName={row.name}
                        />
                      </TableCell>

                      {row.dataset.length < 2 ? (
                        <TableCell style={{ width: "400px" }}></TableCell>
                      ) : (
                        <TableCell style={{ width: "400px" }}>
                          {row.dataset.length === 0 ? null : (
                            <SparkLineChart
                              valueFormatter={formatBytes}
                              data={row.dataset.map((point) => point.s0Value)}
                              height={25}
                              colors={[colors.black]}
                              showTooltip
                              showHighlight
                              xAxis={{
                                scaleType: "time",
                                data: row.dataset.map(
                                  (point) => new Date(point.timestamp),
                                ),
                                valueFormatter: (date: Date) => {
                                  return date.toLocaleTimeString();
                                },
                                min: xMin(measurementInterval),
                              }}
                            />
                          )}
                        </TableCell>
                      )}
                      <TableCell style={{ width: "150px" }}>
                        {formatBytes(row.totalIngest)}
                      </TableCell>
                      {row.dataset.length < 2 ? (
                        <TableCell style={{ width: "400px" }}></TableCell>
                      ) : (
                        <TableCell style={{ width: "400px" }}>
                          {row.dataset.length === 0 ? null : (
                            <SparkLineChart
                              valueFormatter={formatBytes}
                              data={row.dataset.map((point) => point.d1Value)}
                              height={25}
                              colors={[colors.pixelPointBlue]}
                              showTooltip
                              showHighlight
                              xAxis={{
                                scaleType: "time",
                                data: row.dataset.map(
                                  (point) => new Date(point.timestamp),
                                ),
                                valueFormatter: (date: Date) => {
                                  return date.toLocaleTimeString();
                                },
                                min: xMin(measurementInterval),
                              }}
                            />
                          )}
                        </TableCell>
                      )}
                      <TableCell style={{ width: "120px" }}>
                        {formatBytes(row.totalEgress)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </>
  );
};
