import * as pako from "pako";

type PublicLicense = {
  id: string;
  type: string;
  owner: string;
  email: string | null;
};

export function decodeLicense(key: string): PublicLicense | null {
  try {
    // Decode base64
    var decodedKey = atob(key);
    var uint8Array = new Uint8Array(decodedKey.length);
    for (var i = 0; i < decodedKey.length; i++) {
      uint8Array[i] = decodedKey.charCodeAt(i);
    }

    // Decompress using pako
    var decompressedData = pako.inflate(uint8Array, { to: "string" });

    // Parse JSON
    var license = JSON.parse(decompressedData);

    return license;
  } catch (error) {
    return null;
  }
}
