import { useApolloClient } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogProps,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { isEmpty, trim } from "lodash";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { CreateAccountPayload } from "../../types/rest";
import { createNewAccount } from "../../utils/rest/new-account";

import styles from "./new-account-dialog.module.scss";

interface NewAccountDialogProps extends DialogProps {
  onSuccess: () => void;
  onClose: (e: {}, reason: "backdropClick" | "escapeKeyDown") => void;
}

export const NewAccountDialog: React.FC<NewAccountDialogProps> = ({
  onSuccess,
  onClose,
  ...dialogProps
}) => {
  const [details, setDetails] = useState<CreateAccountPayload>({
    displayName: "",
  });
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  function handleDisplayNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setDetails({ ...details, displayName: e.target.value });
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    try {
      const resp = await createNewAccount(details);

      if (resp.status !== 201) {
        throw new Error("Failed to create account");
      }

      // Clear the form
      setDetails({ displayName: "" });
      // Clear the apollo cache
      client.resetStore();
      // Call the onSuccess function to close the dialog.
      onSuccess();
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Error creating account", {
        variant: "error",
        key: "create-account-error",
      });
    }
  };

  function handleClose(e: {}, reason: "backdropClick" | "escapeKeyDown") {
    setDetails({
      displayName: "",
    });
    onClose(e, reason);
  }

  function stopPropagation(e: React.KeyboardEvent) {
    e.key !== "Escape" && e.stopPropagation();
  }

  return (
    <Dialog
      {...dialogProps}
      onClose={handleClose}
      classes={{ paper: styles.dialog }}
    >
      <Stack
        alignItems="center"
        spacing={2}
        paddingY={3}
        component="form"
        onSubmit={handleSubmit}
      >
        <Typography variant="h6" fontWeight={700}>
          Account Details
        </Typography>

        <TextField
          size="small"
          label="Account Name"
          value={details.displayName}
          autoComplete="off"
          autoFocus
          onChange={handleDisplayNameChange}
          // We need to prevent some key events from propagating to
          // the open Settings Menu listeners so the textfield doesn't lose focus.
          onKeyDown={stopPropagation}
        />

        <Button
          type="submit"
          size="large"
          variant="contained"
          disabled={isEmpty(trim(details.displayName))}
        >
          Continue
        </Button>
      </Stack>
    </Dialog>
  );
};
