import { Stack, Typography } from "@mui/material";

/**
 * Role Info provides a description of the different roles that can be assigned.
 */
export const RoleInfo: React.FC = () => {
  return (
    <Stack spacing={2}>
      <div>
        <Typography fontWeight={600}>Admin</Typography>
        <Typography variant="body2" maxWidth={400}>
          Admins can view, edit, and deploy configurations as well as send
          invitations and manage users.
        </Typography>
      </div>

      <div>
        <Typography fontWeight={600}>User</Typography>
        <Typography variant="body2" maxWidth={400}>
          Users can perform all actions as an admin except for sending
          invitations and managing users.
        </Typography>
      </div>

      <div>
        <Typography fontWeight={600}>Viewer</Typography>
        <Typography variant="body2" maxWidth={400}>
          Viewers can read and visit all pages but they cannot edit or deploy
          configurations, send invitations, or manage users.
        </Typography>
      </div>
    </Stack>
  );
};
