import { NavBar } from "../NavBar";
import { SubNav } from "../SubNav";

// withEENavBar renders the children with a NavBar and SubNav.
// It renders the children in a div with global class "content".
export function withEENavBar(FC: React.FC): React.FC {
  return () => (
    <>
      <NavBar />
      <SubNav />
      <div className="content">
        <FC />
      </div>
    </>
  );
}

// withNoStyleEENavBar renders the children with a NavBar and SubNav.
// It renders the children without a div with global class "content".
export function withNoStyleEENavBar(FC: React.FC): React.FC {
  return () => (
    <>
      <NavBar />
      <SubNav />
      <FC />
    </>
  );
}
