import { Card, Stack } from "@mui/material";
import { ProcessorIcon } from "../Icons";
import { usePipelineGraph } from "../PipelineGraph/PipelineGraphContext";
import { Handle, Position } from "reactflow";
import { CountChip } from "../CountChip";

import styles from "./cards.module.scss";

interface ProcessorCardProps {
  processorCount: number;
  resourceType: "source" | "destination";
  resourceIndex: number;
}

export const ProcessorCard: React.FC<ProcessorCardProps> = ({
  processorCount,
  resourceType,
  resourceIndex,
}) => {
  const { editProcessors } = usePipelineGraph();

  return (
    <>
      <Card
        className={styles["processor-card"]}
        onClick={() => editProcessors(resourceType, resourceIndex)}
      >
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
        <Stack
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <ProcessorIcon />
        </Stack>
      </Card>
      {processorCount > 0 && (
        <CountChip className={styles["count-chip"]} count={processorCount} />
      )}
    </>
  );
};
