import { GetConfigurationMetricsQuery } from "../../../graphql/generated";
import { ALL_DESTINATIONS_VALUE } from "./SummaryPageContext";

interface getInitialInfoValue {
  topDestination: string;
  topTelemetryType: "logs" | "metrics" | "traces";
}

interface destinationToValues {
  [key: string]: { logs: number; metrics: number; traces: number };
}

export class ConfigurationMetricsData implements GetConfigurationMetricsQuery {
  configurationMetrics: GetConfigurationMetricsQuery["configurationMetrics"];
  private destinationInfo: destinationToValues = {};

  constructor(
    configurationMetrics: GetConfigurationMetricsQuery["configurationMetrics"],
  ) {
    this.configurationMetrics = { ...configurationMetrics };
  }

  /**
   * getInitialInfo returns the initial settings for the SummaryPageContext.
   * It returns the telemetry type and name of the destination with the highest
   * measurement throughput.
   * @returns {topDestination: string, topTelemetryType: "logs" | "metrics" | "traces"}
   */
  getInitialInfo(): getInitialInfoValue {
    for (const metric of this.configurationMetrics.metrics) {
      // We want the metrics in the form of `destination/<name>`
      if (
        !metric.nodeID.startsWith("destination/") ||
        metric.nodeID.endsWith("/processors")
      ) {
        continue;
      }

      const destinationSlug = metric.nodeID.split("/")[1];
      const destinationName = extractDestinationName(destinationSlug);
      const value = metric.value;

      // Record in destinationToValueMap
      if (this.destinationInfo[destinationName] === undefined) {
        this.destinationInfo[destinationName] = {
          logs: 0,
          metrics: 0,
          traces: 0,
        };
      }

      // Record that the value in the telemetry type
      switch (metric.pipelineType) {
        case "logs":
          this.destinationInfo[destinationName].logs! += value;
          break;
        case "metrics":
          this.destinationInfo[destinationName].metrics! += value;
          break;
        case "traces":
          this.destinationInfo[destinationName].traces! += value;
          break;
      }
    }

    const entries = Object.entries(this.destinationInfo);
    if (entries.length === 0) {
      return {
        topDestination: ALL_DESTINATIONS_VALUE,
        topTelemetryType: "logs",
      };
    }

    let topDestination = "";
    let topTelemetryType: "logs" | "metrics" | "traces" = "logs";
    // Start with a maxValue of -1 so that there must be a destination
    // that satisfies the topDestination condition.
    let maxValue = -1;
    for (const [destination, info] of entries) {
      const typeToValue = Object.entries(info);
      typeToValue.sort((a, b) => b[1] - a[1]);
      const value = typeToValue[0][1];
      if (value > maxValue) {
        maxValue = value;
        topDestination = destination;
        topTelemetryType = typeToValue[0][0] as "logs" | "metrics" | "traces";
      }
    }

    return { topDestination, topTelemetryType: topTelemetryType };
  }
}

/**
 * extractDestinationName returns the destination name from the given
 * destination slug.  These have the form <destination_name>-<index>.
 * @param s
 * @returns
 */
export function extractDestinationName(s: string) {
  var parts = s.split("-");
  parts.pop();
  return parts.join("-");
}
