import {
  K8sPlatformIcon,
  LinuxPlatformIcon,
  MacOSPlatformIcon,
  OpenShiftPlatformIcon,
  WindowsPlatformIcon,
} from "../components/Icons";

/**
 * Platforms that agents can be installed on. Used in install wizards and config details, but NOT on agent details,
 * this is because the agent could report any platform so we handle that more generally than these enumerated options.
 */
export enum Platform {
  KubernetesDaemonset = "kubernetes-daemonset",
  KubernetesDeployment = "kubernetes-deployment",
  KubernetesGateway = "kubernetes-gateway",
  Linux = "linux",
  macOS = "macos",
  OpenShiftDaemonset = "openshift-daemonset",
  OpenShiftDeployment = "openshift-deployment",
  Windows = "windows",
}

export const getConfigPlatformLabel = (platform: Platform): string => {
  switch (platform) {
    case Platform.Linux:
      return "Linux";
    case Platform.KubernetesDaemonset:
      return "Kubernetes Node";
    case Platform.KubernetesDeployment:
      return "Kubernetes Cluster";
    case Platform.KubernetesGateway:
      return "Kubernetes Gateway";
    case Platform.macOS:
      return "macOS";
    case Platform.OpenShiftDaemonset:
      return "Openshift Node";
    case Platform.OpenShiftDeployment:
      return "OpenShift Cluster";
    case Platform.Windows:
      return "Windows";
    default:
      return platform;
  }
};

export const getConfigPlatformIcon = (platform: Platform) => {
  switch (platform) {
    case Platform.Linux:
      return <LinuxPlatformIcon />;
    case Platform.KubernetesDaemonset:
      return <K8sPlatformIcon />;
    case Platform.KubernetesDeployment:
      return <K8sPlatformIcon />;
    case Platform.KubernetesGateway:
      return <K8sPlatformIcon />;
    case Platform.macOS:
      return <MacOSPlatformIcon />;
    case Platform.OpenShiftDaemonset:
      return <OpenShiftPlatformIcon />;
    case Platform.OpenShiftDeployment:
      return <OpenShiftPlatformIcon />;
    case Platform.Windows:
      return <WindowsPlatformIcon />;
    default:
      return undefined;
  }
};

// For agent details
export const getAgentPlatformIcon = (platform: string) => {
  switch (platform) {
    case "linux":
      return <LinuxPlatformIcon />;
    case "kubernetes-daemonset":
      return <K8sPlatformIcon />;
    case "kubernetes-deployment":
      return <K8sPlatformIcon />;
    case "kubernetes-gateway":
      return <K8sPlatformIcon />;
    case "darwin":
      return <MacOSPlatformIcon />;
    case "openshift-daemonset":
      return <OpenShiftPlatformIcon />;
    case "openshift-deployment":
      return <OpenShiftPlatformIcon />;
    case "windows":
      return <WindowsPlatformIcon />;
    default:
      return undefined;
  }
};
