import React from "react";
import ReactDOM from "react-dom";
import { LicenseInfo } from "@mui/x-license-pro";
import { App } from "./app";

import "./styles/globals.scss";

/*
Here we are setting the MUI Premium license key.  We expect it
to be bundled with the served JS, but thats alright.  From MUI:

   The license key is checked without making any network
   requests—it's designed to be public. It's expected that
   the license key will be exposed in a JavaScript bundle;
   we simply ask licensed users not to actively publicize
   their license key.

This works around having to set the license key at build time
and for development.
*/
LicenseInfo.setLicenseKey(
  "a6399cf5c7cee941a9d41fc82d81374cTz03NzQ4OCxFPTE3Mjk5NTY1MDcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
