import {
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  Box,
} from "@mui/material";
import { PipelineType } from "../../graphql/generated";
import { RefreshIcon } from "../Icons";
import { useSnapshot } from "../SnapShotConsole/SnapshotContext";

const TOGGLE_WIDTH = 100;

export interface ProcessorTelemetrySwitcherProps {
  setWorkingQuery: (query: string) => void;
}
export const ProcessorTelemetrySwitcher: React.FC<
  ProcessorTelemetrySwitcherProps
> = ({ setWorkingQuery }) => {
  const { pipelineType, setPipelineType, refresh, loading, setSearchQuery } =
    useSnapshot();
  return (
    <Stack direction={"row"} spacing={2} style={{ marginTop: 5 }}>
      <ToggleButtonGroup
        size={"small"}
        color="primary"
        value={pipelineType}
        exclusive
        onChange={(_, value) => {
          if (value != null) {
            setPipelineType(value);
            setWorkingQuery("");
            setSearchQuery("");
          }
        }}
        aria-label="Telemetry Type"
      >
        <ToggleButton sx={{ width: TOGGLE_WIDTH }} value={PipelineType.Logs}>
          Logs
        </ToggleButton>
        <ToggleButton sx={{ width: TOGGLE_WIDTH }} value={PipelineType.Metrics}>
          Metrics
        </ToggleButton>
        <ToggleButton sx={{ width: TOGGLE_WIDTH }} value={PipelineType.Traces}>
          Traces
        </ToggleButton>
      </ToggleButtonGroup>
      <Box flexGrow={1} />
      <IconButton color={"primary"} disabled={loading} onClick={refresh}>
        <RefreshIcon />
      </IconButton>
    </Stack>
  );
};
