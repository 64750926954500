import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Kind, useGetProcessorWithTypeQuery } from "../../../graphql/generated";
import { BPProcessor } from "../../../utils/classes";
import { EditResourceDialog } from "../../ResourceDialog/EditResourceDialog";
import { gql } from "@apollo/client";

gql`
  query getProcessorWithType($name: String!) {
    processorWithType(name: $name) {
      processor {
        metadata {
          name
          version
          id
          labels
          version
          displayName
        }
        spec {
          type
          parameters {
            name
            value
          }
          disabled
        }
      }
      processorType {
        metadata {
          id
          name
          version
          icon
          description
          additionalInfo {
            message
            documentation {
              text
              url
            }
          }
          resourceDocLink
        }
        spec {
          parameters {
            label
            name
            description
            required
            type
            default
            relevantIf {
              name
              operator
              value
            }
            documentation {
              text
              url
            }
            advancedConfig
            validValues
            options {
              multiline
              creatable
              trackUnchecked
              sectionHeader
              subHeader
              horizontalDivider
              gridColumns
              labels
              metricCategories {
                label
                column
                metrics {
                  name
                  description
                  kpi
                }
              }
              password
              sensitive
            }
          }
        }
      }
    }
  }
`;

interface EditProcessorProps {
  name: string;
  onSaveSuccess: () => void;
  onCancel: () => void;
  readOnly?: boolean;
}

export const EditProcessorDialog: React.FC<EditProcessorProps> = ({
  name,
  readOnly,
  onSaveSuccess,
  onCancel,
}) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { data, error } = useGetProcessorWithTypeQuery({
    variables: {
      name,
    },
    fetchPolicy: "network-only",
  });

  // Communicate error if present.
  useEffect(() => {
    if (error != null) {
      enqueueSnackbar(`Error retrieving data for processor ${name}.`, {
        variant: "error",
      });
      console.error(error);
    }
  }, [enqueueSnackbar, error, name]);

  // Open dialog when we have data.
  useEffect(() => {
    if (data != null) {
      setOpen(true);
    }
  }, [data, setOpen]);

  async function handleSave(values: { [key: string]: any }) {
    const processor = new BPProcessor(data?.processorWithType.processor!);
    processor.setParamsFromMap(values);

    processor.metadata.displayName = values.displayName;

    try {
      await processor.apply();
      enqueueSnackbar("Saved processor!", { variant: "success" });
      setOpen(false);
      onSaveSuccess();
    } catch (err) {
      enqueueSnackbar("Error saving processor", { variant: "error" });
      console.error(err);
    }
  }

  function handleClose() {
    setOpen(false);
    onCancel();
  }

  return (
    <EditResourceDialog
      fullWidth
      resourceTypeDisplayName={
        data?.processorWithType.processor?.metadata.name ?? ""
      }
      displayName={
        data?.processorWithType.processor?.metadata.displayName ?? ""
      }
      description={
        data?.processorWithType.processorType?.metadata.description ?? ""
      }
      onSave={handleSave}
      onClose={handleClose}
      onCancel={handleClose}
      additionalInfo={
        data?.processorWithType.processorType?.metadata.additionalInfo
      }
      resourceDocLink={
        data?.processorWithType.processorType?.metadata.resourceDocLink ?? ""
      }
      parameters={data?.processorWithType.processor?.spec.parameters ?? []}
      parameterDefinitions={
        data?.processorWithType.processorType?.spec.parameters ?? []
      }
      paused={data?.processorWithType.processor?.spec.disabled}
      kind={Kind.Processor}
      open={open}
      readOnly={readOnly}
    />
  );
};
