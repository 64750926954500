import { Stack } from "@mui/material";
import styles from "../snap-shot-console.module.scss";

interface DetailsContainerProps {}

export const DetailsContainer: React.FC<DetailsContainerProps> = ({
  children,
}) => {
  return (
    <div className={styles.fields}>
      <div>
        <Stack paddingX={4} paddingY={2}>
          {children}
        </Stack>
      </div>
    </div>
  );
};
