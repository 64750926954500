import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useWizard } from "../../components/Wizard/WizardContext";
import { AppOnboardingFormValues } from "./AppOnboarding";
import { useNavigate } from "react-router-dom";
import { gql } from "@apollo/client";
import { useGetCurrentUserQuery } from "../../graphql/generated";
import { useSnackbar } from "notistack";

import styles from "./app-onboarding.module.scss";

gql`
  query getCurrentUser {
    user {
      metadata {
        id
        name
        version
        displayName
      }
      spec {
        activeAccount
      }
    }
  }
`;

export const NameAccountStep: React.FC = () => {
  const { formValues, setValues, goToStep } =
    useWizard<AppOnboardingFormValues>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  function handleAccountFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValues({ accountName: e.target.value });
  }

  const { data } = useGetCurrentUserQuery({
    onError: (e) => {
      console.error(e);
      enqueueSnackbar("Oops! Something went wrong.", {
        variant: "error",
      });
    },
  });

  async function handleDoneClick() {
    let success = false;
    if (data == null) {
      return;
    }

    try {
      await updateAccountMetadata(
        data.user.spec.activeAccount,
        formValues.accountName,
      );
      success = true;
    } catch (e) {
      // TODO: toast error.
      console.error(e);
    }

    if (success) {
      navigate("/agents");
    } else {
      enqueueSnackbar("Oops, something went wrong. Please try again.", {
        variant: "error",
      });
    }
  }

  return (
    <div className={styles.stepContainer}>
      <Stack flexGrow={1} justifyContent="center" alignItems="center">
        <Stack spacing={2} marginBottom={2}>
          <Typography variant="h5">Set up your first Account</Typography>
          <Box maxWidth={400}>
            <TextField
              fullWidth
              size="small"
              label="Account Name"
              onChange={handleAccountFieldChange}
              value={formValues.accountName}
            />
          </Box>
          <Typography variant={"body2"}>
            Accounts usually represent a team or organization at your company.
          </Typography>
        </Stack>
      </Stack>

      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button variant="outlined" onClick={() => goToStep(0)}>
          Back
        </Button>
        <Button
          disabled={!formValues.acceptEula}
          variant="contained"
          onClick={handleDoneClick}
        >
          Done
        </Button>
      </Stack>
    </div>
  );
};

/**
 *
 * @param accountId Id of the account to update
 * @param displayName The new display name for the account
 * @returns boolean indicating success
 */
async function updateAccountMetadata(accountId: string, displayName: string) {
  const resp = await fetch(`/v1/accounts/${accountId}/metadata`, {
    method: "PATCH",
    body: JSON.stringify({
      displayName,
    }),
  });

  if (!resp.ok) {
    throw new Error(
      `Failed to update account metadata, got status: ${resp.status}`,
    );
  }
}
