import { Card, Chip, TableRow, Typography } from "@mui/material";
import { differenceInMilliseconds, parseISO } from "date-fns";
import { useMemo } from "react";
import { useInView } from "react-intersection-observer";
import { PipelineType, Trace } from "../../../graphql/generated";
import { SnapshotRegion } from "../regions";
import { getTimestamp } from "../utils";
import { CellLabel, CellValue } from "./Cells";
import { DetailsContainer } from "./DetailsContainer";
import { MapValueSummary } from "./MapValueSummary";
import { RowSummary } from "./RowSummary";
import { AttributesProvider } from "./SnapShotRow";
import { SummaryTable } from "./SummaryTable";

import styles from "../snap-shot-console.module.scss";
import { useSnapshot } from "../SnapshotContext";
import { highlightSearchQuery } from "./utils";

interface TraceRecordRowProps {
  message: Trace;
  attributes: AttributesProvider;
  bindplaneID: string;
}

export const TraceRecordRow: React.FC<TraceRecordRowProps> = ({
  message,
  attributes,
  bindplaneID,
}) => {
  const { ref, inView } = useInView({ threshold: 0.1 });
  const timestamp = useMemo(
    () => getTimestamp(message, PipelineType.Traces),
    [message],
  );

  const diff = useMemo(
    function calcSpan() {
      const [start, end] = [parseISO(message.start), parseISO(message.end)];

      return `${differenceInMilliseconds(end, start)} ms`;
    },
    [message],
  );

  const { searchRegex } = useSnapshot();

  return (
    <Card
      classes={{ root: styles.card }}
      data-region={SnapshotRegion.ROW}
      data-row-id={bindplaneID}
      ref={ref}
    >
      <RowSummary
        bindplaneID={bindplaneID}
        timestamp={timestamp}
        data-region={SnapshotRegion.ROW_SUMMARY}
      >
        <Chip
          size="small"
          label={highlightSearchQuery(message.name, searchRegex)}
        />
        <Typography fontFamily="monospace" fontSize={12}>
          {diff}
        </Typography>
      </RowSummary>

      {inView && (
        <DetailsContainer>
          <Typography fontWeight={600}>Span</Typography>
          <SummaryTable>
            <TableRow>
              <CellLabel>start</CellLabel>
              <CellValue>{message.start}</CellValue>
            </TableRow>
            <TableRow>
              <CellLabel>end</CellLabel>
              <CellValue>{message.end}</CellValue>
            </TableRow>
            <TableRow>
              <CellLabel>name</CellLabel>
              <CellValue>
                {highlightSearchQuery(message.name, searchRegex)}
              </CellValue>
            </TableRow>
            <TableRow>
              <CellLabel>id</CellLabel>
              <CellValue>
                {highlightSearchQuery(message.spanID, searchRegex)}
              </CellValue>
            </TableRow>
            <TableRow>
              <CellLabel>parent</CellLabel>
              <CellValue>
                {highlightSearchQuery(message.parentSpanID, searchRegex)}
              </CellValue>
            </TableRow>
            <TableRow>
              <CellLabel>trace</CellLabel>
              <CellValue>
                {highlightSearchQuery(message.traceID, searchRegex)}
              </CellValue>
            </TableRow>
          </SummaryTable>

          <Typography fontWeight={600} marginTop={2}>
            Attributes
          </Typography>

          <MapValueSummary
            value={attributes()}
            fieldType="attribute"
            emptyMessage="No attribute values"
          />

          <Typography fontWeight={600} marginTop={2}>
            Resource
          </Typography>
          <MapValueSummary
            value={message.resource}
            fieldType="resource"
            emptyMessage="No resource values"
          />
        </DetailsContainer>
      )}
    </Card>
  );
};
