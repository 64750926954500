import { PipelineType, ResourceConfiguration } from "../../graphql/generated";
import {
  editParameterValue,
  equalsParameterValue,
  getParameterValue,
} from "../../utils/classes/resource-configuration";
import {
  SnapshotAction,
  SnapshotActionType,
} from "../SnapShotConsole/SnapShotConsole";

export interface ResourceEditor {
  match(p: ResourceConfiguration): boolean;
  update(p: ResourceConfiguration): boolean;
}

interface ProcessorEditor {
  addProcessor(type: string, params: Record<string, any>): void;
  editProcessor(editor: ResourceEditor): boolean;
}

interface ActionHandler {
  (action: SnapshotAction, editor: ProcessorEditor): void;
}

const actionHandlers: { [key in SnapshotActionType]?: ActionHandler } = {
  [SnapshotActionType.INCLUDE_METRIC]: filterByMetricName,
  [SnapshotActionType.EXCLUDE_METRIC]: filterByMetricName,
  [SnapshotActionType.INCLUDE_FIELD]: filterByField,
  [SnapshotActionType.EXCLUDE_FIELD]: filterByField,
  [SnapshotActionType.DELETE_FIELD]: deleteField,
  [SnapshotActionType.RENAME_FIELD]: renameField,
  [SnapshotActionType.COPY_OTTL]: copyOttl,
  [SnapshotActionType.COPY_VALUE]: copyValue,
  [SnapshotActionType.FILTER_SEVERITY_GTE]: filterSeverityGte,
  [SnapshotActionType.GROUP_BY_ATTRIBUTES]: groupByAttributes,
};

export function handleAction(action: SnapshotAction, editor: ProcessorEditor) {
  actionHandlers[action.type]?.(action, editor);
}

// ----------------------------------------------------------------------

function filterByMetricName(
  action: SnapshotAction,
  { addProcessor, editProcessor }: ProcessorEditor,
) {
  const { type, data } = action;
  const isIncludeMetric = type === SnapshotActionType.INCLUDE_METRIC;
  // if we already have an include, we want to add to it so that we get OR
  // semantics. two separate processors will have AND semantics and no metrics will
  // match both, so we'll get no results.
  if (isIncludeMetric) {
    const edited = editProcessor({
      match: (p) =>
        p.type === "filter_metric_name" &&
        equalsParameterValue(p, "action", "include") &&
        equalsParameterValue(p, "match_type", "strict"),
      update: (copy) => {
        const names = editParameterValue(
          copy,
          "metric_names",
          (current?: string[]) => addValueToList(current, data.name),
        );
        copy.displayName = `Include ${names.join(", ")}`;
        return true;
      },
    });
    if (edited) return;
  }

  addProcessor("filter_metric_name", {
    displayName: `${isIncludeMetric ? "Include" : "Exclude"} ${data.name}`,
    action: isIncludeMetric ? "include" : "exclude",
    match_type: "strict",
    metric_names: [data.name],
  });
}

// ----------------------------------------------------------------------

function filterByField(
  action: SnapshotAction,
  { addProcessor, editProcessor }: ProcessorEditor,
) {
  const { type, data } = action;
  const { key, value, fieldtype } = data;
  const isInclude = type === SnapshotActionType.INCLUDE_FIELD;

  const fieldKeys: Record<string, string> = {
    attribute: "attributes",
    resource: "resources",
    body: "bodies",
  };
  const fieldKey = fieldKeys[fieldtype];

  if (isInclude) {
    const edited = editProcessor({
      match: (p) => {
        if (
          p.type === "filter_field" &&
          equalsParameterValue(p, "action", "include") &&
          equalsParameterValue(p, "match_type", "strict")
        ) {
          const current = getParameterValue(p, "telemetry_types", []);
          return (
            current?.length === 1 &&
            current[0] === telemetryTypesEnumValue(action.pipelineType)
          );
        }
        return false;
      },
      update: (copy) => {
        editParameterValue(
          copy,
          fieldKey,
          (current?: Record<string, string>) => {
            return { ...current, [key]: value };
          },
        );
        let allFields: string[] = [];
        for (const fk of Object.values(fieldKeys)) {
          const fields = getParameterValue(copy, fk, {}) ?? {};
          for (const [k, v] of Object.entries(fields)) {
            allFields.push(`${k}=${v}`);
          }
        }
        copy.displayName = `Include ${allFields.join(", ")} (${
          action.pipelineType
        })`;
        return true;
      },
    });
    if (edited) return;
  }

  const displayName = `${isInclude ? "Include" : "Exclude"} ${key}=${value} (${
    action.pipelineType
  })`;

  const params: any = {
    displayName,
    action: isInclude ? "include" : "exclude",
    match_type: "strict",
    telemetry_types: [telemetryTypesEnumValue(action.pipelineType)],
    [fieldKey]: { [key]: value },
  };

  addProcessor("filter_field", params);
}

// ----------------------------------------------------------------------

const deleteFieldParamKeys: {
  [pipelineType: string]: {
    enableKey: string;
    conditionKey: string;
    fieldKey: { [key: string]: string };
  };
} = {
  [PipelineType.Logs]: {
    enableKey: "enable_logs",
    conditionKey: "log_condition",
    fieldKey: {
      body: "log_body_keys",
      attribute: "log_attributes",
      resource: "log_resource_attributes",
    },
  },
  [PipelineType.Metrics]: {
    enableKey: "enable_metrics",
    conditionKey: "datapoint_condition", // not metric_condition
    fieldKey: {
      attribute: "metric_attributes",
      resource: "metric_resource_attributes",
    },
  },
  [PipelineType.Traces]: {
    enableKey: "enable_traces",
    conditionKey: "span_condition",
    fieldKey: {
      attribute: "trace_attributes",
      resource: "trace_resource_attributes",
    },
  },
};

function deleteField(
  action: SnapshotAction,
  { addProcessor, editProcessor }: ProcessorEditor,
) {
  const pk = deleteFieldParamKeys[action.pipelineType];

  const { key, fieldtype } = action.data;
  const edited = editProcessor({
    match: (p) =>
      p.type === "delete_fields" &&
      equalsParameterValue(p, pk.enableKey, true) &&
      equalsParameterValue(p, pk.conditionKey, "true"),
    update: (copy) => {
      editParameterValue(copy, pk.fieldKey[fieldtype], (current?: string[]) =>
        addValueToList(current, key),
      );

      const allFields: string[] = [];
      for (const k of Object.values(pk.fieldKey)) {
        allFields.push(...(getParameterValue(copy, k, []) ?? []));
      }

      copy.displayName = `Delete ${allFields.join(", ")} (${
        action.pipelineType
      })`;
      return true;
    },
  });
  if (edited) return;

  const params: any = {
    displayName: `Delete ${key} (${action.pipelineType})`,
    [pk.enableKey]: true,
    [pk.conditionKey]: "true",
    [pk.fieldKey[fieldtype]]: [key],
  };

  addProcessor("delete_fields", params);
}

// ----------------------------------------------------------------------

const renameFieldParamKeys: {
  [pipelineType: string]: {
    enableKey: string;
    conditionKey: string;
    fieldKey: { [key: string]: string };
  };
} = {
  [PipelineType.Logs]: {
    enableKey: "enable_logs",
    conditionKey: "log_condition",
    fieldKey: {
      body: "log_body_keys",
      attribute: "log_attribute_keys",
      resource: "log_resource_keys",
    },
  },
  [PipelineType.Metrics]: {
    enableKey: "enable_metrics",
    conditionKey: "datapoint_condition", // not metric_condition
    fieldKey: {
      attribute: "metric_attribute_keys",
      resource: "metric_resource_keys",
    },
  },
  [PipelineType.Traces]: {
    enableKey: "enable_traces",
    conditionKey: "span_condition",
    fieldKey: {
      attribute: "trace_attribute_keys",
      resource: "trace_resource_keys",
    },
  },
};

function renameField(
  action: SnapshotAction,
  { addProcessor }: ProcessorEditor,
) {
  const { key, fieldtype } = action.data;
  const pk = renameFieldParamKeys[action.pipelineType];

  // prompt for a new name2
  const newKey = prompt(`New name for the ${key} field:`);

  const params: any = {
    displayName: `Rename ${key} => ${newKey} (${action.pipelineType})`,
    [pk.enableKey]: true,
    [pk.conditionKey]: "true",
    [pk.fieldKey[fieldtype]]: { [key]: newKey },
  };

  addProcessor("rename_field", params);
}

// ----------------------------------------------------------------------

function copyOttl(action: SnapshotAction, editor: ProcessorEditor) {
  const { key, fieldtype } = action.data;
  let ottl: string;
  switch (fieldtype) {
    case "attribute":
      ottl = `attributes["${key}"]`;
      break;

    case "resource":
      ottl = `resource.attributes["${key}"]`;
      break;

    default: // "body"
      ottl = key;
      break;
  }
  navigator.clipboard.writeText(ottl);
}

// ----------------------------------------------------------------------

function copyValue(action: SnapshotAction, editor: ProcessorEditor) {
  navigator.clipboard.writeText(action.data.value);
}

// ----------------------------------------------------------------------

export const filterSeverityOptions: Record<string, string> = {
  trace: "TRACE",
  debug: "DEBUG",
  info: "INFO",
  warning: "WARN",
  error: "ERROR",
  fatal: "FATAL",
};

function filterSeverityGte(
  action: SnapshotAction,
  { addProcessor }: ProcessorEditor,
) {
  const { severity } = action.data;
  const severityOption = filterSeverityOptions[severity];
  const params: any = {
    displayName: `Severity >= ${severityOption}`,
    severity: severityOption,
  };

  addProcessor("filter_severity", params);
}

// ----------------------------------------------------------------------

const groupByAttributesParamKeys: {
  [pipelineType: string]: {
    enableKey: string;
    attributesKey: string;
  };
} = {
  [PipelineType.Logs]: {
    enableKey: "enable_logs",
    attributesKey: "log_attributes",
  },
  [PipelineType.Metrics]: {
    enableKey: "enable_metrics",
    attributesKey: "metric_attributes",
  },
  [PipelineType.Traces]: {
    enableKey: "enable_traces",
    attributesKey: "trace_attributes",
  },
};

function groupByAttributes(
  action: SnapshotAction,
  { addProcessor, editProcessor }: ProcessorEditor,
) {
  const pk = groupByAttributesParamKeys[action.pipelineType];
  const { key } = action.data;

  const edited = editProcessor({
    match: (p) => p.type === "group_by_attributes",
    update: (copy) => {
      editParameterValue(copy, pk.attributesKey, (current?: string[]) =>
        addValueToList(current, key),
      );

      const allFields = [];
      for (const [tt, params] of Object.entries(groupByAttributesParamKeys)) {
        const fields = getParameterValue(copy, params.attributesKey, []) ?? [];
        if (fields.length > 0) {
          allFields.push(`${fields.join(", ")} (${tt})`);
        }
      }

      copy.displayName = `Group by ${allFields.join(", ")}`;
      return true;
    },
  });
  if (edited) return;

  const params: any = {
    displayName: `Group by ${key} (${action.pipelineType})`,
    [pk.enableKey]: true,
    [pk.attributesKey]: [key],
  };

  addProcessor("group_by_attributes", params);
}

// ----------------------------------------------------------------------
// helpers

function telemetryTypesEnumValue(pipelineType: PipelineType): string {
  switch (pipelineType) {
    case PipelineType.Logs:
      return "Logs";
    case PipelineType.Metrics:
      return "Metrics";
    case PipelineType.Traces:
      return "Traces";
    default:
      return "Logs";
  }
}

// add a value to a list, creating the list if it doesn't exist and doing nothing if the
// value is already in the list. returns the updated list.
function addValueToList(list: string[] | undefined, value: string): string[] {
  if (!list) {
    return [value];
  }
  if (list.includes(value)) {
    return list;
  }
  return [...list, value];
}
