import { Button, Link, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useMemo } from "react";
import { useConfigurationCountQuery } from "../../../graphql/generated";
import { ExternalLinkIcon } from "../../Icons";

import colors from "../../../styles/colors";

export interface ConfigurationCountCellProps {
  resourceType: "source" | "processor" | "destination";
  resourceName: string;
}

/**
 * Table cell that shows the number of configurations using a resource, with a link to the configurations page
 * showing those configurations
 */
export const ConfigurationCountCell: React.FC<ConfigurationCountCellProps> = ({
  resourceName,
  resourceType,
}) => {
  const snackbar = useSnackbar();
  const { data, refetch: refetchConfigurationCount } =
    useConfigurationCountQuery({
      variables: { query: `${resourceType}:${resourceName}` },
      onError(error) {
        console.error(
          `Failed getting configuration count for ${resourceType} ${resourceName}`,
          error,
        );
        snackbar.enqueueSnackbar(
          `Failed to get configuration count for ${resourceName}`,
          { variant: "error" },
        );
      },
    });
  const configsWithResourceURL = useMemo(() => {
    const query = `${resourceType}:${resourceName}`;
    const params = new URLSearchParams();
    params.set("query", query);

    return `/configurations?${params.toString()}`;
  }, [resourceName, resourceType]);

  useEffect(() => {
    refetchConfigurationCount();
  });

  return (
    <Link
      href={configsWithResourceURL}
      target="_blank"
      rel="noopener noreferrer"
      underline="hover"
      style={{ color: colors.semitoneBlue }}
      data-testid={`configuration-count-link-${resourceName}`}
    >
      <Button
        endIcon={<ExternalLinkIcon color={colors.semitoneBlue} width={15} />}
        sx={{
          padding: "0",
          "& .MuiButton-endIcon": {
            marginRight: "0",
          },
        }}
      >
        <Typography fontSize={14} color={colors.semitoneBlue}>
          {data?.configurationCount}
        </Typography>
      </Button>
    </Link>
  );
};
