import { gql } from "@apollo/client";
import { Stack } from "@mui/material";
import { DataGridProProps, GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ConfigurationChangesDocument,
  ConfigurationChangesSubscription,
  ConfigurationTableMetricsSubscription,
  EventType,
  GetConfigurationTableQuery,
  Suggestion,
  useGetConfigurationTableQuery,
} from "../../../graphql/generated";
import { SearchBar } from "../../SearchBar";
import {
  ConfigurationsDataGrid,
  ConfigurationsTableField,
} from "./ConfigurationsDataGrid";
import { ConfigOnboardingNoRowsOverlay } from "./ConfigOnboardingNoRowsOverlay";

gql`
  query GetConfigurationTable(
    $selector: String
    $query: String
    $onlyDeployedConfigurations: Boolean
  ) {
    configurations(
      selector: $selector
      query: $query
      onlyDeployedConfigurations: $onlyDeployedConfigurations
    ) {
      configurations {
        metadata {
          id
          version
          name
          labels
          description
        }
        agentCount
      }
      query
      suggestions {
        query
        label
      }
    }
  }

  subscription ConfigurationChanges($selector: String, $query: String) {
    configurationChanges(selector: $selector, query: $query) {
      configuration {
        metadata {
          id
          version
          name
          description
          labels
        }
        agentCount
      }
      eventType
    }
  }

  subscription ConfigurationTableMetrics($period: String!) {
    overviewMetrics(period: $period) {
      metrics {
        name
        nodeID
        pipelineType
        value
        unit
      }
    }
  }
`;

type TableConfig =
  GetConfigurationTableQuery["configurations"]["configurations"][0];

export function mergeConfigs(
  currentConfigs: TableConfig[],
  configurationUpdates:
    | ConfigurationChangesSubscription["configurationChanges"]
    | undefined,
): TableConfig[] {
  const newConfigs: TableConfig[] = [...currentConfigs];

  for (const update of configurationUpdates || []) {
    const config = update.configuration;
    const configIndex = currentConfigs.findIndex(
      (c) => c.metadata.name === config.metadata.name,
    );
    if (update.eventType === EventType.Remove) {
      // remove the agent if it exists

      if (configIndex !== -1) {
        newConfigs.splice(configIndex, 1);
      }
    } else if (configIndex === -1) {
      newConfigs.push(config);
    } else {
      newConfigs[configIndex] = config;
    }
  }

  return newConfigs;
}

interface ConfigurationTableProps
  extends Omit<DataGridProProps, "columns" | "rows"> {
  selector?: string;
  initQuery?: string;
  columns?: ConfigurationsTableField[];
  onSelectionChange: (selected: GridRowSelectionModel) => void;
  enableDelete?: boolean;
  enableNew?: boolean;
  allowSelection: boolean;
  minHeight?: string;
  maxHeight?: string;
  configurationMetrics?: ConfigurationTableMetricsSubscription["overviewMetrics"]["metrics"];

  // if true, the empty state empty state will
  // link to the configuration builder page
  onboardingNoRowsOverlay?: boolean;

  // urlQuerySearchParam is used to store the query in the url
  // The table will update this param in the URL onQueryChange if it is set
  // It will not use the search query from the URL automatically though, that must
  // be set from the renderer in initQuery.
  urlQuerySearchParam?: string;
}

const CONFIGURATIONS_TABLE_FILTER_OPTIONS: Suggestion[] = [
  { label: "Rollout Complete", query: "rollout-status:stable" },
  { label: "Rollout Paused", query: "rollout-status:paused" },
  { label: "Rollout Pending", query: "rollout-status:pending" },
  { label: "Rollout Started", query: "rollout-status:started" },
];

export const ConfigurationsTable: React.FC<ConfigurationTableProps> = ({
  initQuery = "",
  selector,
  columns,
  enableDelete = true,
  enableNew = true,
  onSelectionChange,
  allowSelection,
  minHeight,
  maxHeight,
  urlQuerySearchParam,
  configurationMetrics,
  onboardingNoRowsOverlay,
  ...DataGridProProps
}) => {
  const { data, loading, refetch, subscribeToMore } =
    useGetConfigurationTableQuery({
      variables: {
        selector,
        query: initQuery,
        onlyDeployedConfigurations: false,
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-only",
      onCompleted(data) {
        const query = data.configurations.query;
        if (query != null) {
          setSuggestionsQuery(query);
        }
      },
    });

  const [selected, setSelected] = useState<GridRowSelectionModel>([]);
  const [suggestionsQuery, setSuggestionsQuery] = useState<string>("");

  const debouncedRefetch = useMemo(() => debounce(refetch, 100), [refetch]);
  const location = useLocation();

  useEffect(() => {
    subscribeToMore({
      document: ConfigurationChangesDocument,
      variables: { query: suggestionsQuery, selector },
      updateQuery: (prev, { subscriptionData, variables }) => {
        if (
          subscriptionData == null ||
          variables?.query !== suggestionsQuery ||
          variables?.selector !== selector
        ) {
          return prev;
        }
        const { data } = subscriptionData as unknown as {
          data: ConfigurationChangesSubscription;
        };
        return {
          configurations: {
            __typename: "Configurations",
            suggestions: prev.configurations?.suggestions ?? [],
            query: prev.configurations?.query ?? "",
            configurations: mergeConfigs(
              prev.configurations?.configurations ?? [],
              data.configurationChanges,
            ),
          },
        };
      },
    });
  }, [selector, suggestionsQuery, subscribeToMore]);

  function onQueryChange(query: string) {
    debouncedRefetch({ selector, query });

    if (urlQuerySearchParam) {
      const params = new URLSearchParams(location.search);
      params.set(urlQuerySearchParam, query);
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${params.toString()}`,
      );
    }
  }

  function handleSelectionChange(newSelection: GridRowSelectionModel) {
    onSelectionChange(newSelection);
    setSelected(newSelection);
  }

  const noRowsOverlay = useMemo(() => {
    if (onboardingNoRowsOverlay && suggestionsQuery === "") {
      return ConfigOnboardingNoRowsOverlay;
    }
  }, [onboardingNoRowsOverlay, suggestionsQuery]);

  return (
    <>
      <Stack spacing={1} height="100%">
        <SearchBar
          filterOptions={CONFIGURATIONS_TABLE_FILTER_OPTIONS}
          suggestions={data?.configurations.suggestions}
          onQueryChange={onQueryChange}
          suggestionQuery={data?.configurations.query}
          initialQuery={initQuery}
        />

        <ConfigurationsDataGrid
          {...DataGridProProps}
          allowSelection={allowSelection}
          setSelectionModel={handleSelectionChange}
          loading={loading}
          configurations={data?.configurations.configurations ?? []}
          configurationMetrics={configurationMetrics}
          columnFields={columns}
          selectionModel={selected}
          minHeight={minHeight}
          maxHeight={maxHeight}
          data-testid="configurations-table"
          noRowsOverlay={noRowsOverlay}
        />
      </Stack>
    </>
  );
};
