import { gql } from "@apollo/client";
import { useProcessorTypeDisplayInfoQuery } from "../../../graphql/generated";
import { ProcessorIcon } from "../../Icons";
import styles from "./cells.module.scss";

gql`
  query ProcessorTypeDisplayInfo($name: String!) {
    processorType(name: $name) {
      metadata {
        id
        displayName
        name
        version
      }
    }
  }
`;

interface ResourceTypeCellProps {
  type: string;
}

/**
 * Data grid cell that shows display name of a processor type
 */
export const ProcessorTypeCell: React.FC<ResourceTypeCellProps> = ({
  type,
}) => {
  const { data } = useProcessorTypeDisplayInfoQuery({
    variables: { name: type },
  });
  const text = data?.processorType?.metadata.displayName ?? type;
  return (
    <div className={styles.cell}>
      <span className={styles.icon}>
        {/* TODO: dynamic icon based on processor type */}
        <ProcessorIcon />
      </span>
      {text}
    </div>
  );
};
