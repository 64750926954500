import { ApolloError, NetworkStatus, gql } from "@apollo/client";
import { createContext, useContext, useMemo, useState } from "react";
import {
  PipelineType,
  SnapshotQuery,
  useSnapshotQuery,
  useSnapshotSearchSupportedQuery,
} from "../../graphql/generated";
import { escapeRegExp } from "lodash";
import { v4 } from "uuid";

// while the query includes all three pipeline types, only the pipelineType specified will have results
gql`
  query snapshot(
    $agentID: String!
    $pipelineType: PipelineType!
    $position: String
    $resourceName: String
    $searchQuery: String
    $uuid: String!
  ) {
    snapshot(
      agentID: $agentID
      pipelineType: $pipelineType
      position: $position
      resourceName: $resourceName
      searchQuery: $searchQuery
      uuid: $uuid
    ) {
      metrics {
        name
        timestamp
        value
        unit
        type
        attributes
        resource
      }
      logs {
        timestamp
        body
        severity
        attributes
        resource
      }
      traces {
        name
        traceID
        spanID
        parentSpanID
        start
        end
        attributes
        resource
      }
    }
  }
  query snapshotSearchSupported($agentID: String!) {
    snapshotSearchSupported(agentID: $agentID)
  }
`;

export type Metric = SnapshotQuery["snapshot"]["metrics"][0];
export type Log = SnapshotQuery["snapshot"]["logs"][0];
export type Trace = SnapshotQuery["snapshot"]["traces"][0];

export interface SnapshotContextValue {
  logs: Log[];
  metrics: Metric[];
  traces: Trace[];

  setLogs(logs: Log[]): void;
  setMetrics(metrics: Metric[]): void;
  setTraces(traces: Trace[]): void;

  processedLogs: Log[];
  processedMetrics: Metric[];
  processedTraces: Trace[];

  setProcessedLogs(logs: Log[]): void;
  setProcessedMetrics(metrics: Metric[]): void;
  setProcessedTraces(traces: Trace[]): void;

  // true during initial loading and refetching
  loading: boolean;

  // true if a dropdown of agents should be included
  showAgentSelector: boolean;

  // footer is displayed under the snapshot telemetry
  footer: string;
  setFooter(footer: string): void;

  // processedFooter is displayed under the processed telemetry
  processedFooter: string;
  setProcessedFooter(footer: string): void;

  error?: ApolloError;
  setError(error: ApolloError): void;

  agentID?: string;
  setAgentID(agentID: string | undefined): void;

  pipelineType: PipelineType;
  setPipelineType(type: PipelineType): void;

  searchQuery?: string;
  setSearchQuery(query: string): void;
  snapshotSearchSupported: boolean;
  searchRegex?: RegExp | undefined;
  refresh: () => void;

  // track open snapshot rows
  openRowIDs: string[];
  toggleRow: (id: string) => void;
}

const defaultValue: SnapshotContextValue = {
  logs: [],
  metrics: [],
  traces: [],

  processedLogs: [],
  processedMetrics: [],
  processedTraces: [],

  setLogs: () => {},
  setMetrics: () => {},
  setTraces: () => {},

  setProcessedLogs: () => {},
  setProcessedMetrics: () => {},
  setProcessedTraces: () => {},

  loading: false,
  showAgentSelector: false,

  footer: "",
  setFooter: () => {},

  processedFooter: "",
  setProcessedFooter: () => {},

  error: undefined,
  setError: () => {},

  agentID: undefined,
  setAgentID: () => {},

  searchQuery: "",
  setSearchQuery: () => {},
  snapshotSearchSupported: false,
  searchRegex: undefined,

  pipelineType: PipelineType.Traces,
  setPipelineType: () => {},

  refresh: () => {},

  // track open snapshot rows
  openRowIDs: [],
  toggleRow: () => {},
};

export const SnapshotContext = createContext(defaultValue);

export interface SnapshotProviderProps {
  pipelineType: PipelineType;
  agentID?: string;
  showAgentSelector?: boolean;
  position?: "s0" | "d0";
  resourceName?: string;
  skipQuery?: boolean;
}

export const SnapshotContextProvider: React.FC<SnapshotProviderProps> = ({
  children,
  pipelineType: initialPipelineType,
  agentID: initialAgentID,
  showAgentSelector,
  position,
  resourceName,
  skipQuery,
}) => {
  const [pipelineType, setPipelineType] =
    useState<PipelineType>(initialPipelineType);

  const [logs, setLogs] = useState<Log[]>([]);
  const [metrics, setMetrics] = useState<Metric[]>([]);
  const [traces, setTraces] = useState<Trace[]>([]);

  const [agentID, setAgentID] = useState<string | undefined>(initialAgentID);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [snapshotSearchSupported, setSnapshotSearchSupported] =
    useState<boolean>(false);
  const [searchRegex, setSearchRegex] = useState<RegExp | undefined>();

  const [error, setError] = useState<ApolloError>();
  const [uuid] = useState<string>(v4());
  const [footer, setFooter] = useState<string>("");

  useSnapshotSearchSupportedQuery({
    variables: { agentID: agentID ?? "" },
    onCompleted: (data) => {
      setSnapshotSearchSupported(data.snapshotSearchSupported);
    },
  });
  const { loading, refetch, networkStatus } = useSnapshotQuery({
    variables: {
      agentID: agentID ?? "",
      pipelineType,
      position,
      resourceName,
      searchQuery,
      uuid,
    },
    skip: agentID == null || skipQuery,
    onCompleted: (data) => {
      const { snapshot } = data;
      setLogs(snapshot.logs.slice().reverse());
      setMetrics(snapshot.metrics.slice().reverse());
      setTraces(snapshot.traces.slice().reverse());
      setError(undefined);
      updateFooterCounts(data);
      if (searchQuery && searchQuery.length > 0) {
        setSearchRegex(new RegExp(`(${escapeRegExp(searchQuery)})`, "g"));
      } else {
        setSearchRegex(undefined);
      }
    },
    onError: (error) => {
      setError(error);
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const anyLoading = useMemo(
    () => loading || networkStatus === NetworkStatus.refetch,
    [loading, networkStatus],
  );

  function updateFooterCounts(data: SnapshotQuery) {
    const { logs, metrics, traces } = data.snapshot;
    var count;
    switch (pipelineType) {
      case PipelineType.Logs:
        count = logs.length;
        break;
      case PipelineType.Metrics:
        count = metrics.length;
        break;
      case PipelineType.Traces:
        count = traces.length;
        break;
    }
    setFooter(`Showing ${count} recent ${pipelineType}`);
  }

  // track open snapshot rows
  const [openRowIDs, setOpenRowIDs] = useState<string[]>([]);
  const toggleRow = (rowID: string): void => {
    if (openRowIDs.includes(rowID)) {
      setOpenRowIDs(openRowIDs.filter((id) => id !== rowID));
    } else {
      setOpenRowIDs([...openRowIDs, rowID]);
    }
  };

  return (
    <SnapshotContext.Provider
      value={{
        logs,
        metrics,
        traces,

        setLogs,
        setMetrics,
        setTraces,

        processedLogs: [],
        processedMetrics: [],
        processedTraces: [],

        setProcessedLogs: () => {},
        setProcessedMetrics: () => {},
        setProcessedTraces: () => {},

        loading: anyLoading,
        showAgentSelector: showAgentSelector ?? false,

        footer,
        processedFooter: "",
        setFooter: setFooter,
        setProcessedFooter: () => {},

        error,
        setError,

        agentID,
        setAgentID,

        searchQuery,
        setSearchQuery,
        snapshotSearchSupported,
        searchRegex,

        pipelineType,
        setPipelineType: (type: PipelineType) => {
          setPipelineType(type);
          setFooter("Searching...");
        },

        refresh: () => refetch({ agentID: agentID ?? "", pipelineType }),

        openRowIDs,
        toggleRow,
      }}
    >
      {children}
    </SnapshotContext.Provider>
  );
};

export function useSnapshot(): SnapshotContextValue {
  return useContext(SnapshotContext);
}
