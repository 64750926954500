import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";
import { platformIsContainer } from "./utils";

interface ConfigurationSelectProps {
  platform: string;
  configs: string[];
  selectedConfig: string;
  setSelectedConfig: (config: string) => void;
}

/**
 * Renders a select box for selecting a configuration depending on the platform
 * k8s and openshift require a configuration, others do not
 *
 * @param configs - The list of configurations to display
 * @param platform - The platform to filter the configurations by
 * @param selectedConfig - The currently selected configuration
 * @param setSelectedConfig - The function to call when the configuration is changed
 */
export const ConfigurationSelect: React.FC<ConfigurationSelectProps> = ({
  configs,
  platform,
  selectedConfig,
  setSelectedConfig,
}: ConfigurationSelectProps) => {
  const configRequired = platformIsContainer(platform);
  const label = configRequired
    ? "Select Configuration"
    : "Select Configuration (optional)";

  return (
    <Stack spacing={2}>
      {configs.length > 0 && (
        <FormControl fullWidth margin="normal">
          <InputLabel id="config-label">{label}</InputLabel>

          <Select
            size="small"
            inputProps={{ "data-testid": "config-select" }}
            labelId="config-label"
            id="configuration"
            label={label}
            onChange={(e: SelectChangeEvent<string>) => {
              setSelectedConfig(e.target.value);
            }}
            value={selectedConfig}
          >
            {!configRequired && (
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            )}
            {configs.map((c) => (
              <MenuItem key={c} value={c} data-testid={`config-${c}`}>
                {c}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Stack>
  );
};
