import { GetConfigurationVersionsQuery } from "../../graphql/generated";

export class VersionsData
  implements Omit<GetConfigurationVersionsQuery, "__typename">
{
  configurationVersions: GetConfigurationVersionsQuery["configurationVersions"];
  constructor(data: GetConfigurationVersionsQuery) {
    this.configurationVersions = data.configurationVersions;
  }

  /**
   * findCurrentVersion returns the current version or undefined if there is no current version
   */
  findCurrent() {
    return this.configurationVersions.find((version) => version.status.current);
  }

  /**
   * findPendingVersion returns the pending version or undefined if there is no pending version
   */
  findPending() {
    return this.configurationVersions.find(
      (version) => version.status.pending && !version.status.current,
    );
  }

  /**
   * findNewVersion returns the latest version if it is not pending or stable
   */
  findNew() {
    return this.configurationVersions.find(
      (version) =>
        version.status.latest &&
        !version.status.pending &&
        !version.status.current,
    );
  }

  /**
   * liveVersion returns the version number of the live version,
   * i.e. the greatest version that has been rolled out, corresponding
   * to a configuration with rollout status stable, started, paused, or errored.
   *
   *
   */
  liveVersion(): number | undefined {
    return this.configurationVersions.reduce<number | undefined>(
      (prev, current) => {
        if (current.status.current || current.status.pending) {
          if (prev === undefined) {
            return current.metadata.version;
          }

          if (prev > current.metadata.version) {
            return prev;
          } else {
            return current.metadata.version;
          }
        }
        return prev;
      },
      undefined,
    );
  }

  /**
   * Draft version returns the version of the draft configuration,
   * i.e that it is the latest and has not been rolled out.
   */
  draftVersion(): number | undefined {
    return this.configurationVersions.find(
      (version) =>
        version.status.latest &&
        !version.status.pending &&
        !version.status.current,
    )?.metadata.version;
  }

  // firstActiveType returns either the first active type for the current version
  // if it exists - then the newest version.
  firstActiveType(): string | undefined {
    const current = this.findCurrent();
    if (current && current.activeTypes && current.activeTypes.length > 0) {
      return current.activeTypes[0];
    }

    const latest = this.configurationVersions[0];
    if (latest && latest.activeTypes && latest.activeTypes.length > 0) {
      return latest.activeTypes[0];
    }
  }
}
