import { gql } from "@apollo/client";
import { withRBAC } from "../../contexts/RBAC";
import { useGetAuditEventsQuery } from "../../graphql/generated";
import {
  DataGridPro,
  DataGridProProps,
  GridSortingInitialState,
} from "@mui/x-data-grid-pro";
import { Stack } from "@mui/material";
import { isEmpty } from "lodash";
import { AuditLogsOptions } from "./AuditLogsOptions";
import { addOneDay } from "../../utils/time";
import { withEENavBar } from "../../components/EENavBar";
import { withRequireLogin } from "../../contexts/RequireLogin";
import { DateParam, StringParam, useQueryParam } from "use-query-params";

import styles from "./audit-logs-page.module.scss";

gql`
  query GetAuditEvents($options: AuditEventFilter, $sort: String) {
    auditEvents(options: $options, sort: $sort) {
      id
      timestamp
      resourceName
      resourceKind
      configuration
      action
      user
      account
      description
    }
  }
`;

const INIT_SORT: GridSortingInitialState = {
  sortModel: [{ field: "timestamp", sort: "desc" }],
};

export const AuditLogsPageContent: React.FC = () => {
  const [selectedConfig, setSelectedConfig] = useQueryParam(
    "config",
    StringParam,
  );
  const [selectedUser, setSelectedUser] = useQueryParam("user", StringParam);
  const [minDate, setMinDate] = useQueryParam("minDate", DateParam);
  const [maxDate, setMaxDate] = useQueryParam("maxDate", DateParam);

  const { data, loading, refetch } = useGetAuditEventsQuery({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      options: {
        configuration: selectedConfig ?? undefined,
        user: selectedUser ?? undefined,
        minDate: minDate?.toISOString(),
        // Add 1 day to maxDate to include the entire day.
        maxDate: addOneDay(maxDate)?.toISOString(),
      },
    },
  });

  function handleRefreshClick() {
    refetch();
  }

  const columns: DataGridProProps["columns"] = [
    {
      field: "timestamp",
      width: 180,
      headerName: "Date",
      renderCell({ value }) {
        return new Date(value).toLocaleString();
      },
      valueGetter: ({ value }) => new Date(value).getTime(),
    },
    {
      field: "configuration",
      flex: 1,
      renderCell({ value }) {
        return isEmpty(value) ? "N/A" : value;
      },
      headerName: "Configuration",
    },
    { field: "resourceKind", width: 120, headerName: "Resource" },
    { field: "action", width: 120, headerName: "Action" },
    { field: "resourceName", flex: 1, headerName: "Name" },
    { field: "user", flex: 1, headerName: "User" },
    { field: "description", flex: 2, headerName: "Description" },
  ];

  return (
    <div className={styles.container} style={{ height: "calc(100vh - 200px)" }}>
      <AuditLogsOptions
        selectedConfig={selectedConfig ?? null}
        setSelectedConfig={setSelectedConfig}
        selectedUser={selectedUser ?? null}
        setSelectedUser={setSelectedUser}
        minDate={minDate ?? null}
        setMinDate={setMinDate}
        maxDate={maxDate ?? null}
        setMaxDate={setMaxDate}
        onRefreshClick={handleRefreshClick}
      />
      <DataGridPro
        columns={columns}
        rows={data?.auditEvents ?? []}
        loading={loading}
        disableRowSelectionOnClick
        slots={{
          noRowsOverlay: NoRowsOverlay,
        }}
        // This is a workaround for component testing.
        autoHeight={process.env.NODE_ENV === "test"}
        initialState={{
          sorting: INIT_SORT,
        }}
        sortingOrder={["asc", "desc"]}
        pagination
      />
    </div>
  );
};

const NoRowsOverlay: React.FC = () => {
  return (
    <Stack
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      No Audit Logs
    </Stack>
  );
};

export const AuditLogsPage = withRequireLogin(
  withRBAC(withEENavBar(AuditLogsPageContent)),
);
