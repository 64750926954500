/**
 * Sends a rest request to /v1/accept-eula to accept the EULA.
 */
export async function acceptEula() {
  const resp = await fetch("/v1/accept-eula", {
    method: "PUT",
  });

  if (!resp.ok) {
    throw new Error(`Failed to accept EULA, got status: ${resp.status}`);
  }
}
