import {
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { isEmpty, trim } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreateAccountPayload } from "../../../types/rest";
import { createNewAccount } from "../../../utils/rest/new-account";

import { withRequireLogin } from "../../../contexts/RequireLogin";

import styles from "./setup-account-page.module.scss";

export const SetupAccountPage: React.FC = withRequireLogin(() => {
  const [details, setDetails] = useState<CreateAccountPayload>({
    displayName: "",
  });
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    const resp = await createNewAccount(details);

    if (resp.status !== 201) {
      setError("Oops! Something went wrong.");
      return;
    }

    navigate("/agents");
  };

  return (
    <Stack className={styles.bg} alignItems="center">
      <Card className={styles.card}>
        <CardContent>
          <Stack
            alignItems="center"
            spacing={2}
            paddingY={3}
            component="form"
            onSubmit={handleSubmit}
          >
            <Typography variant="h4" fontWeight={700} marginBottom={1}>
              Account Details
            </Typography>

            <Typography>
              Before you can manage agents, you need to create an account.
            </Typography>

            <TextField
              size="small"
              fullWidth
              label="Account Name"
              value={details.displayName}
              onChange={(e) => {
                setDetails((prev) => {
                  return {
                    ...prev,
                    displayName: e.target.value,
                  };
                });
              }}
            />

            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={isEmpty(trim(details.displayName))}
            >
              Continue
            </Button>

            {error && <Typography color="error">{error}</Typography>}
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
});
