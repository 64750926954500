import { Alert } from "@mui/lab";
import { Chip, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { isFunction } from "lodash";
import { AdditionalInfo } from "../../graphql/generated";
import colors from "../../styles/colors";
import { BookmarkIcon, HelpCircleIcon } from "../Icons";

interface ViewHeadingProps {
  heading?: string;
  subHeading?: string;
  additionalInfo?: AdditionalInfo | null;
  deprecated?: boolean;
  resourceDocLink?: string;
  showLibraryBookmark?: boolean;
  readOnly?: boolean;
  resourceInLibrary?: boolean;
  setAddToLibraryDialogOpen?: (b: boolean) => void;
  setUnlinkFromLibraryDialogOpen?: (b: boolean) => void;
}

export const ViewHeading: React.FC<ViewHeadingProps> = ({
  heading,
  subHeading,
  additionalInfo,
  deprecated,
  resourceDocLink,
  showLibraryBookmark,
  readOnly,
  resourceInLibrary,
  setAddToLibraryDialogOpen,
  setUnlinkFromLibraryDialogOpen,
}) => {
  function handleBookmarkClick() {
    if (resourceInLibrary) {
      isFunction(setUnlinkFromLibraryDialogOpen)
        ? setUnlinkFromLibraryDialogOpen(true)
        : console.error(
            "No function provided for setUnlinkFromLibraryDialogOpen",
          );
    } else {
      isFunction(setAddToLibraryDialogOpen)
        ? setAddToLibraryDialogOpen(true)
        : console.error("No function provided for setAddToLibraryDialogOpen");
    }
  }

  return (
    <Stack paddingBottom={1}>
      {heading && (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography fontWeight={600} fontSize={24}>
            {heading}
          </Typography>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
            flexGrow={1}
          >
            {resourceDocLink && (
              <IconButton
                href={resourceDocLink}
                target="_blank"
                rel="noreferrer"
                color="primary"
              >
                <HelpCircleIcon />
              </IconButton>
            )}
            {deprecated && (
              <Chip
                color="warning"
                label="Deprecated"
                size="small"
                variant="filled"
                style={{ float: "right" }}
              />
            )}
            {showLibraryBookmark && (
              <Tooltip
                title={
                  resourceInLibrary
                    ? "Unlink resource from Library"
                    : "Add resource to Library"
                }
              >
                {/* span around button allows Tooltip to show when button is disabled, otherwise there are console errors */}
                <span>
                  <IconButton
                    color={resourceInLibrary ? "primary" : undefined}
                    onClick={handleBookmarkClick}
                    disabled={readOnly}
                    data-testid="processor-configuration-bookmark-icon"
                  >
                    <BookmarkIcon
                      fill={resourceInLibrary ? colors.pixelPointBlue : "none"}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Stack>
        </Stack>
      )}
      {subHeading && <Typography fontSize={16}>{subHeading}</Typography>}
      {additionalInfo && (
        <Alert severity="info" data-testid="info-alert">
          <Typography>
            {additionalInfo.message}
            {additionalInfo.documentation?.map((d) => (
              <div key={d.url}>
                <a href={d.url} rel="noreferrer" target="_blank">
                  {d.text}
                </a>
              </div>
            ))}
          </Typography>
        </Alert>
      )}
    </Stack>
  );
};
