import { Menu, MenuItem } from "@mui/material";
import {
  SnapshotAction,
  SnapshotActionHandler,
  SnapshotActionType,
} from "../SnapShotConsole/SnapShotConsole";

import styles from "./ee-processor-dialog.module.scss";
import consoleStyles from "../SnapShotConsole/snap-shot-console.module.scss";

import { filterSeverityOptions } from "./actions";

interface SnapshotRegionMenuProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  action: SnapshotAction;
  onAction: SnapshotActionHandler;
  onClose: () => void;
}

export const SnapshotRegionMenu: React.FC<SnapshotRegionMenuProps> = ({
  open,
  anchorEl,
  action,
  onAction,
  onClose,
}) => {
  const handleAction = (type: SnapshotActionType) => {
    return () => onAction({ ...action, type });
  };

  const items = [];
  for (const [section, options] of Object.entries(getMenuOptions(action))) {
    const subitems = [];
    for (const option of options) {
      if (option.visible) {
        subitems.push(
          <MenuItem
            dense
            onClick={handleAction(option.action)}
            key={option.title}
          >
            {option.title}
          </MenuItem>,
        );
      }
    }
    if (subitems.length > 0) {
      items.push(<SectionHeading title={section} key={section} />);
      items.push(...subitems);
    }
  }

  anchorEl?.classList.toggle(consoleStyles["menu-open"], items.length > 0);
  return (
    <Menu
      className={styles["snapshot-menu"]}
      id="snapshot-menu"
      anchorEl={anchorEl}
      open={open && items.length > 0}
      onClose={onClose}
    >
      {items}
    </Menu>
  );
};

const SectionHeading: React.FC<{ title: string }> = ({ title }) => {
  return (
    <MenuItem className={styles["snapshot-menu-heading"]} disabled>
      {title}
    </MenuItem>
  );
};

// ----------------------------------------------------------------------

interface MenuOption {
  title: string;
  action: SnapshotActionType;
  visible: boolean;
}

function getMenuOptions(action: SnapshotAction): {
  [section: string]: MenuOption[];
} {
  return {
    "Filter by Metric Name": [
      {
        title: "Include",
        action: SnapshotActionType.INCLUDE_METRIC,
        visible: action.type === SnapshotActionType.OPEN_METRIC_NAME_MENU,
      },
      {
        title: "Exclude",
        action: SnapshotActionType.EXCLUDE_METRIC,
        visible: action.type === SnapshotActionType.OPEN_METRIC_NAME_MENU,
      },
    ],
    "Filter by Severity": [
      {
        title: `>= ${filterSeverityOptions[action.data.severity]}`,
        action: SnapshotActionType.FILTER_SEVERITY_GTE,
        visible:
          action.type === SnapshotActionType.OPEN_SEVERITY_MENU &&
          filterSeverityOptions[action.data.severity] != null,
      },
    ],
    "Filter by Fields": [
      {
        title: "Include",
        action: SnapshotActionType.INCLUDE_FIELD,
        visible: action.type === SnapshotActionType.OPEN_FIELD_ROW_MENU,
      },
      {
        title: "Exclude",
        action: SnapshotActionType.EXCLUDE_FIELD,
        visible: action.type === SnapshotActionType.OPEN_FIELD_ROW_MENU,
      },
    ],
    Transform: [
      {
        title: "Delete Field",
        action: SnapshotActionType.DELETE_FIELD,
        visible: action.type === SnapshotActionType.OPEN_FIELD_ROW_MENU,
      },
      {
        title: "Rename Field",
        action: SnapshotActionType.RENAME_FIELD,
        visible: action.type === SnapshotActionType.OPEN_FIELD_ROW_MENU,
      },
    ],
    Advanced: [
      {
        title: "Group by Attributes",
        action: SnapshotActionType.GROUP_BY_ATTRIBUTES,
        visible:
          action.type === SnapshotActionType.OPEN_FIELD_ROW_MENU &&
          action.data.fieldtype === "attribute",
      },
      {
        title: "Copy OTTL Path",
        action: SnapshotActionType.COPY_OTTL,
        visible: action.type === SnapshotActionType.OPEN_FIELD_ROW_MENU,
      },
      {
        title: "Copy Value",
        action: SnapshotActionType.COPY_VALUE,
        visible: action.type === SnapshotActionType.OPEN_FIELD_ROW_MENU,
      },
    ],
  };
}
