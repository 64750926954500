import { withRequireLogin } from "../../contexts/RequireLogin";
import { Box, Button, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { ReactFlowProvider } from "reactflow";
import { OverviewGraph } from "./OverviewGraph";
import { OverviewPageProvider, useOverviewPage } from "./OverviewPageContext";
import {
  DEFAULT_OVERVIEW_GRAPH_QUERY_PERIOD,
  DEFAULT_TELEMETRY_TYPE,
  MeasurementControlBar,
  TELEMETRY_SIZE_METRICS,
} from "../../components/MeasurementControlBar/MeasurementControlBar";
import { ApolloError, gql } from "@apollo/client";
import {
  DestinationsDataGrid,
  DestinationsTableField,
} from "../../components/Tables/DestinationsTable/DestinationsDataGrid";
import {
  ConfigurationsDataGrid,
  ConfigurationsTableField,
} from "../../components/Tables/ConfigurationTable/ConfigurationsDataGrid";
import { withRBAC } from "../../contexts/RBAC";
import { withNoStyleEENavBar } from "../../components/EENavBar";
import {
  DestinationsQuery,
  GetConfigurationTableQuery,
  useDestinationsQuery,
  useGetConfigurationTableQuery,
} from "../../graphql/generated";
import { useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { SearchBar } from "../../components/SearchBar";
import { EditDestinationDialog } from "../../components/Tables/DestinationsTable/EditDestinationDialog";
import { GridRowSelectionModel } from "@mui/x-data-grid";

import mixins from "../../styles/mixins.module.scss";
import styles from "./overview-page.module.scss";

gql`
  query DeployedConfigs {
    configurations(onlyDeployedConfigurations: true) {
      configurations {
        metadata {
          id
          name
          version
        }
      }
    }
  }
`;

const OverviewPageSubContent: React.FC = () => {
  const [configQuery, setConfigQuery] =
    useState<GetConfigurationTableQuery["configurations"]["configurations"]>();
  const [configSearch, setConfigSearch] = useState<string>("");

  const [destinationsQuery, setDestinationsQuery] =
    useState<DestinationsQuery>();
  const [destinationSearch, setDestinationSearch] = useState<string>("");

  const { enqueueSnackbar } = useSnackbar();

  function onQueryError(error: ApolloError) {
    console.error(error);
    enqueueSnackbar("Error loading data", { variant: "error" });
  }

  const { loading: configLoading } = useGetConfigurationTableQuery({
    variables: {
      onlyDeployedConfigurations: true,
    },
    onCompleted(data) {
      setConfigQuery(data.configurations.configurations);
    },
    onError: onQueryError,
    fetchPolicy: "network-only",
  });

  const { loading: destinationsLoading } = useDestinationsQuery({
    variables: {
      filterUnused: true,
    },
    onCompleted: setDestinationsQuery,
    onError: onQueryError,
    fetchPolicy: "network-only",
  });

  const configurations = useMemo(() => {
    if (configQuery) {
      return configQuery.filter((config) =>
        config.metadata.name.includes(configSearch),
      );
    }
  }, [configQuery, configSearch]);

  const destinations = useMemo(() => {
    if (destinationsQuery) {
      return destinationsQuery.destinations.filter((destination) =>
        destination.metadata.name.includes(destinationSearch),
      );
    }
  }, [destinationsQuery, destinationSearch]);

  const {
    selectedTelemetry,
    selectedConfigs,
    setSelectedConfigs,
    selectedDestinations,
    setSelectedDestinations,
    setSelectedTelemetry,
    selectedPeriod,
    setPeriod,
    editingDestination,
    setEditingDestination,
    onSelectTop3Configs,
    onSelectTop3Destinations,
    loadingMetrics,
    overviewMetrics,
  } = useOverviewPage();

  const measurementForTelemetry = TELEMETRY_SIZE_METRICS[selectedTelemetry];

  // Don't enable Top Three Buttons if there aren't measurements for the selected telemetry type
  const metricsAreEmpty =
    loadingMetrics ||
    !overviewMetrics?.metrics ||
    !overviewMetrics.metrics.some((m) => m.name === measurementForTelemetry);

  function handleSetSelectedConfigs(configs: GridRowSelectionModel) {
    // No op when we do not have config data.  This prevents the DataGrid
    // from calling setConfigs for an empty value which will clear the
    // query parameters.
    if (configQuery == null) {
      return;
    }

    setSelectedConfigs(configs);
  }

  function handleSetSelectedDestinations(destinations: GridRowSelectionModel) {
    // No op when we do not have destination data.  This prevents the DataGrid
    // from calling setDestinations for an empty value which will clear the
    // query parameters.
    if (destinationsQuery == null) {
      return;
    }

    setSelectedDestinations(destinations);
  }

  return (
    <Box
      className={styles["grid-background"]}
      height="calc(100vh - 94px)"
      overflow="hidden"
    >
      <Stack direction={"row"} spacing={1} height="100%">
        <Stack spacing={1} className={styles["overview-table-left"]}>
          <Paper className={styles["overview-table-paper"]}>
            <Stack className={styles["table-container"]}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="8px"
              >
                <Typography variant="h6">Configurations</Typography>
                <Tooltip
                  enterDelay={1000}
                  title="Limit the displayed configurations to the three receiving the most data of the selected telemetry type over the selected period."
                >
                  <span>
                    <Button
                      variant="text"
                      classes={{ root: mixins["float-right"] }}
                      onClick={onSelectTop3Configs}
                      data-testid="top-three-configs-button"
                      disabled={metricsAreEmpty}
                    >
                      Top Three
                    </Button>
                  </span>
                </Tooltip>
              </Stack>
              <SearchBar
                initialQuery=""
                onQueryChange={setConfigSearch}
                placeholder="Filter by config name"
              />
              <ConfigurationsDataGrid
                loading={configLoading}
                allowSelection
                selectionModel={selectedConfigs}
                setSelectionModel={handleSetSelectedConfigs}
                configurationMetrics={overviewMetrics?.metrics}
                configurations={configurations ?? []}
                columnFields={[ConfigurationsTableField.NAME]}
                maxHeight="100%"
                minHeight="0"
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      logs: false,
                      metrics: false,
                      traces: false,
                    },
                  },
                  sorting: {
                    sortModel: [{ field: "name", sort: "asc" }],
                  },
                }}
                classes={{
                  footerContainer: mixins.hidden,
                }}
              />
            </Stack>

            <Stack className={styles["table-container"]}>
              <Stack
                direction="row"
                justifyContent="space-between"
                marginBottom="8px"
                marginTop="16px"
              >
                <Typography variant="h6">Destinations</Typography>
                <Tooltip
                  enterDelay={1000}
                  title="Limit the displayed destinations to the three receiving the most data of the selected telemetry type over the selected period."
                >
                  <span>
                    <Button
                      variant="text"
                      classes={{ root: mixins["float-right"] }}
                      onClick={onSelectTop3Destinations}
                      data-testid="top-three-destinations-button"
                      disabled={metricsAreEmpty}
                    >
                      Top Three
                    </Button>
                  </span>
                </Tooltip>
              </Stack>

              <SearchBar
                placeholder="Filter by destination name"
                onQueryChange={setDestinationSearch}
                initialQuery=""
              />
              <DestinationsDataGrid
                allowSelection
                selectionModel={selectedDestinations ?? []}
                setSelectionModel={handleSetSelectedDestinations}
                destinationsPage={false}
                destinations={destinations ?? []}
                configurationMetrics={overviewMetrics?.metrics}
                columnFields={[DestinationsTableField.ICON_AND_NAME]}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      logs: false,
                      metrics: false,
                      traces: false,
                    },
                  },
                  sorting: {
                    sortModel: [
                      {
                        field: DestinationsTableField.ICON_AND_NAME,
                        sort: "asc",
                      },
                    ],
                  },
                }}
                onEditDestination={(name) => setEditingDestination(name)}
                loading={destinationsLoading}
                maxHeight="100%"
                minHeight="0"
                classes={{
                  footerContainer: mixins.hidden,
                }}
              />
            </Stack>
          </Paper>
        </Stack>

        <Stack height="100%" width="100%">
          <MeasurementControlBar
            telemetry={selectedTelemetry || DEFAULT_TELEMETRY_TYPE}
            onTelemetryTypeChange={setSelectedTelemetry}
            period={selectedPeriod || DEFAULT_OVERVIEW_GRAPH_QUERY_PERIOD}
            onPeriodChange={setPeriod}
          />
          <ReactFlowProvider>
            <OverviewGraph />
          </ReactFlowProvider>
        </Stack>
      </Stack>

      {/* Edit Destination Dialog */}
      {editingDestination && (
        <EditDestinationDialog
          name={editingDestination}
          onSaveSuccess={() => {}}
          onCancel={() => {}}
          readOnly
        />
      )}
    </Box>
  );
};

export const OverviewPageContent: React.FC = () => {
  return (
    <OverviewPageProvider>
      <OverviewPageSubContent />
    </OverviewPageProvider>
  );
};

export const OverviewPage: React.FC = withRequireLogin(
  withRBAC(withNoStyleEENavBar(OverviewPageContent)),
);
