import { forwardRef } from "react";
import { useLocation, Link, LinkProps } from "react-router-dom";
import { DESTINATIONS_PARAM_NAME, CONFIGS_PARAM_NAME } from "../../utils/state";

export interface SearchLinkProps extends Omit<LinkProps, "to"> {
  path: string;
  displayName: string;
}

const GLOBAL_SEARCH_PARAMS = [DESTINATIONS_PARAM_NAME, CONFIGS_PARAM_NAME];

/**
 * SearchLink is a wrapper around the react-router-dom Link component.
 * It persists the search params in the URL defined as global search params as
 * specified in this component file.
 */
export const SearchLink = forwardRef<
  HTMLAnchorElement,
  Omit<SearchLinkProps, "to">
>(({ displayName, path, ...rest }, ref) => {
  const location = useLocation();

  return (
    <Link
      ref={ref}
      to={{ pathname: path, search: persistGlobalSearch(location) }}
      {...rest}
    >
      {displayName}
    </Link>
  );
});

/**
 * persistGlobalSearch persists the global search params in the URL.
 *
 * @param location the location object from react-router-dom
 * @returns a url search string starting with ? containing global search params
 */
export function persistGlobalSearch(location: ReturnType<typeof useLocation>) {
  const existingSearch = new URLSearchParams(location.search);
  const persistedSearch = new URLSearchParams();
  for (const paramName of GLOBAL_SEARCH_PARAMS) {
    const paramValue = existingSearch.get(paramName);
    if (paramValue) {
      persistedSearch.set(paramName, paramValue);
    }
  }
  return persistedSearch.toString();
}
