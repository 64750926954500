import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Dialog,
  DialogProps,
  Stack,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { ContentSection, TitleSection } from "../DialogComponents";
import { gql } from "@apollo/client";
import CodeDiff, { ReactDiffViewerProps } from "react-diff-viewer-continued";
import { RenderedConfigData } from "./get-rendered-config-data";
import { useEffect, useState } from "react";
import {
  useGetRenderedConfigQuery,
  useGetVersionHistoryQuery,
} from "../../graphql/generated";
import { asCurrentVersion, nameAndVersion } from "../../utils/version-helpers";
import { useSnackbar } from "notistack";

import colors from "../../styles/colors";
import styles from "./diff-section.module.scss";

gql`
  query getRenderedConfig($name: String!) {
    configuration(name: $name) {
      metadata {
        name
        id
        version
      }
      rendered
    }
  }

  query getVersionHistory($name: String!) {
    configurationHistory(name: $name) {
      metadata {
        name
        id
        version
      }
      status {
        current
        pending
        latest
      }
    }
  }
`;

const CODE_DIFF_STYLES: ReactDiffViewerProps["styles"] = {
  contentText: {
    lineBreak: "anywhere",
    fontSize: 12,
  },
  diffContainer: {
    backgroundColor: colors.verylightGray,
    pre: {
      lineHeight: "14px",
    },
    a: {
      textDecoration: "none",
    },
  },
  lineNumber: {
    cursor: "default",
    fontSize: 12,
  },
  gutter: {
    ":hover": {
      cursor: "default",
    },
  },
};

interface DiffDialogProps extends DialogProps {
  onClose: () => void;
  configurationName: string;
  historyVersion?: number;
  setHistoryVersion: (n?: number) => void;
}
/**
 * DiffDialog is used to show the diff between the latest version and the current version.
 *
 * @param onClose callback to close the Dialog
 * @param configurationName the name of the configuration, should not contain a version
 * @param historyVersion is used to sync Rollout History with DiffDialog
 * @param setHistoryVersion is used to sync Rollout History with DiffDialog
 * @returns
 */
export const DiffDialog: React.FC<DiffDialogProps> = ({
  open,
  configurationName,
  onClose,
  historyVersion,
  setHistoryVersion,
}) => {
  const [currentConfigData, setCurrentConfigData] =
    useState<RenderedConfigData>();
  const [rightVersion, setRightVersion] = useState<number>();
  const [rightConfigData, setRightConfigData] = useState<RenderedConfigData>();

  const { enqueueSnackbar } = useSnackbar();

  const { data: versionHistory, refetch: refetchVersionHistory } =
    useGetVersionHistoryQuery({
      variables: {
        name: configurationName,
      },
      fetchPolicy: "network-only",
      onError(error) {
        console.error(error);
        enqueueSnackbar(
          `Failed to fetch version history for ${configurationName}.`,
          {
            variant: "error",
          },
        );
      },
      onCompleted(data) {
        if (data.configurationHistory) {
          setRightVersion(data.configurationHistory[0].metadata.version);
        }
      },
    });

  const { refetch: refetchCurrentConfigData } = useGetRenderedConfigQuery({
    variables: { name: asCurrentVersion(configurationName) },
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
      enqueueSnackbar(
        `Failed to fetch rendered configuration ${asCurrentVersion(
          configurationName,
        )}.`,
        {
          variant: "error",
        },
      );
    },
    onCompleted(data) {
      if (data.configuration) {
        setCurrentConfigData(new RenderedConfigData(data.configuration));
      }
    },
  });

  const { refetch: refetchRightConfigData } = useGetRenderedConfigQuery({
    variables: {
      name: nameAndVersion(configurationName, rightVersion),
    },
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
      enqueueSnackbar(
        `Failed to fetch rendered configuration ${nameAndVersion(
          configurationName,
          rightVersion,
        )}.`,
        {
          variant: "error",
        },
      );
    },
    onCompleted(data) {
      if (data.configuration) {
        setRightConfigData(new RenderedConfigData(data.configuration));
      }
    },
  });

  useEffect(() => {
    refetchVersionHistory();
    if (historyVersion !== undefined) {
      setRightVersion(historyVersion);
      setHistoryVersion(undefined);
    }
    refetchCurrentConfigData();
    refetchRightConfigData();
  }, [
    open,
    historyVersion,
    setHistoryVersion,
    rightVersion,
    setRightVersion,
    refetchVersionHistory,
    refetchCurrentConfigData,
    refetchRightConfigData,
  ]);

  function handleRightSelectChange(e: SelectChangeEvent<number>) {
    setRightVersion(e.target.value as number);
  }

  if (!currentConfigData || !rightConfigData) {
    return (
      <Dialog
        data-testid="diff-dialog"
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="lg"
      >
        <TitleSection onClose={onClose} title="Compare Versions" />
        <ContentSection>
          <Stack
            height="616px"
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Stack>
        </ContentSection>
      </Dialog>
    );
  }
  const noChanges =
    currentConfigData.value().localeCompare(rightConfigData.value()) === 0;

  const rightVersionisDraft =
    rightVersion ===
      versionHistory?.configurationHistory[0]?.metadata?.version &&
    !versionHistory?.configurationHistory[0]?.status?.current &&
    !versionHistory?.configurationHistory[0]?.status?.pending &&
    versionHistory?.configurationHistory[0]?.status?.latest;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <TitleSection onClose={onClose} title="Compare Versions" />
      <ContentSection>
        <Stack spacing={1}>
          {noChanges && (
            <Alert severity="info">
              <AlertTitle>Identical Rendered Configurations</AlertTitle>
              There are no differences between the Selected Version and the Live
              Version. This is likely because the changes result in the same
              rendered OpenTelemetry configuration.
            </Alert>
          )}
          <Stack direction="row" justifyContent="space-around" width="100%">
            <Typography fontWeight={600} position={"relative"} top={"8px"}>
              {currentConfigData.title()} (Live)
            </Typography>
            <Stack direction={"row"} spacing={1} width={"150px"}>
              <Select<number>
                size="small"
                value={rightVersion}
                onChange={handleRightSelectChange}
                MenuProps={{
                  style: {
                    maxHeight: 550,
                  },
                }}
              >
                {versionHistory?.configurationHistory.map((v) => (
                  <MenuItem
                    key={`select-version-${v.metadata.version}`}
                    value={v.metadata.version}
                  >
                    Version {v.metadata.version}
                  </MenuItem>
                ))}
              </Select>
              {rightVersionisDraft && (
                <Typography fontWeight={600} position={"relative"} top={"8px"}>
                  (Draft)
                </Typography>
              )}
            </Stack>
          </Stack>

          <Box className={styles.box}>
            <CodeDiff
              oldValue={currentConfigData.value()}
              newValue={rightConfigData.value()}
              disableWordDiff
              codeFoldMessageRenderer={(total) => (
                <Stack width="200%" justifyContent="center" alignItems="center">
                  <Typography fontSize={14}>Expand {total} lines</Typography>
                </Stack>
              )}
              styles={CODE_DIFF_STYLES}
              showDiffOnly={!noChanges}
            />
          </Box>
        </Stack>{" "}
      </ContentSection>
    </Dialog>
  );
};
