interface ErrorResponse {
  errors: string[];
}

// parseErrorResponse parses the response body as an ErrorResponse and returns
// an array of strings.  It returns null if the response was not an error
// response.
export async function parseErrorResponse(
  resp: Response,
): Promise<string[] | null> {
  try {
    const errResp = (await resp.json()) as ErrorResponse;
    return errResp.errors;
  } catch (e) {
    return null;
  }
}
