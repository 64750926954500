import { gql } from "@apollo/client";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { ConfirmDeleteResourceDialog } from "../../components/ConfirmDeleteResourceDialog";
import { withEENavBar } from "../../components/EENavBar";
import { ChevronDown } from "../../components/Icons";
import { DEFAULT_CONFIGURATION_TABLE_QUERY_PERIOD } from "../../components/MeasurementControlBar/MeasurementControlBar";
import {
  DestinationsDataGrid,
  DestinationsTableField,
} from "../../components/Tables/DestinationsTable/DestinationsDataGrid";
import { EditDestinationDialog } from "../../components/Tables/DestinationsTable/EditDestinationDialog";
import { FailedDeleteDialog } from "../../components/Tables/DestinationsTable/FailedDeleteDialog";
import { EditProcessorDialog } from "../../components/Tables/ProcessorTable/EditProcessorDialog";
import { ProcessorDataGrid } from "../../components/Tables/ProcessorTable/ProcessorDataGrid";
import { EditSourceDialog } from "../../components/Tables/SourceTable/EditSourceDialog";
import { SourceDataGrid } from "../../components/Tables/SourceTable/SourceDataGrid";
import {
  RBACWrapper,
  hasPermission,
  useRole,
  withRBAC,
} from "../../contexts/RBAC";
import { withRequireLogin } from "../../contexts/RequireLogin";
import {
  Role,
  useConfigurationTableMetricsSubscription,
  useDestinationsQuery,
  useProcessorsQuery,
  useSourcesQuery,
} from "../../graphql/generated";
import { ResourceKind, ResourceStatus } from "../../types/resources";
import { deleteResources } from "../../utils/rest/delete-resources";
import { resourcesFromSelected } from "../destinations/DestinationsPage";
import { classes } from "../../utils/styles";

import mixins from "../../styles/mixins.module.scss";
import styles from "./resource-library.module.scss";

gql`
  query Sources {
    sources {
      metadata {
        id
        name
        displayName
        version
      }
      spec {
        type
      }
    }
  }

  query Processors {
    processors {
      metadata {
        id
        name
        displayName
        version
      }
      spec {
        type
      }
    }
  }
`;

/**
 * Tables of reusable sources, processors, and destinations
 */
const ResourceLibraryPageContent: React.FC = () => {
  const role = useRole();
  const { enqueueSnackbar } = useSnackbar();
  const { data: sources, refetch: refetchSources } = useSourcesQuery({
    onError: (e) => {
      console.error(e);
      enqueueSnackbar("There was an error retrieving sources.", {
        variant: "error",
      });
    },
  });
  const { data: processors, refetch: refetchProcessors } = useProcessorsQuery({
    onError: (e) => {
      console.error(e);
      enqueueSnackbar("There was an error retrieving processors.", {
        variant: "error",
      });
    },
  });
  const {
    data: destinations,
    loading: loadingDestinations,
    refetch: refetchDestinations,
  } = useDestinationsQuery({
    fetchPolicy: "cache-and-network",
    refetchWritePolicy: "merge",
    onError: (e) => {
      console.error(e);
      enqueueSnackbar("There was an error retrieving destinations.", {
        variant: "error",
      });
    },
  });
  const { data: destinationMetrics } = useConfigurationTableMetricsSubscription(
    {
      variables: {
        period: DEFAULT_CONFIGURATION_TABLE_QUERY_PERIOD,
      },
    },
  );
  const [editingSource, setEditingSource] = useState<string | null>(null);
  const [editingProcessor, setEditingProcessor] = useState<string | null>(null);
  const [editingDestination, setEditingDestination] = useState<string | null>(
    null,
  );
  // Used to control the delete confirmation modal.
  const [open, setOpen] = useState<boolean>(false);
  const [deletingKind, setDeletingKind] = useState<ResourceKind | null>(null);
  const [selectedSources, setSelectedSources] = useState<GridRowSelectionModel>(
    [],
  );
  const [selectedProcessors, setSelectedProcessors] =
    useState<GridRowSelectionModel>([]);
  const [selectedDestinations, setSelectedDestinations] =
    useState<GridRowSelectionModel>([]);
  const [failedDeletes, setFailedDeletes] = useState<ResourceStatus[]>([]);
  const [failedDeletesOpen, setFailedDeletesOpen] = useState(false);

  const selectedForDeletion = useMemo(() => {
    switch (deletingKind) {
      case ResourceKind.SOURCE:
        return selectedSources;
      case ResourceKind.PROCESSOR:
        return selectedProcessors;
      case ResourceKind.DESTINATION:
        return selectedDestinations;
      default:
        return [];
    }
  }, [deletingKind, selectedSources, selectedProcessors, selectedDestinations]);

  async function deleteLibraryResources() {
    const selected = selectedForDeletion;
    try {
      const items = resourcesFromSelected(selected, deletingKind!);
      const { updates } = await deleteResources(items);
      setOpen(false);

      const failures = updates.filter((u) => u.status !== "deleted");
      setFailedDeletes(failures);

      refetchSources();
      refetchProcessors();
      refetchDestinations();
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to delete resources.", { variant: "error" });
    }
  }

  useEffect(() => {
    refetchSources();
    refetchProcessors();
  });

  useEffect(() => {
    if (failedDeletes.length > 0) {
      setFailedDeletesOpen(true);
    }
  }, [failedDeletes, setFailedDeletesOpen]);

  function onAcknowledge() {
    setFailedDeletesOpen(false);
  }

  // when arriving from Overview
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#destinations") {
      const targetElement = document.getElementById("destinations");
      if (targetElement) {
        setTimeout(() => {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }, 500);
      }
    }
  }, []);

  return (
    <Stack spacing={3} className={classes([styles.root])}>
      <Accordion defaultExpanded className={classes([styles.accordion])}>
        <AccordionSummary expandIcon={<ChevronDown />}>
          <Typography variant="h5">Sources</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            <div>
              {selectedSources.length > 0 && (
                <RBACWrapper requiredRole={Role.User}>
                  <Button
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setOpen(true);
                      setDeletingKind(ResourceKind.SOURCE);
                    }}
                    classes={{ root: mixins["float-right"] }}
                  >
                    Delete {selectedSources.length} Source
                    {selectedSources.length > 1 && "s"}
                  </Button>
                </RBACWrapper>
              )}
            </div>
            <SourceDataGrid
              sources={sources?.sources}
              onEditSource={(id: string) => setEditingSource(id)}
              hideFooter
              rowSelectionModel={selectedSources}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={(newSelection) =>
                setSelectedSources(newSelection)
              }
            />
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded className={classes([styles.accordion])}>
        <AccordionSummary expandIcon={<ChevronDown />}>
          <Typography variant="h5">Processors</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            <div>
              {selectedProcessors.length > 0 && (
                <RBACWrapper requiredRole={Role.User}>
                  <Button
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setOpen(true);
                      setDeletingKind(ResourceKind.PROCESSOR);
                    }}
                    classes={{ root: mixins["float-right"] }}
                  >
                    Delete {selectedProcessors.length} Processor
                    {selectedProcessors.length > 1 && "s"}
                  </Button>
                </RBACWrapper>
              )}
            </div>
            <ProcessorDataGrid
              processors={processors?.processors}
              onEditProcessor={(id: string) => setEditingProcessor(id)}
              hideFooter
              rowSelectionModel={selectedProcessors}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={(newSelection) =>
                setSelectedProcessors(newSelection)
              }
            />
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Stack id={"destinations"}>
        <Accordion defaultExpanded className={classes([styles.accordion])}>
          <AccordionSummary expandIcon={<ChevronDown />}>
            <Typography variant="h5">Destinations</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={2}>
              <div>
                {selectedDestinations.length > 0 && (
                  <RBACWrapper requiredRole={Role.User}>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setOpen(true);
                        setDeletingKind(ResourceKind.DESTINATION);
                      }}
                      classes={{ root: mixins["float-right"] }}
                    >
                      Delete {selectedDestinations.length} Destination
                      {selectedDestinations.length > 1 && "s"}
                    </Button>
                  </RBACWrapper>
                )}
              </div>
              <DestinationsDataGrid
                columnFields={[
                  DestinationsTableField.NAME,
                  DestinationsTableField.TYPE,
                  DestinationsTableField.CONFIGURATIONS,
                  DestinationsTableField.DATA_OUT,
                ]}
                destinations={destinations?.destinations}
                configurationMetrics={
                  destinationMetrics?.overviewMetrics?.metrics
                }
                autoHeight
                loading={loadingDestinations}
                allowSelection
                onEditDestination={(name: string) =>
                  setEditingDestination(name)
                }
                hideFooter
                rowSelectionModel={selectedDestinations}
                checkboxSelection
                onRowSelectionModelChange={(newSelection) =>
                  setSelectedDestinations(newSelection)
                }
                setSelectionModel={setSelectedDestinations}
              />
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Stack>
      {editingSource && (
        <EditSourceDialog
          name={editingSource}
          onCancel={() => setEditingSource(null)}
          onSaveSuccess={() => setEditingSource(null)}
          readOnly={!hasPermission(Role.Admin, role)}
        />
      )}
      {editingProcessor && (
        <EditProcessorDialog
          name={editingProcessor}
          onCancel={() => setEditingProcessor(null)}
          onSaveSuccess={() => setEditingProcessor(null)}
          readOnly={!hasPermission(Role.Admin, role)}
        />
      )}
      {editingDestination && (
        <EditDestinationDialog
          name={editingDestination}
          onCancel={() => setEditingDestination(null)}
          onSaveSuccess={() => setEditingDestination(null)}
          readOnly={!hasPermission(Role.Admin, role)}
        />
      )}
      <ConfirmDeleteResourceDialog
        open={open}
        onClose={() => setOpen(false)}
        onDelete={deleteLibraryResources}
        onCancel={() => setOpen(false)}
        action={"delete"}
      >
        <Typography>
          Are you sure you want to delete {selectedForDeletion.length}{" "}
          {deletingKind}
          {selectedForDeletion.length > 1 && "s"}?
        </Typography>
      </ConfirmDeleteResourceDialog>

      <FailedDeleteDialog
        open={failedDeletesOpen}
        failures={failedDeletes}
        onAcknowledge={onAcknowledge}
        onClose={() => setFailedDeletesOpen(false)}
      />
    </Stack>
  );
};

export const ResourceLibraryPage = withRequireLogin(
  withRBAC(withEENavBar(ResourceLibraryPageContent)),
);
