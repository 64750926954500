import { ApolloError } from "@apollo/client";
import { CircularProgress, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  GetResourceTypeQuery,
  Kind,
  Parameter,
  ResourceConfiguration,
  useGetResourceTypeQuery,
  useGetResourceWithTypeQuery,
} from "../../graphql/generated";
import { BPResourceConfiguration } from "../../utils/classes";
import { BPExtension } from "../../utils/classes/extension";
import { BPProcessor } from "../../utils/classes/processor";
import { trimVersion } from "../../utils/version-helpers";
import { ReusableProcessors } from "../ProcessorsDialog/ProcessorDialog";
import { FormValues, ResourceConfigurationView } from "../ResourceConfigForm";

interface EditResourceViewProps {
  resourceKind: Kind.Processor | Kind.Extension;
  items: ResourceConfiguration[];
  applyQueue: (BPProcessor | BPExtension)[];
  editingIndex: number;
  readOnly?: boolean;
  onEditInlineSave: (values: FormValues) => void;
  onEditPersistentResourceSave: (resource: BPProcessor | BPExtension) => void;
  onBack: () => void;
  onRemove: (removeIndex: number) => void;
  libraryResources?: ReusableProcessors;
  onAddToLibrary?: (values: { [key: string]: any }, name: string) => void;
  onUnlinkFromLibrary?: (
    values: { [key: string]: any },
    name: string,
    type: string,
    unlinkDisplayName: string,
  ) => void;
}

export const EditResourceView: React.FC<EditResourceViewProps> = ({
  resourceKind,
  items,
  editingIndex,
  readOnly,
  applyQueue,
  onEditInlineSave,
  onEditPersistentResourceSave,
  onBack,
  onRemove,
  libraryResources,
  onAddToLibrary,
  onUnlinkFromLibrary,
}) => {
  const resourceConfig = new BPResourceConfiguration(items[editingIndex]);

  const { enqueueSnackbar } = useSnackbar();

  function onError(error: ApolloError) {
    console.error(error.message);
    enqueueSnackbar("Oops! Something went wrong.");
    onBack();
  }

  const [parameters, setParameters] = useState<Parameter[]>();
  const [resource, setResource] = useState<BPProcessor | BPExtension>();
  const [resourceType, setResourceType] =
    useState<GetResourceTypeQuery["resourceType"]>();

  useGetResourceTypeQuery({
    variables: {
      kind:
        resourceKind === Kind.Processor
          ? Kind.ProcessorType
          : Kind.ExtensionType,
      name: resourceConfig.type!,
    },
    skip: !resourceConfig.isInline(),
    onCompleted(data) {
      setResourceType(data.resourceType);
      setParameters(resourceConfig.parameters ?? []);
    },
    onError,
    fetchPolicy: "network-only",
  });

  useGetResourceWithTypeQuery({
    variables: { name: resourceConfig.name!, kind: resourceKind },
    skip: resourceConfig.isInline(),
    onCompleted(data) {
      setResourceType(data.resourceWithType.resourceType);

      // Use an existing processor editor if it exists
      const existingEdit = applyQueue.find(
        (p) => p.name() === trimVersion(resourceConfig.name ?? ""),
      );
      if (existingEdit) {
        setResource(existingEdit);
        setParameters(existingEdit.spec.parameters ?? []);
        return;
      }

      const newResource =
        resourceKind === Kind.Processor
          ? new BPProcessor(data.resourceWithType.resource!)
          : new BPExtension(data.resourceWithType.resource!);

      setResource(newResource);
      setParameters(data.resourceWithType.resource?.spec?.parameters ?? []);
    },
    onError,
    fetchPolicy: "network-only",
  });

  function handleFormSave(values: FormValues) {
    if (resourceConfig.isInline()) {
      onEditInlineSave(values);
      return;
    }

    if (resource == null) {
      console.error(
        `Cannot save persistent resource, no resource found with name: ${resourceConfig.name}.`,
      );
      enqueueSnackbar("Oops! Something went wrong.", { variant: "error" });
      return;
    }

    const newResource =
      resourceKind === Kind.Processor
        ? new BPProcessor(resource)
        : new BPExtension(resource);

    newResource.setParamsFromMap(values);
    onEditPersistentResourceSave(newResource);
  }

  if (parameters == null || resourceType == null) {
    return (
      <Stack
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <ResourceConfigurationView
      resourceTypeDisplayName={
        resourceType.metadata.displayName ?? resourceType.metadata.name
      }
      resourceType={resourceType.metadata.name}
      resourceNameField={resourceConfig?.name ?? ""}
      displayName={resourceConfig.displayName ?? ""}
      description={resourceType.metadata.description ?? ""}
      additionalInfo={resourceType.metadata.additionalInfo}
      resourceDocLink={resourceType.metadata.resourceDocLink ?? ""}
      deprecated={resourceType.metadata.deprecated ?? false}
      kind={resourceKind}
      parameterDefinitions={resourceType.spec.parameters}
      includeDisplayNameField={resourceConfig.isInline()}
      parameters={parameters}
      onSave={handleFormSave}
      saveButtonLabel="Done"
      onBack={onBack}
      onDelete={() => onRemove(editingIndex)}
      readOnly={readOnly}
      embedded={true}
      libraryResources={libraryResources}
      showLibraryBookmark
      onAddToLibrary={onAddToLibrary}
      onUnlinkFromLibrary={onUnlinkFromLibrary}
    />
  );
};
