import { Button, Stack, Typography } from "@mui/material";
import { DialogResource, ResourceType } from ".";
import {
  ResourceTypeButton,
  ResourceTypeButtonContainer,
} from "../ResourceTypeButton";
import { useResourceDialog } from "./ResourceDialogContext";
import {
  TitleSection,
  ContentSection,
  ActionsSection,
} from "../DialogComponents";
import { filterResourcesByType } from "./utils";
import { Kind } from "../../graphql/generated";
import { useState } from "react";
import { metadataSatisfiesSubstring } from "../../utils/metadata-satisfies-substring";

interface ChooseViewProps {
  resources: DialogResource[];
  selected: ResourceType;
  kind: Kind.Source | Kind.Destination;
  clearResource: () => void;
  handleSaveExisting: (r: DialogResource) => void;
  setCreateNew: (b: boolean) => void;
}

export const ChooseView: React.FC<ChooseViewProps> = ({
  resources,
  selected,
  kind,
  handleSaveExisting,
  setCreateNew,
  clearResource,
}) => {
  const { onClose } = useResourceDialog();

  const matchingResources = filterResourcesByType(resources, selected);

  const [search, setSearch] = useState("");

  return (
    <>
      <TitleSection
        title={
          selected.metadata.displayName
            ? `Add ${kind}: ${selected.metadata.displayName}`
            : selected.metadata.name
        }
        description={selected?.metadata?.description || ``}
        deprecated={selected?.metadata?.deprecated || false}
        resourceDocLink={selected?.metadata?.resourceDocLink || ``}
        onClose={onClose}
      />

      <ContentSection>
        <Stack spacing={1}>
          <Stack spacing={1} alignItems={"center"}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCreateNew(true)}
              data-testid="add-resource-dialog-create-new-instead-of-reuse-button"
            >
              Create New
            </Button>
            <Typography>or</Typography>
            <Typography>Reuse existing {kind}</Typography>
          </Stack>

          <Stack maxHeight={"500px"}>
            <ResourceTypeButtonContainer
              onSearchChange={(v: string) => setSearch(v)}
              placeholder={
                kind === Kind.Source
                  ? "Search for a source..."
                  : kind === Kind.Destination
                  ? "Search for a destination..."
                  : ""
              }
            >
              {matchingResources
                .filter((r) => {
                  return metadataSatisfiesSubstring(r, search);
                })
                .map((resource) => {
                  return (
                    <ResourceTypeButton
                      key={resource?.metadata?.name}
                      displayName={resource?.metadata?.name}
                      icon={selected?.metadata.icon!}
                      onSelect={() => handleSaveExisting(resource)}
                      telemetryTypes={selected?.spec?.telemetryTypes}
                    />
                  );
                })}
            </ResourceTypeButtonContainer>
          </Stack>
        </Stack>
      </ContentSection>

      <ActionsSection>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => clearResource()}
        >
          Back
        </Button>
      </ActionsSection>
    </>
  );
};
