import { DialogResource, ResourceType } from ".";
import { AdditionalInfo, Kind } from "../../graphql/generated";
import { FormValues, ResourceConfigurationView } from "../ResourceConfigForm";

interface ConfigureViewProps {
  selected: ResourceType;
  kind: Kind.Source | Kind.Destination;
  createNew: boolean;
  clearResource: () => void;
  handleSaveNew: (formValues: FormValues, selected: ResourceType) => void;
  resources: DialogResource[];
  additionalInfo?: AdditionalInfo | null;
  resourceDocLink?: string;
}

export const ConfigureView: React.FC<ConfigureViewProps> = ({
  selected,
  kind,
  createNew,
  clearResource,
  handleSaveNew,
  resources,
  additionalInfo,
  resourceDocLink,
}) => {
  if (selected === null) {
    return <></>;
  }

  return (
    <ResourceConfigurationView
      kind={kind}
      includeNameField={kind === Kind.Destination && createNew}
      includeDisplayNameField={kind === Kind.Source}
      existingResourceNames={resources?.map((r) => r.metadata.name)}
      onBack={clearResource}
      onSave={(fv) => handleSaveNew(fv, selected)}
      resourceTypeDisplayName={selected.metadata.displayName ?? ""}
      description={selected.metadata.description ?? ""}
      parameterDefinitions={selected.spec.parameters ?? []}
      additionalInfo={additionalInfo}
      resourceDocLink={resourceDocLink}
      deprecated={!!selected.metadata.deprecated}
    />
  );
};
