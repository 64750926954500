import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthType } from "../../graphql/generated";
import { getAuthType } from "../../utils/get-auth-type";
import { GoogleLoginPage } from "./GoogleLogin";
import { UsernameLogin } from "./UsernameLogin";

export const LoginPage: React.FC = () => {
  const authType = getAuthType();
  const navigate = useNavigate();
  const user = localStorage.getItem("user");

  useEffect(() => {
    // Navigate to /agents page if user is logged in.
    if (user != null) {
      navigate("/agents");
    }
  }, [navigate, user]);

  switch (authType) {
    case AuthType.Google:
      return <GoogleLoginPage />;
    default:
      return <UsernameLogin />;
  }
};
