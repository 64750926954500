import { Button, Stack } from "@mui/material";
import { ActionsSection } from "../DialogComponents";
import {
  FormValues,
  initFormValues,
  isValid,
  useValidationContext,
  ValidationContextProvider,
} from "../ResourceConfigForm";
import { initFormErrors } from "../ResourceConfigForm/init-form-values";
import {
  FormValueContextProvider,
  useResourceFormValues,
} from "../ResourceConfigForm/ResourceFormContext";
import { ResourceForm } from "./ResourceForm";
import { GetResourceTypeQuery, Kind } from "../../graphql/generated";

import mixins from "../../styles/mixins.module.scss";

interface CreateConfigureViewProps {
  resourceKind: Kind.Processor | Kind.Extension;
  resourceType: NonNullable<GetResourceTypeQuery["resourceType"]>;
  onBack: () => void;
  onSave: (formValues: FormValues) => void;
  onClose: () => void;
}

const CreateConfigureViewComponent: React.FC<CreateConfigureViewProps> = ({
  resourceType,
  resourceKind,
  onSave,
  onBack,
  onClose,
}) => {
  const { formValues } = useResourceFormValues();
  const { touchAll, setErrors } = useValidationContext();

  function handleSave() {
    const errors = initFormErrors(
      resourceType.spec.parameters,
      formValues,
      resourceKind,
    );

    if (!isValid(errors)) {
      setErrors(errors);
      touchAll();
      return;
    }

    onSave(formValues);
  }

  return (
    <Stack className={mixins["flex-grow"]}>
      <ResourceForm
        title={resourceType.metadata.displayName ?? ""}
        description={resourceType.metadata.description ?? ""}
        additionalInfo={resourceType.metadata.additionalInfo}
        parameterDefinitions={resourceType.spec.parameters}
        deprecated={resourceType.metadata.deprecated ?? false}
        resourceDocLink={resourceType.metadata.resourceDocLink ?? ""}
      />

      <ActionsSection>
        <Button variant="outlined" color="secondary" onClick={onBack}>
          Cancel
        </Button>

        <Button variant="contained" color="primary" onClick={handleSave}>
          Done
        </Button>
      </ActionsSection>
    </Stack>
  );
};

export const CreateConfigureView: React.FC<CreateConfigureViewProps> = (
  props,
) => {
  const initValues = initFormValues(
    props.resourceType.spec.parameters,
    null,
    false,
  );
  const initErrors = initFormErrors(
    props.resourceType.spec.parameters,
    initValues,
    props.resourceKind,
  );
  return (
    <FormValueContextProvider initValues={initValues}>
      <ValidationContextProvider
        initErrors={initErrors}
        definitions={props.resourceType.spec.parameters}
      >
        <CreateConfigureViewComponent {...props} />
      </ValidationContextProvider>
    </FormValueContextProvider>
  );
};
