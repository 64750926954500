import { Kind, ResourceConfiguration } from "../../graphql/generated";
import { ResourceConfigurationLabelCard } from "./ResourceConfigurationLabelCard";

interface Props {
  index: number;
  item: ResourceConfiguration;
  onEdit: () => void;
  resourceKind: Kind.Processor | Kind.Extension;
}

export const ViewOnlyResourceConfigurationLabel: React.FC<Props> = ({
  index,
  item,
  onEdit,
  resourceKind,
}) => {
  return (
    <ResourceConfigurationLabelCard
      resourceKind={resourceKind}
      index={index}
      item={item}
      onEdit={onEdit}
    />
  );
};
