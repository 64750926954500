import colors from "../../../styles/colors";

export function highlightSearchQuery(
  text?: string | null,
  searchRegex?: RegExp | undefined,
): JSX.Element {
  if (!searchRegex || !text) {
    return <>{text}</>;
  }

  const parts = text.split(searchRegex);
  if (parts.length === 1) {
    return <>{text}</>;
  }

  return (
    <>
      {parts.map((part, index) =>
        index % 2 === 0 ? (
          part
        ) : (
          <span
            key={index}
            style={{
              backgroundColor: colors.palePrim,
            }}
          >
            {part}
          </span>
        ),
      )}
    </>
  );
}
