import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  DialogProps,
} from "@mui/material";

interface DiscardDialogProps extends DialogProps {
  onDiscard: () => void;
  onClose: () => void;
  open: boolean;
}

/**
 * DiscardDialog is used to confirm discarding a configuration version
 *
 * @param onDiscard Function to call when clicking 'Discard'
 * @param onClose Function to call when clicking 'Cancel'
 * @param open Whether the dialog is open or not
 */
export const DiscardDialog: React.FC<DiscardDialogProps> = ({
  onDiscard,
  onClose,
  open,
}) => {
  return (
    <Dialog data-testid="confirm-discard-dialog" open={open}>
      <DialogContent>
        <Typography>Are you sure you want to discard this draft?</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={onDiscard}
          data-testid="discard-dialog-discard-button"
        >
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  );
};
