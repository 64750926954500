import { Stack, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EmptyTableIcon } from "../../Icons";

// ConfigOnboardingNoRowsOverlay links to the configuration builder page
export const ConfigOnboardingNoRowsOverlay: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <EmptyTableIcon />
      <Button
        variant={"contained"}
        onClick={() => navigate("/configurations/new")}
      >
        Create a Configuration
      </Button>
      <Typography>
        Create a configuration to start shipping telemetry!
      </Typography>
    </Stack>
  );
};
