import { Stack, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EmptyTableIcon } from "../Icons";

// AgentOnboardingNoRowsOverlay links to the install agent page
export const AgentOnboardingNoRowsOverlay: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <EmptyTableIcon />
      <Button variant={"contained"} onClick={() => navigate("/agents/install")}>
        Install Your First Agent
      </Button>
      <Typography>Install an agent to start shipping telemetry!</Typography>
    </Stack>
  );
};
