/**
 * Platforms that agents can be installed on
 */
export enum Platform {
  KubernetesDaemonset = "kubernetes-daemonset",
  KubernetesDeployment = "kubernetes-deployment",
  kubernetesGateway = "kubernetes-gateway",
  Linux = "linux",
  macOS = "macos",
  OpenShiftDaemonset = "openshift-daemonset",
  OpenShiftDeployment = "openshift-deployment",
  Windows = "windows",
}
