/**
 * Cypress will use this key to set {@link cypressGoogleCredentials} in local storage
 */
export const CYPRESS_GOOGLE_CREDENTIALS_KEY = "CI_GOOGLE_CREDENTIALS";

/**
 * Cypress will set this in local storage to allow login without button interaction
 */
export interface CypressGoogleCredentials {
  token: string;
  user: {
    googleId: string;
    email: string;
    givenName: string;
    familyName: string;
    imageUrl: string;
  };
}
