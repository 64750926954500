import { Card, Stack, Typography, IconButton } from "@mui/material";
import { ApolloError } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  Kind,
  ResourceConfiguration,
  useGetResourceTypeQuery,
  useGetResourceWithTypeQuery,
} from "../../graphql/generated";
import { BPResourceConfiguration } from "../../utils/classes";
import { EditIcon, MenuIcon } from "../Icons";
import { trimVersion } from "../../utils/version-helpers";

import styles from "./resource-configuration-label-card.module.scss";
import colors from "../../styles/colors";

interface LabelCardProps {
  index: number;
  item: ResourceConfiguration;
  resourceKind: Kind.Processor | Kind.Extension;
  dragDropRef?: React.RefObject<HTMLDivElement>;
  isHovered?: boolean;
  onEdit: () => void;
}

export const ResourceConfigurationLabelCard: React.FC<LabelCardProps> = ({
  index,
  resourceKind,
  item,
  dragDropRef,
  isHovered,
  onEdit,
}) => {
  const resourceConfig = new BPResourceConfiguration(item);
  const { enqueueSnackbar } = useSnackbar();
  const [resourceTypeDisplayName, setResourceTypeDisplayName] =
    useState<string>("");
  const [resourceDisplayName, setResourceDisplayName] = useState<string>();

  function onError(error: ApolloError) {
    console.error(error);
    enqueueSnackbar("Error retrieving Resource Type", {
      variant: "error",
      key: "Error retrieving Resource Type",
    });
  }

  const resourceType =
    resourceKind === Kind.Processor ? Kind.ProcessorType : Kind.ExtensionType;

  useGetResourceTypeQuery({
    variables: { kind: resourceType, name: resourceConfig.type! },
    skip: !resourceConfig.isInline(),
    onError,
    onCompleted(data) {
      setResourceTypeDisplayName(data.resourceType!.metadata!.displayName!);
    },
  });

  useGetResourceWithTypeQuery({
    variables: { kind: resourceKind, name: resourceConfig.name! },
    skip: resourceConfig.isInline(),
    onError,
    onCompleted(data) {
      setResourceDisplayName(
        data.resourceWithType!.resource!.metadata!.displayName!,
      );
    },
  });

  const name = resourceConfig.name
    ? trimVersion(resourceConfig.name)
    : resourceTypeDisplayName;

  const displayName = resourceDisplayName
    ? resourceDisplayName
    : item.displayName;

  return (
    <Card
      variant="outlined"
      ref={dragDropRef}
      style={{
        border: isHovered ? `1px solid ${colors.pixelPointBlue}` : undefined,
      }}
      classes={{ root: styles.card }}
    >
      <Stack
        direction="row"
        alignItems={"center"}
        spacing={1}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} spacing={1}>
          <MenuIcon className={styles["hover-icon"]} />
          <Typography fontWeight={600}>
            {name}
            {displayName && ":"}
          </Typography>
          {displayName && <Typography>{displayName}</Typography>}
        </Stack>

        <IconButton
          onClick={onEdit}
          data-testid={`edit-${resourceKind.toLowerCase()}-${index}`}
        >
          <EditIcon width={15} height={15} style={{ float: "right" }} />
        </IconButton>
      </Stack>
    </Card>
  );
};
