import { Autocomplete } from "@mui/material";
import { memo } from "react";
import { ParamInputProps } from "./ParameterInput";
import { isEmpty, isFunction } from "lodash";
import { useValidationContext } from "../ValidationContext";
import { FormHelperText, Stack, TextField } from "@mui/material";
import {
  Log,
  Metric,
  Trace,
  useSnapshot,
} from "../../SnapShotConsole/SnapshotContext";

const OTTLFieldInputComponent: React.FC<ParamInputProps<string>> = ({
  definition,
  value,
  readOnly,
  onValueChange,
}) => {
  const { errors, touched, touch } = useValidationContext();
  const { logs, metrics, traces } = useSnapshot();

  function getOptions(
    logs: Log[],
    metrics: Metric[],
    traces: Trace[],
  ): string[] {
    let paths: string[] = [];

    switch (definition.options?.ottlContext) {
      case "body":
        logs.forEach((log) => {
          paths = getPaths(log.body, "", paths);
        });
        break;
      case "attributes":
        logs.forEach((log) => {
          paths = getPaths(log.attributes, "", paths);
        });
        metrics.forEach((metric) => {
          paths = getPaths(metric.attributes, "", paths);
        });
        traces.forEach((trace) => {
          paths = getPaths(trace.attributes, "", paths);
        });
        break;
      case "resource":
        logs.forEach((log) => {
          paths = getPaths(log.resource, "", paths);
        });
        metrics.forEach((metric) => {
          paths = getPaths(metric.resource, "", paths);
        });
        traces.forEach((trace) => {
          paths = getPaths(trace.resource, "", paths);
        });
    }

    return paths;
  }

  function getPaths(obj: any, prefix = "", paths: string[] = []): string[] {
    if (!isObject(obj)) return paths;

    for (const key in obj) {
      if (!obj.hasOwnProperty(key)) continue;
      if (key === "__bindplane_id__") continue;

      const formattedKey = prefix ? `["${key}"]` : key;
      const formattedPrefix =
        prefix && !isBracketNotation(prefix) ? `["${prefix}"]` : prefix;
      const path = prefix ? `${formattedPrefix}${formattedKey}` : formattedKey;

      if (!paths.includes(path)) {
        paths.push(path);
      }

      const value = obj[key];
      if (isObject(value)) {
        paths = getPaths(value, path, paths);
      }
    }

    return paths;
  }

  function isBracketNotation(key: string): boolean {
    return key.startsWith(`["`) && key.endsWith(`"]`);
  }

  function isObject(obj: any): boolean {
    return obj !== null && typeof obj === "object" && !Array.isArray(obj);
  }

  return (
    <Autocomplete
      freeSolo
      options={getOptions(logs, metrics, traces)}
      value={value}
      onChange={(_e, newValue) => {
        isFunction(onValueChange) && onValueChange(newValue || "");
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={params.InputProps}
          value={value || ""}
          onChange={(e) => {
            isFunction(onValueChange) && onValueChange(e.target.value || "");
          }}
          disabled={readOnly}
          onBlur={() => touch(definition.name)}
          name={definition.name}
          fullWidth
          size="small"
          label={definition.label}
          FormHelperTextProps={{
            sx: { paddingLeft: "-2px" },
          }}
          helperText={
            <>
              {errors[definition.name] && touched[definition.name] && (
                <>
                  <FormHelperText sx={{ marginLeft: 0 }} component="span" error>
                    {errors[definition.name]}
                  </FormHelperText>
                  <br />
                </>
              )}

              {!isEmpty(definition.description) && (
                <FormHelperText sx={{ marginLeft: 0 }} component="span">
                  {definition.description}
                </FormHelperText>
              )}
              {definition.documentation && (
                <Stack component={"span"}>
                  {definition.documentation?.map((d) => (
                    <a
                      href={d.url}
                      rel="noreferrer"
                      target="_blank"
                      key={d.url}
                    >
                      {d.text}
                    </a>
                  ))}
                </Stack>
              )}
            </>
          }
          required={definition.required}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        />
      )}
    />
  );
};

export const OTTLFieldInput = memo(OTTLFieldInputComponent);
