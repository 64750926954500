import { ApolloError, gql } from "@apollo/client";
import { Alert, Dialog, DialogProps, Stack } from "@mui/material";
import { isEqual } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { EESnapshotContextProvider } from "../../contexts/EESnapshotContext";
import {
  GetResourceTypesQuery,
  Kind,
  PipelineType,
  ResourceConfiguration,
  ResourceTypeKind,
  useGetResourceWithTypeQuery,
  useProcessorDialogDestinationTypeLazyQuery,
  useProcessorDialogSourceTypeLazyQuery,
  useUpdateProcessorsMutation,
} from "../../graphql/generated";
import { BPResourceConfiguration } from "../../utils/classes";
import { trimVersion } from "../../utils/version-helpers";
import { DialogContainer } from "../DialogComponents/DialogContainer";
import { usePipelineGraph } from "../PipelineGraph/PipelineGraphContext";
import { ResourceDialogContextProvider } from "../ResourceDialog/ResourceDialogContext";
import {
  SnapshotAction,
  SnapshotActionHandler,
  SnapshotActionType,
  SnapshotConsole,
} from "../SnapShotConsole/SnapShotConsole";
import { SnapShotControls } from "../SnapShotConsole/SnapShotControls";
import { useSnapshot } from "../SnapShotConsole/SnapshotContext";
import { SnapshotRegionMenu } from "./menu";
import { ResourceEditor, handleAction } from "./actions";
import { ResourceConfigurationEditor } from "../ResourceConfigurationEditor";
import { ProcessorTelemetrySwitcher } from "../ProcessorTelemetrySwitcher/ProcessorTelemetrySwitcher";
import {
  ReusableProcessors,
  withCommonProcessorDialog,
} from "../ProcessorsDialog/ProcessorDialog";

import consoleStyles from "../SnapShotConsole/snap-shot-console.module.scss";
import styles from "./ee-processor-dialog.module.scss";

gql`
  query processorDialogSourceType($name: String!) {
    sourceType(name: $name) {
      metadata {
        name
        id
        version
        displayName
        description
      }
      spec {
        telemetryTypes
      }
    }
  }

  query processorDialogDestinationType($name: String!) {
    destinationWithType(name: $name) {
      destinationType {
        metadata {
          id
          name
          version
          displayName
          description
        }
        spec {
          telemetryTypes
        }
      }
    }
  }

  mutation updateProcessors($input: UpdateProcessorsInput!) {
    updateProcessors(input: $input)
  }
`;

export interface ProcessorDialogProps extends DialogProps {
  processors: ResourceConfiguration[];
  reusableProcessors?: ReusableProcessors;
  fetchReusableProcessorsError?: ApolloError;
  refetchReusableProcessors: () => void;
  readOnly: boolean;
}

export type ProcessorType = GetResourceTypesQuery["resourceTypes"][0];

export const EEProcessorDialogComponent: React.FC<ProcessorDialogProps> = ({
  processors: processorsProp,
  reusableProcessors: reusableProcessorsProp,
  fetchReusableProcessorsError,
  refetchReusableProcessors,
  readOnly,
  ...dialogProps
}) => {
  const {
    editProcessorsInfo,
    configuration,
    closeProcessorDialog,
    refetchConfiguration,
    selectedTelemetryType,
    agentID,
  } = usePipelineGraph();

  const [processors, setProcessors] = useState(processorsProp);
  const { enqueueSnackbar } = useSnackbar();

  const resourceNameField = useMemo(() => {
    if (editProcessorsInfo) {
      return configuration?.spec?.sources?.[editProcessorsInfo.index].name;
    } else {
      console.error(`editProcessorsInfo is ${editProcessorsInfo}`);
    }
  }, [configuration, editProcessorsInfo]);

  // Get the type of the source or destination to which we're adding processors
  const resourceTypeName = useMemo(() => {
    try {
      switch (editProcessorsInfo?.resourceType) {
        case "source":
          return configuration?.spec?.sources?.[editProcessorsInfo.index].type;
        case "destination":
          return configuration?.spec?.destinations?.[editProcessorsInfo.index]
            .type;
        default:
          return null;
      }
    } catch (err) {
      return null;
    }
  }, [
    configuration?.spec?.destinations,
    configuration?.spec?.sources,
    editProcessorsInfo?.index,
    editProcessorsInfo?.resourceType,
  ]);

  /* ------------------------ GQL Mutations and Queries ----------------------- */
  const [updateProcessors] = useUpdateProcessorsMutation({});

  const [fetchSourceType, { data: sourceTypeData }] =
    useProcessorDialogSourceTypeLazyQuery({
      variables: { name: resourceTypeName ?? "" },
    });
  const [fetchDestinationType, { data: destinationTypeData }] =
    useProcessorDialogDestinationTypeLazyQuery();

  const { data: reusableSource, refetch: refetchReusableSource } =
    useGetResourceWithTypeQuery({
      skip: !resourceNameField,
      variables: {
        kind: Kind.Source,
        name: resourceNameField ?? "",
      },
      onError(error) {
        console.error(error);
        enqueueSnackbar("Failed to get source for processors.", {
          variant: "error",
          autoHideDuration: 5000,
        });
      },
    });

  useEffect(() => {
    function fetchData() {
      refetchReusableSource();
      if (editProcessorsInfo!.resourceType === "source") {
        fetchSourceType({ variables: { name: resourceTypeName ?? "" } });
      } else {
        const destName =
          configuration?.spec?.destinations?.[editProcessorsInfo!.index].name;
        fetchDestinationType({ variables: { name: destName ?? "" } });
      }
    }

    if (editProcessorsInfo == null) {
      return;
    }

    fetchData();
  }, [
    configuration?.spec?.destinations,
    editProcessorsInfo,
    fetchDestinationType,
    fetchSourceType,
    resourceTypeName,
    refetchReusableSource,
  ]);

  /* -------------------------------- Functions ------------------------------- */

  // handleClose is called when a user clicks off the dialog or the "X" button
  function handleClose() {
    if (!isEqual(processors, processorsProp)) {
      const ok = window.confirm("Discard changes?");
      if (!ok) {
        return;
      }
      // reset form values if chooses to discard.
      setProcessors(processorsProp);
    }

    closeProcessorDialog();
  }

  async function handleUpdateInlineProcessors(
    processors: ResourceConfiguration[],
  ): Promise<boolean> {
    let success = true;
    await updateProcessors({
      variables: {
        input: {
          configuration: configuration?.metadata?.name!,
          resourceType:
            editProcessorsInfo?.resourceType === "source"
              ? ResourceTypeKind.Source
              : ResourceTypeKind.Destination,
          resourceIndex: editProcessorsInfo?.index!,
          processors: processors,
        },
      },
      onError(error) {
        success = false;
        console.error(error);
        enqueueSnackbar("Failed to save processors", {
          variant: "error",
          key: "save-processors-error",
        });
      },
    });

    return success;
  }

  // displayName for sources is the sourceType name, for destinations it's the destination name
  const { resourceName, displayName } = useMemo(() => {
    if (editProcessorsInfo == null) {
      return {
        resourceName: "",
        displayName: "",
      };
    }
    if (editProcessorsInfo.resourceType === "source") {
      const name =
        configuration?.spec?.sources?.[editProcessorsInfo.index].name;
      const id = configuration?.spec?.sources?.[editProcessorsInfo.index].id;

      // named source
      if (name) {
        const trimName = trimVersion(name);
        return {
          resourceName: `${trimName}_${id}`,
          displayName: name,
        };
      }
      const resourceName = `source${editProcessorsInfo.index}_${id}`;
      return {
        resourceName: resourceName,
        displayName: sourceTypeData?.sourceType?.metadata.displayName,
      };
    }
    const name =
      configuration?.spec?.destinations?.[editProcessorsInfo.index].name;
    if (name) {
      const trimName = trimVersion(name);
      return {
        resourceName: `${trimName}-${editProcessorsInfo.index}`,
        displayName: trimName,
      };
    }
    return {
      resourceName: `dest${editProcessorsInfo.index}`,
      displayName: `dest${editProcessorsInfo.index}`,
    };
  }, [
    configuration?.spec?.destinations,
    configuration?.spec?.sources,
    editProcessorsInfo,
    sourceTypeData?.sourceType?.metadata.displayName,
  ]);

  let buttons: JSX.Element | undefined;

  const parentDisplayName = displayName ?? "unknown";
  const title = useMemo(() => {
    const kind =
      editProcessorsInfo?.resourceType === "source" ? "Source" : "Destination";
    return `${kind} ${trimVersion(parentDisplayName)}: Processors`;
  }, [editProcessorsInfo?.resourceType, parentDisplayName]);

  const description =
    "Processors are run on data after it's received and prior to being sent to a destination. They will be executed in the order they appear below.";

  const telemetryTypes =
    (editProcessorsInfo?.resourceType === "source"
      ? resourceNameField
        ? reusableSource?.resourceWithType.resourceType?.spec.telemetryTypes
        : sourceTypeData?.sourceType?.spec?.telemetryTypes
      : destinationTypeData?.destinationWithType.destinationType?.spec
          ?.telemetryTypes) ?? [];

  const snapshotPosition =
    editProcessorsInfo?.resourceType === "source" ? "s0" : "d0";

  const [action, setAction] = useState<SnapshotAction>({
    pipelineType: PipelineType.Logs,
    type: SnapshotActionType.NONE,
    data: {},
  });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const toggleActionMenu = (newAnchorEl: HTMLElement | null) => {
    if (anchorEl) {
      anchorEl.classList.remove(consoleStyles["menu-open"]);
    }
    setAnchorEl(newAnchorEl);
  };

  function addProcessor(type: string, params: Record<string, any>): void {
    const processorConfig = new BPResourceConfiguration();
    processorConfig.setParamsFromMap(params);
    processorConfig.type = type;

    const newProcessors = [...processors, processorConfig];
    setProcessors(newProcessors);
  }

  // editProcessor will edit an existing processor in the list of processors, returning
  // true if a match was found and the edit was successful.
  function editProcessor({ match, update }: ResourceEditor): boolean {
    const index = processors.findIndex(match);
    if (index === -1) {
      return false;
    }

    const processor = processors[index];
    const copy = Object.assign({}, processor);
    if (!update(copy)) {
      return false;
    }
    const newProcessors = [...processors];
    newProcessors[index] = copy;
    setProcessors(newProcessors);
    return true;
  }

  const onAction = (action: SnapshotAction) => {
    setAction(action);

    let showMenuAtElement: HTMLElement | null = null;
    switch (action.type) {
      case SnapshotActionType.OPEN_METRIC_NAME_MENU:
      case SnapshotActionType.OPEN_FIELD_ROW_MENU:
      case SnapshotActionType.OPEN_SEVERITY_MENU:
        showMenuAtElement = action.source ?? null;
        break;
      default:
        handleAction(action, { addProcessor, editProcessor });
        break;
    }

    toggleActionMenu(showMenuAtElement);
  };

  const menu = (
    <SnapshotRegionMenu
      open={open}
      anchorEl={anchorEl}
      action={action}
      onAction={onAction}
      onClose={() => toggleActionMenu(null)}
    />
  );

  return (
    <ResourceDialogContextProvider onClose={handleClose} purpose={"edit"}>
      <EESnapshotContextProvider
        pipelineType={convertTelemetryType(selectedTelemetryType)}
        processors={processors}
        position={snapshotPosition}
        resourceName={resourceName}
        showAgentSelector={true}
        agentID={agentID}
      >
        <Dialog {...dialogProps} fullScreen onClose={handleClose}>
          <DialogContainer
            title={title}
            description={description}
            onClose={handleClose}
            buttons={buttons}
          >
            <ProcessorsBody onAction={onAction} readOnly={readOnly}>
              <ResourceConfigurationEditor
                closeDialog={closeProcessorDialog}
                initItems={processorsProp}
                items={processors}
                kind={Kind.Processor}
                reusableResources={reusableProcessorsProp}
                fetchReusableResourcesError={fetchReusableProcessorsError}
                refetchReusableResources={refetchReusableProcessors}
                onItemsChange={setProcessors}
                refetchConfiguration={refetchConfiguration}
                telemetryTypes={telemetryTypes}
                updateInlineItems={handleUpdateInlineProcessors}
                readOnly={readOnly}
              />
            </ProcessorsBody>
          </DialogContainer>
          {menu}
        </Dialog>
      </EESnapshotContextProvider>
    </ResourceDialogContextProvider>
  );
};

function convertTelemetryType(telemetryType: string): PipelineType {
  switch (telemetryType) {
    case PipelineType.Logs:
      return PipelineType.Logs;
    case PipelineType.Metrics:
      return PipelineType.Metrics;
    case PipelineType.Traces:
      return PipelineType.Traces;
    default:
      return PipelineType.Logs;
  }
}

interface Props {
  onAction?: SnapshotActionHandler;
  readOnly: boolean;
}

export const ProcessorsBody: React.FC<Props> = ({
  children,
  onAction,
  readOnly,
}) => {
  const {
    logs,
    metrics,
    traces,
    processedLogs,
    processedMetrics,
    processedTraces,
    footer,
    processedFooter,
    error,
  } = useSnapshot();
  const [workingQuery, setWorkingQuery] = useState<string>("");

  return (
    <Stack
      height="100%"
      spacing={2}
      className={
        readOnly ? consoleStyles["read-only"] : consoleStyles["read-write"]
      }
    >
      {error && (
        <Alert sx={{ marginTop: 2 }} color="error">
          {error.message}
        </Alert>
      )}

      <Stack
        direction="row"
        flexGrow={1}
        spacing={2}
        className={
          readOnly ? consoleStyles["read-only"] : consoleStyles["read-write"]
        }
      >
        <div className={styles["snapshot-container-left"]}>
          <Stack spacing={2} height="100%">
            <SnapShotControls
              setWorkingQuery={setWorkingQuery}
              workingQuery={workingQuery}
            />
            <SnapshotConsole
              logs={logs}
              metrics={metrics}
              traces={traces}
              footer={footer}
              onAction={onAction}
              readOnly={readOnly}
            />
          </Stack>
        </div>
        <Stack height="100%" spacing={2}>
          <ProcessorTelemetrySwitcher setWorkingQuery={setWorkingQuery} />
          <div className={styles["form-container"]}>{children}</div>
        </Stack>
        <div className={styles["snapshot-container-right"]}>
          <SnapshotConsole
            hideControls
            logs={processedLogs}
            metrics={processedMetrics}
            traces={processedTraces}
            footer={processedFooter}
            onAction={onAction}
            readOnly={readOnly}
          />
        </div>
      </Stack>
    </Stack>
  );
};

export const EEProcessorDialog = withCommonProcessorDialog(
  EEProcessorDialogComponent,
);
