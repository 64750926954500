import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { withRequireLogin } from "../../contexts/RequireLogin";

import styles from "./license-required.module.scss";
import mixins from "../../styles/mixins.module.scss";

const LicenseRequiredPageContent: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Stack
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      className={styles.stack}
    >
      <Card classes={{ root: styles.card }}>
        <CardHeader title="License Required" />
        <CardContent>
          <Typography>
            A license is required to use BindPlane OP Enterprise Edition. Update
            your server configuration with a valid license and restart the
            server to use the application.
          </Typography>
        </CardContent>
        <CardActions className={styles.actions}>
          <Button
            href="mailto:support.bindplaneop@observiq.com"
            variant="outlined"
            color="secondary"
            className={mixins["mr-2"]}
          >
            Contact Support
          </Button>
          <Button onClick={() => navigate("/agents")} variant="contained">
            Refresh
          </Button>
        </CardActions>
      </Card>
    </Stack>
  );
};

export const LicenseRequiredPage = withRequireLogin(LicenseRequiredPageContent);
