import { useGetSourceTypeDisplayInfoQuery } from "../../../graphql/generated";
import styles from "./cells.module.scss";

interface ResourceTypeCellProps {
  type: string;
}

/**
 * Data grid cell that shows the icon and display name of a source type
 */
export const SourceTypeCell: React.FC<ResourceTypeCellProps> = ({ type }) => {
  const { data } = useGetSourceTypeDisplayInfoQuery({
    variables: { name: type },
  });
  return data?.sourceType ? (
    <div className={styles.cell}>
      <span
        className={styles.icon}
        style={{
          backgroundImage: `url(${data.sourceType?.metadata.icon ?? ""})`,
        }}
      />
      {data.sourceType?.metadata.displayName}
    </div>
  ) : (
    <div>{type}</div>
  );
};
