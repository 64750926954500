import { TextField, Stack, MenuItem, FormHelperText } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { isEmpty, isFunction } from "lodash";
import { ChangeEvent, memo, useState } from "react";
import { ParamInputProps } from "./ParameterInput";
import { useValidationContext } from "../ValidationContext";
import { validateStringsField } from "../validation-functions";

const EnumListInputComponent: React.FC<ParamInputProps<string[]>> = ({
  definition,
  value,
  readOnly,
  onValueChange,
}) => {
  const theme = useTheme();
  const { errors, setError, touched, touch } = useValidationContext();
  const [selection, setSelection] = useState<string[]>([]);

  const getItemStyle = (selections: string[], name: string, theme: Theme) => {
    return {
      fontWeight:
        selections.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightBold,
    };
  };

  const handleChange = (event: ChangeEvent<any>) => {
    const changedValue = event.target.value as string[];
    setSelection(changedValue);
    if (isFunction(onValueChange)) {
      onValueChange(changedValue);
    }

    if (!touched[definition.name]) {
      touch(definition.name);
    }

    setError(
      definition.name,
      validateStringsField(changedValue, definition.required),
    );
  };

  return (
    <TextField
      value={value}
      onChange={handleChange}
      disabled={readOnly}
      name={definition.name}
      fullWidth
      size="small"
      label={definition.label}
      helperText={
        <>
          {errors[definition.name] && touched[definition.name] && (
            <>
              <FormHelperText sx={{ marginLeft: 0 }} component="span" error>
                {errors[definition.name]}
              </FormHelperText>
              <br />
            </>
          )}

          {!isEmpty(definition.description) && (
            <FormHelperText sx={{ marginLeft: 0 }} component="span">
              {definition.description}
            </FormHelperText>
          )}
          {definition.documentation && (
            <Stack component={"span"}>
              {definition.documentation?.map((d) => (
                <a href={d.url} rel="noreferrer" target="_blank" key={d.url}>
                  {d.text}
                </a>
              ))}
            </Stack>
          )}
        </>
      }
      required={definition.required}
      select
      SelectProps={{ multiple: true }}
    >
      {definition.validValues?.map((v) => (
        <MenuItem key={v} value={v} style={getItemStyle(selection, v, theme)}>
          {v}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const EnumListInput = memo(EnumListInputComponent);
