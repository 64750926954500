import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  FormControl,
  Button,
  Autocomplete,
  TextField,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import { useMemo, useState } from "react";
import { getAccountsEnabled } from "../../components/EESettingsMenu/utils";
import {
  useGetConfigNamesQuery,
  useGetUsernamesQuery,
} from "../../graphql/generated";
import { gql } from "@apollo/client";
import { format, isValid } from "date-fns";
import { addOneDay } from "../../utils/time";

import styles from "./audit-logs-page.module.scss";
import { RefreshIcon } from "../../components/Icons";

gql`
  query getUsernames {
    users {
      metadata {
        id
        name
        version
        displayName
      }
    }
  }
`;

interface AuditLogsOptionsProps {
  selectedConfig: string | null;
  setSelectedConfig: (config: string | null) => void;
  selectedUser: string | null;
  setSelectedUser: (user: string | null) => void;
  minDate: Date | null;
  setMinDate: (date: Date | null) => void;
  maxDate: Date | null;
  setMaxDate: (date: Date | null) => void;
  onRefreshClick: () => void;
}

export const AuditLogsOptions: React.FC<AuditLogsOptionsProps> = ({
  selectedConfig,
  selectedUser,
  minDate,
  maxDate,
  setSelectedConfig,
  setSelectedUser,
  setMinDate,
  setMaxDate,
  onRefreshClick,
}) => {
  const accountsEnabled = getAccountsEnabled();

  const [configNames, setConfigNames] = useState<string[]>([]);
  const [usernames, setUsernames] = useState<string[]>([]);

  useGetConfigNamesQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setConfigNames([
        ...data.configurations.configurations.map((c) => c.metadata.name),
      ]);
    },
  });

  useGetUsernamesQuery({
    fetchPolicy: "network-only",
    skip: !accountsEnabled,
    onCompleted: (data) => {
      setUsernames([
        ...data.users.map((u) => u.metadata.displayName ?? u.metadata.name),
      ]);
    },
  });

  function handleMinDateChange(date: Date | null) {
    if (date === null || isValid(date)) {
      setMinDate(date);
    }
  }

  function handleMaxDateChange(date: Date | null) {
    if (date === null || isValid(date)) {
      setMaxDate(date);
    }
  }

  const downloadHref = useMemo(() => {
    const searchParams = new URLSearchParams();
    if (selectedConfig) {
      searchParams.set("configuration-name", selectedConfig);
    }

    if (selectedUser) {
      searchParams.set("user", selectedUser);
    }

    if (minDate) {
      searchParams.set("min-date", toAuditEventDateFormat(minDate));
    }

    if (maxDate) {
      const dayPlus1 = addOneDay(maxDate);
      if (dayPlus1 == null) {
        console.error(
          "Failed to add one day to max date, skipping search parameter for max-date",
        );
      } else {
        searchParams.set("max-date", toAuditEventDateFormat(dayPlus1));
      }
    }

    if (process.env.NODE_ENV === "development") {
      return `http://localhost:3001/v1/audit-events/download?${searchParams.toString()}`;
    }

    return `/v1/audit-events/download?${searchParams.toString()}`;
  }, [maxDate, minDate, selectedConfig, selectedUser]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      marginBottom={2}
      spacing={2}
    >
      <Stack direction="row" spacing={2}>
        <FormControl
          classes={{
            root: styles.select,
          }}
        >
          <Autocomplete
            value={selectedConfig}
            onChange={(_e, newValue) => setSelectedConfig(newValue)}
            options={configNames}
            size="small"
            renderInput={(params) => (
              <TextField {...params} label="Filter by Configuration" />
            )}
          />
        </FormControl>

        {accountsEnabled && (
          <FormControl
            classes={{
              root: styles.select,
            }}
          >
            <Autocomplete
              value={selectedUser}
              onChange={(_e, newValue) => setSelectedUser(newValue)}
              options={usernames}
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Filter by User" />
              )}
            />
          </FormControl>
        )}
      </Stack>

      <Stack direction="row" alignItems="center" spacing={2}>
        <DatePicker
          maxDate={maxDate ?? new Date()}
          slotProps={{
            textField: {
              size: "small",
            },
            actionBar: {
              actions: ["clear"],
            },
          }}
          value={minDate}
          onChange={handleMinDateChange}
        />

        <Typography>To</Typography>
        <DatePicker
          minDate={minDate ?? undefined}
          maxDate={new Date()}
          slotProps={{
            textField: {
              size: "small",
            },

            actionBar: {
              actions: ["clear"],
            },
          }}
          value={maxDate}
          onChange={handleMaxDateChange}
        />
      </Stack>

      <Stack direction="row" spacing={2}>
        <Button
          size="large"
          variant="contained"
          href={downloadHref}
          classes={{
            root: styles.button,
          }}
        >
          Export CSV
        </Button>
        <IconButton onClick={onRefreshClick} color="primary">
          <RefreshIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

// toAuditEventDateFormat converts a Date object
// to the string of formatYYYYMMDDHHMMSS in UTC timezone.
export function toAuditEventDateFormat(date: Date): string {
  const utcDate = date.getUTCDate();
  const utcMonth = date.getUTCMonth();
  const utcFullYear = date.getUTCFullYear();

  const utc = new Date(Date.UTC(utcFullYear, utcMonth, utcDate));
  return format(utc, "yyyyMMddHHmmss");
}
