import React from "react";
import Button from "@mui/material/Button";
import { ArrowDropDownIcon, ArrowDropUpIcon } from "../Icons";

import { classes } from "../../utils/styles";
import styles from "./agent-select.module.scss";

interface SelectButtonProps {
  label: string;
  open: boolean;
  setAnchorEl: (value: React.SetStateAction<HTMLButtonElement | null>) => void;
}

export const SelectButton: React.FC<SelectButtonProps> = ({
  label,
  open,
  setAnchorEl,
}) => {
  return (
    <Button
      className={classes([
        styles["dropdown-like-button"],
        open ? styles.open : undefined,
      ])}
      disableRipple
      size="medium"
      variant="outlined"
      onClick={(event) => {
        setAnchorEl(event.currentTarget);
      }}
      endIcon={
        open ? (
          <ArrowDropUpIcon className={styles.arrow} />
        ) : (
          <ArrowDropDownIcon className={styles.arrow} />
        )
      }
    >
      <span className={styles.text}>{label}</span>
    </Button>
  );
};
