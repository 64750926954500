import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  split,
  from,
  FieldMergeFunction,
  InMemoryCacheConfig,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";
import { isFunction } from "lodash";
import { getWebsocketClient } from "./websocket";

const httpLink = new HttpLink({
  uri: "/v1/graphql",
  credentials: "same-origin",
});

const wsLink = new GraphQLWsLink(getWebsocketClient());

// Use the httpLink for queries and wsLink for subscriptions
export const requestLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink,
);

/**
 * merge is used to merge fields that do not have a unique identifier.
 * This is discussed in apollo documentation here:
 * https://www.apollographql.com/docs/react/caching/cache-field-behavior
 */
const merge: FieldMergeFunction = (existing, incoming, { mergeObjects }) => {
  return mergeObjects(existing, incoming);
};

export const typePolicies: InMemoryCacheConfig["typePolicies"] = {
  Agent: {
    keyFields: ["id"],
  },
  Configuration: {
    keyFields: ["metadata"],
    fields: {
      status: {
        merge,
      },
    },
  },
  SourceType: {
    keyFields: ["metadata"],
    fields: {
      spec: {
        merge,
      },
    },
  },
  DestinationType: {
    keyFields: ["metadata"],
    fields: {
      spec: {
        merge,
      },
    },
  },
  Destination: {
    keyFields: ["metadata"],
    fields: {
      spec: {
        merge,
      },
    },
  },
  ExtensionType: {
    keyFields: ["metadata"],
    fields: {
      spec: {
        merge,
      },
    },
  },
  Extension: {
    keyFields: ["metadata"],
    fields: {
      spec: {
        merge,
      },
    },
  },
  Metadata: {
    keyFields: ["id", "name", "version"],
  },
  ResourceConfiguration: {
    keyFields: ["id", "name", "type"],
  },
};

// authErrorLink will log a user out if a graphql query or
// subscription returns with a 401 unauthorized.
const authErrorLink = onError(({ operation }) => {
  const context = operation.getContext();

  if (context.response.status === 401) {
    logoutAndResetApolloCache();
  }
});

// forbiddenErrorLink will log a user out if a graphql query or
// subscription returns with a 403 forbidden.
const forbiddenErrorLink = onError(({ operation }) => {
  const context = operation.getContext();

  if (context.response.status === 403) {
    logoutAndResetApolloCache();
  }
});

const licenseErrorLink = onError(({ operation }) => {
  const context = operation.getContext();

  if (context.response.status === 452) {
    window.location.pathname = "/license-required";
    console.error("License required");
  }

  if (context.response.status === 453) {
    window.location.pathname = "/eula-required";
    console.error("eula required");
  }
});

// Chain the authLink, forbiddenErrorLink, licenseErrorLink and requestLink together
const link = from([
  authErrorLink,
  forbiddenErrorLink,
  licenseErrorLink,
  requestLink,
]);

const APOLLO_CLIENT = new ApolloClient({
  link: link,
  cache: newInMemoryCache(),
});

export function newInMemoryCache() {
  return new InMemoryCache({
    typePolicies: {
      ...typePolicies,
      User: {
        keyFields: ["metadata"],
      },
      Account: {
        keyFields: ["metadata"],
      },
      Query: {
        fields: {
          users: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
          configuration: {
            merge,
          },
        },
      },
    },
  });
}

export default APOLLO_CLIENT;

async function logoutAndResetApolloCache() {
  localStorage.removeItem("user");
  window.location.pathname = "/login";

  if (isFunction(window.resetApolloClient)) {
    await window.resetApolloClient();
  }
}
