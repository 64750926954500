import { Typography } from "@mui/material";
import { version, edition } from "./utils";

import styles from "./version.module.scss";
import { getSaasEnabled } from "../../utils/get-saas-enabled";

// Version displays the server version received from the /version endpoint.
export const BindplaneVersion: React.FC = () => {
  return (
    <Typography
      variant="body2"
      fontWeight={300}
      classes={{ root: styles.root }}
    >
      {getSaasEnabled() ? "Cloud" : `${edition()} Edition`} {version()}
    </Typography>
  );
};
