import { memo } from "react";
import { Log, Metric, Trace, PipelineType } from "../../../graphql/generated";
import { LogRecordRow } from "./LogRecordRow";
import { MetricsRecordRow } from "./MetricsRecordRow";
import { TraceRecordRow } from "./TraceRecordRow";

export type AttributesProvider = () => any;

const SnapShotRowComponent: React.FC<{
  message: Log | Metric | Trace;
  type: PipelineType;
}> = ({ type, message }) => {
  // provide access to the modified message attributes
  const attributes: AttributesProvider = () => {
    const attrs = Object.assign({}, message.attributes);
    delete attrs["__bindplane_id__"];
    return attrs;
  };

  // extract the bindplaneID from the message attributes
  const bindplaneID = message.attributes?.["__bindplane_id__"];

  switch (type) {
    case PipelineType.Logs:
      return (
        <LogRecordRow
          message={message! as Log}
          attributes={attributes}
          bindplaneID={bindplaneID}
        />
      );
    case PipelineType.Metrics:
      return (
        <MetricsRecordRow
          message={message! as Metric}
          attributes={attributes}
          bindplaneID={bindplaneID}
        />
      );
    case PipelineType.Traces:
      return (
        <TraceRecordRow
          message={message! as Trace}
          attributes={attributes}
          bindplaneID={bindplaneID}
        />
      );
  }
};

export const SnapshotRow = memo(SnapShotRowComponent);
