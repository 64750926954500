import React from "react";
import APOLLO_CLIENT from "./apollo-client";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import {
  ConfigurationsPage,
  AgentsPage,
  NewConfigurationPage,
  AgentInstallPage,
  AgentPage,
} from "./pages";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { ConfigurationPage } from "./pages/configurations/configuration";
import { NewRawConfigurationPage } from "./pages/configurations/new-raw";
import { SnackbarProvider } from "notistack";
import { OverviewPage } from "./pages/overview/OverviewPage";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { LoginPage } from "./pages/login";
import { AccountPage, SetupAccountPage } from "./pages/account";
import { AcceptInvitationPage } from "./pages/accept-invitation";
import { SignUpPage } from "./pages/signup";
import { LicenseRequiredPage } from "./pages/license-required/LicenseRequiredPage";
import { theme } from "./theme";
import { EulaRequiredPage } from "./pages/eula-required/EulaRequiredPage";
import { AuditLogsPage } from "./pages/audit-logs/AuditLogsPage";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { SummaryPage } from "./pages/overview/SummaryPage";
import { ResourceLibraryPage } from "./pages/resource-library/ResourceLibraryPage";
import { AppOnboarding } from "./pages/app-onboarding";

import "reactflow/dist/style.css";

export const App: React.FC = () => {
  const googleClientId = getGoogleClientId();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={APOLLO_CLIENT}>
          <GoogleOAuthProvider clientId={googleClientId}>
            <SnackbarProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <BrowserRouter>
                  <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <Routes>
                      {/* Unauthenticated Routes */}
                      <Route path="/login" element={<LoginPage />} />
                      <Route
                        path="/accept-invitation"
                        element={<AcceptInvitationPage />}
                      />
                      <Route path="/sign-up" element={<SignUpPage />} />

                      {/** App Routes */}
                      <Route path="/overview">
                        <Route index element={<OverviewPage />} />
                        <Route path="summary" element={<SummaryPage />} />
                      </Route>
                      <Route path="/" element={<Navigate to="/overview" />} />
                      <Route path="agents">
                        <Route index element={<AgentsPage />} />
                        <Route path="install" element={<AgentInstallPage />} />
                        <Route path=":id">
                          <Route index element={<AgentPage />} />
                        </Route>
                      </Route>
                      <Route path="configurations">
                        <Route index element={<ConfigurationsPage />} />
                        <Route
                          path="new-raw"
                          element={<NewRawConfigurationPage />}
                        />
                        <Route path="new" element={<NewConfigurationPage />} />
                        <Route path=":name" element={<ConfigurationPage />} />
                      </Route>
                      <Route path="resource-library">
                        <Route index element={<ResourceLibraryPage />} />
                      </Route>
                      <Route path="account">
                        <Route index element={<AccountPage />} />
                        <Route
                          path="/account/new"
                          element={<SetupAccountPage />}
                        />
                      </Route>
                      <Route path="audit-logs">
                        <Route index element={<AuditLogsPage />} />
                      </Route>
                      <Route
                        path="license-required"
                        element={<LicenseRequiredPage />}
                      />
                      <Route
                        path="eula-required"
                        element={<EulaRequiredPage />}
                      />
                      <Route
                        path="/getting-started"
                        element={<AppOnboarding />}
                      />
                    </Routes>
                  </QueryParamProvider>
                </BrowserRouter>
              </LocalizationProvider>
            </SnackbarProvider>
          </GoogleOAuthProvider>
        </ApolloProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

function getGoogleClientId(): string {
  try {
    return __GOOGLE_CLIENT_ID__;
  } catch (err) {
    return "";
  }
}
