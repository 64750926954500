/**
 * handleLoginResponse calls the appropriate callback for the given
 * response status.
 * 401 indicates a failure to authenticate
 * 403 indicates the user does not have access to an account
 * 409 indicates the user failed to accept an invitation
 * onFailure is the catch all for other non success responses.
 */
export async function handleLoginResponse({
  status,
  onSuccess,
  on401,
  on403,
  on409,
  onFailure,
}: {
  status: number;
  onSuccess: () => Promise<void>;
  on401: () => Promise<void>;
  on403: () => Promise<void>;
  on409: () => Promise<void>;
  onFailure: () => Promise<void>;
}) {
  switch (status) {
    case 200:
      await onSuccess();
      return;

    case 401:
      await on401();
      return;

    case 403:
      await on403();
      return;

    case 409:
      await on409();
      return;

    default:
      await onFailure();
  }
}
