import { AppBar, IconButton, Toolbar } from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  AgentGridIcon,
  OverviewIcon,
  SlidersIcon,
  BookmarkIcon,
  MenuIcon,
} from "../Icons";
import { BindPlaneLogo } from "../Logos";
import { classes } from "../../utils/styles";

import styles from "./nav-bar.module.scss";
import { persistGlobalSearch } from "../SearchLink/SearchLink";
import { EESettingsMenu } from "../EESettingsMenu";

export const NavBar: React.FC = () => {
  const [settingsAnchorEl, setAnchorEl] = useState<Element | null>(null);
  const settingsOpen = Boolean(settingsAnchorEl);

  const navigate = useNavigate();

  // make navigate available to the global window
  // to let us use it outside of components.
  useEffect(() => {
    window.navigate = navigate;
  }, [navigate]);

  const handleSettingsClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();
  return (
    <>
      <AppBar position="sticky" classes={{ root: styles["app-bar-root"] }}>
        <Toolbar classes={{ root: styles.toolbar }}>
          <Link
            to={{
              pathname: "/overview",
              search: persistGlobalSearch(location),
            }}
          >
            <BindPlaneLogo
              className={styles.logo}
              aria-label="bindplane-logo"
            />
          </Link>

          <div className={styles["main-nav"]}>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? classes([styles["nav-link"], styles["active"]])
                  : styles["nav-link"]
              }
              to={{
                pathname: "/overview",
                search: persistGlobalSearch(location),
              }}
              data-testid="navbar-overview-link"
            >
              {({ isActive }) => {
                const className = isActive
                  ? classes([styles["icon"], styles["active"]])
                  : styles["icon"];
                return (
                  <>
                    <OverviewIcon className={className} />
                    Overview
                  </>
                );
              }}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? classes([styles["nav-link"], styles["active"]])
                  : styles["nav-link"]
              }
              to={{
                pathname: "/agents",
                search: persistGlobalSearch(location),
              }}
              data-testid="navbar-agents-link"
            >
              {({ isActive }) => {
                const className = isActive
                  ? classes([styles["icon"], styles["active"]])
                  : styles["icon"];
                return (
                  <>
                    <AgentGridIcon className={className} />
                    Agents
                  </>
                );
              }}
            </NavLink>

            <NavLink
              className={({ isActive }) =>
                isActive
                  ? classes([styles["nav-link"], styles["active"]])
                  : styles["nav-link"]
              }
              to={{
                pathname: "/configurations",
                search: persistGlobalSearch(location),
              }}
              data-testid="navbar-configurations-link"
            >
              {({ isActive }) => {
                const className = isActive
                  ? classes([styles["icon"], styles["active"]])
                  : styles["icon"];
                return (
                  <>
                    <SlidersIcon className={className} />
                    Configurations
                  </>
                );
              }}
            </NavLink>

            <NavLink
              className={({ isActive }) =>
                isActive
                  ? classes([styles["nav-link"], styles["active"]])
                  : styles["nav-link"]
              }
              to={{
                pathname: "/resource-library",
                search: persistGlobalSearch(location),
              }}
              data-testid="navbar-resource-library-link"
            >
              {({ isActive }) => {
                const className = isActive
                  ? classes([styles["icon"], styles["active"]])
                  : classes([styles["icon"]]);
                return (
                  <>
                    <BookmarkIcon className={className} />
                    Library
                  </>
                );
              }}
            </NavLink>
          </div>

          <div className={styles["sub-nav"]}>
            <IconButton
              className={styles.button}
              aria-controls={settingsOpen ? "settings-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={settingsOpen ? "true" : undefined}
              color="inherit"
              data-testid="settings-button"
              onClick={handleSettingsClick}
            >
              <MenuIcon className={styles.icon} />
            </IconButton>
            <EESettingsMenu
              anchorEl={settingsAnchorEl}
              onClose={handleSettingsClose}
              open={settingsOpen}
            />
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};
