import { debounce } from "lodash";
import {
  useConfigurationSpecChangesSubscription,
  useConfigurationStatusChangesSubscription,
} from "../graphql/generated";
import { useOnWebsocketConnected } from "./useOnWebsocketConnected";
import { gql } from "@apollo/client";

gql`
  subscription ConfigurationStatusChanges($name: String!) {
    configurationStatusChanges(name: $name) {
      configuration {
        metadata {
          id
          version
          name
          description
          labels
        }
        agentCount
      }
      eventType
    }
  }
  subscription ConfigurationSpecChanges($name: String!) {
    configurationSpecChanges(name: $name) {
      configuration {
        metadata {
          id
          version
          name
          description
          labels
        }
        agentCount
      }
      eventType
    }
  }
`;
/**
 * useRefetchOnConfigurationChange will call the provided refetch when
 * changes are received via the ConfigurationSpecChanges subscription.  The
 * refetch is called right away on first change and then debounced
 * with a wait of 1 second.
 *
 * @param configurationName the name of the configuration.
 * @param refetch the refetch function to call when changes are received.
 */
export function useRefetchOnConfigurationChange(
  configurationName: string,
  refetch: () => void,
) {
  const debouncedRefetch = debounce(refetch, 1000, { leading: true });

  useConfigurationSpecChangesSubscription({
    variables: {
      name: configurationName,
    },
    onData: () => {
      debouncedRefetch();
    },
  });

  // If the websocket connection is broken, we may miss events, so we should refetch once the connection is re-established.
  useOnWebsocketConnected(debouncedRefetch);
}

/**
 * useRefetchOnConfigurationChange will call the provided refetch when
 * changes are received via the ConfigurationStatusChanges subscription.  The
 * refetch is called right away on first change and then debounced
 * with a wait of 1 second.
 *
 * @param configurationName the name of the configuration.
 * @param refetch the refetch function to call when changes are received.
 */
export function useRefetchOnConfigurationStatusChange(
  configurationName: string,
  refetch: () => void,
) {
  const debouncedRefetch = debounce(refetch, 1000, { leading: true });

  useConfigurationStatusChangesSubscription({
    variables: {
      name: configurationName,
    },
    onData: () => {
      debouncedRefetch();
    },
  });

  // If the websocket connection is broken, we may miss events, so we should refetch once the connection is re-established.
  useOnWebsocketConnected(debouncedRefetch);
}
