import { Stack, Typography } from "@mui/material";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { useSnackbar } from "notistack";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AuthType } from "../../graphql/generated";
import { getAuthType } from "../../utils/get-auth-type";
import { handleLoginResponse } from "../login/handleLoginResponse";
import { Diagram } from "./Diagram";
import { currentYear } from "../../utils/time";

import {
  BindPlaneLogoBlueGrey,
  BindPlaneOPLogoWhite,
} from "../../components/Logos";

import styles from "./sign-up-page.module.scss";
import colors from "../../styles/colors";

export const SignUpPage: React.FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  if (getAuthType() !== AuthType.Google) {
    return <Navigate to="/login" />;
  }

  async function onSuccess(res: CredentialResponse) {
    try {
      const resp = await fetch("/login", {
        method: "POST",
        body: JSON.stringify(res),
      });

      handleLoginResponse({
        status: resp.status,
        onSuccess: async () => {
          localStorage.setItem("user", "googleUser");
          navigate("/agents");
        },
        on401: async () => {
          enqueueSnackbar("Oops! Something went wrong.", { variant: "error" });
        },
        on403: async () => {
          localStorage.setItem("user", "googleUser");
          navigate("/account/new");
        },
        on409: async () => {},
        onFailure: async () => {
          enqueueSnackbar("Oops! Something went wrong.", { variant: "error" });
        },
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Oops! Something went wrong.", { variant: "error" });
    }
  }
  return (
    <div className={styles.page}>
      <div className={styles["header-img"]} />

      <BindPlaneOPLogoWhite className={styles.logo} />
      <a
        target="_blank"
        rel="noreferrer"
        href="https://observiq.com/bindplaneop-demo/"
        className={styles.demo}
      >
        Request a Demo
      </a>

      <Stack direction="row" alignItems="center">
        <Stack borderRight="2px solid #D2D3D7" paddingRight={3} width={825}>
          <Typography className={styles.h}>What is BindPlane OP?</Typography>

          <Typography marginBottom={2}>
            <strong>BindPlane OP</strong> is an open source observability
            pipeline that gives you the ability to collect, refine, and ship
            metrics, logs, and traces to any destination. BindPlane OP provides
            the controls you need to reduce observability costs and simplify the
            deployment and management of telemetry agents at scale.
          </Typography>

          <Stack direction="row" spacing={5} alignItems="center">
            <Diagram width={1200} />

            <div>
              <Typography className={styles.h}>Key Benefits</Typography>
              <Stack spacing={2}>
                <Typography>
                  <strong>Simplify</strong>: Simplified deployment,
                  configuration, and management of all your agents.
                </Typography>

                <Typography>
                  <strong>Standardize</strong>: Instrument once, send anywhere.
                  BindPlane OP gives you the power to choose best of breed
                  solutions without vendor lock-in.
                </Typography>

                <Typography>
                  <strong>Reduce</strong>: Enables a reduction in your data,
                  your costs, and the time spent managing your telemetry data.
                </Typography>

                <Typography>
                  <strong>Control</strong>: Refine telemetry by filtering,
                  sampling, and enriching data easily in real time.
                </Typography>
              </Stack>
            </div>
          </Stack>
        </Stack>

        <Stack
          alignItems="center"
          justifyContent="center"
          height="100%"
          spacing={2}
          paddingLeft={10}
        >
          <BindPlaneLogoBlueGrey width={160} />
          <GoogleLogin
            text="signup_with"
            onSuccess={onSuccess}
            onError={() => {
              enqueueSnackbar("Oops! Something went wrong.", {
                variant: "error",
              });
            }}
            width="160"
          />
          <div>
            <Typography fontSize={13} color={colors.darkGray}>
              Already have an account?{" "}
              <Link to={"/login"} style={{ textDecoration: "none" }}>
                Log in
              </Link>
            </Typography>
          </div>
        </Stack>
      </Stack>

      <footer className={styles.footer}>
        © {currentYear()} observIQ, Inc. All rights reserved. Legal and Privacy.
      </footer>

      <div className={styles["footer-img"]} />
    </div>
  );
};
