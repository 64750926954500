import { gql } from "@apollo/client";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useMemo } from "react";
import {
  GetRolloutHistoryQuery,
  useGetRolloutHistoryQuery,
} from "../../graphql/generated";
import { format } from "date-fns";
import { useRefetchOnConfigurationChange } from "../../hooks/useRefetchOnConfigurationChanges";
import { LinkButton } from "../LinkButton";

import styles from "./rollout-history.module.scss";

gql`
  query getRolloutHistory($name: String!) {
    configurationHistory(name: $name) {
      metadata {
        name
        id
        version
        dateModified
      }
      status {
        rollout {
          status
          errors
          startedAt
        }
      }
    }
  }
`;

interface RolloutHistoryProps {
  configurationName: string;
  handleHistoryVersionClick: (v: number) => void;
  setDialogOpen: (b: boolean) => void;
}

export const RolloutHistory: React.FC<RolloutHistoryProps> = ({
  configurationName,
  handleHistoryVersionClick,
  setDialogOpen,
}) => {
  const { data, refetch } = useGetRolloutHistoryQuery({
    variables: {
      name: configurationName,
    },
    fetchPolicy: "cache-and-network",
  });

  useRefetchOnConfigurationChange(configurationName, refetch);

  const messages = useMemo(() => {
    if (!data) {
      return [];
    }

    return makeMessages(data, handleHistoryVersionClick, setDialogOpen);
  }, [data, handleHistoryVersionClick, setDialogOpen]);

  return (
    <Box aria-describedby={"rollout-history-loading"} aria-busy={data == null}>
      <Stack>{messages.slice(0, 1)}</Stack>
      {data == null ? (
        <Stack width="100%" alignItems={"center"} justifyContent="center">
          <CircularProgress
            size={24}
            id="rollout-history-loading"
            data-testid="circular-progress"
            disableShrink
          />
        </Stack>
      ) : (
        <>{messages.slice(1)}</>
      )}
    </Box>
  );
};

/**
 * makeMessages takes in the data from the getRolloutHistory query and returns
 * all the messages to be displayed in the RolloutHistory component.
 *
 * @param data the data from the getRolloutHistory query
 */
function makeMessages(
  data: NonNullable<GetRolloutHistoryQuery>,
  handleHistoryVersionClick: (v: number) => void,
  setDialogOpen: (b: boolean) => void,
): JSX.Element[] {
  const epochStartTime = new Date(0).getTime();

  const messagesLength = data.configurationHistory.length;

  const messages = data.configurationHistory.map((history) => {
    const { metadata, status } = history;
    const { version, dateModified } = metadata;
    const { rollout } = status;
    const { status: rolloutStatus, errors } = rollout;
    const { startedAt } = rollout;

    // Check if startedAt is the beginning of the epoch or if status is not "rollout started"
    const date =
      new Date(startedAt).getTime() === epochStartTime || rolloutStatus !== 1
        ? new Date(dateModified)
        : new Date(startedAt);

    const action = rolloutStatusToAction[rolloutStatus];
    const withErrors =
      errors > 0 ? (
        <>
          <Typography component="span"> with</Typography>
          <Typography color="error" component="span">
            {" "}
            {errors} error{errors > 1 && "s"}
          </Typography>
        </>
      ) : null;

    // If there's only one version in the history, don't link to compare versions
    return messagesLength > 1 ? (
      <Typography
        key={`${date.toString()}-${version}-${status}`}
        data-testid={`rollout-history-row-${version}`}
      >
        Version{" "}
        <LinkButton
          onClick={() => {
            handleHistoryVersionClick(version);
            setDialogOpen(false);
          }}
          buttonClassName={styles["version-link"]}
        >
          {version}
        </LinkButton>{" "}
        {action}
        {withErrors} on {formatDate(date)} at {formatTime(date)}
      </Typography>
    ) : (
      <Typography key={`${date.toString()}-${version}-${status}`}>
        Version {version} {action}
        {withErrors} on {formatDate(date)} at {formatTime(date)}
      </Typography>
    );
  });

  return messages;
}

export function formatDate(date: Date) {
  return format(date, "M/dd/yyyy");
}

export function formatTime(date: Date) {
  return format(date, "HH:mm");
}

const rolloutStatusToAction: Record<number, string> = {
  0: "pending rollout",
  1: "rollout started",
  2: "rollout paused",
  3: "rollout paused",
  4: "completed",
  5: "rollout replaced",
};
