import { PaginatedAgentsTableQuery } from "../../graphql/generated";

export type TableAgent = PaginatedAgentsTableQuery["agentsTable"]["agents"][0];
export type AgentMetrics =
  PaginatedAgentsTableQuery["agentsTable"]["agentMetrics"];

export enum AgentsTableField {
  NAME = "name",
  STATUS = "status",
  VERSION = "version",
  CONFIGURATION = "configuration",
  CONFIGURATION_VERSION = "configurationVersion",
  OPERATING_SYSTEM = "operatingSystem",
  LABELS = "labels",
  LOGS = "logs",
  METRICS = "metrics",
  TRACES = "traces",
}
