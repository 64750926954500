import { withEENavBar } from "../../../components/EENavBar";
import { withRBAC } from "../../../contexts/RBAC";
import { useState } from "react";
import { useGetFeatureGateQuery } from "../../../graphql/generated";
import { Navigate } from "react-router-dom";
import { withRequireLogin } from "../../../contexts/RequireLogin";
import {
  Alert,
  Box,
  Grid,
  GridProps,
  Stack,
  StackProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SummaryPageCard } from "../../../components/SummaryPageCard";
import { AgentMonitoring } from "../../../components/AgentMonitoring";
import { ConfigurationMonitoring } from "../../../components/ConfigurationMonitoring";
import { DataSummarySection } from "../../../components/DataSummarySection";
import { RecentActivitySection } from "./RecentActivitySection";
import { ConfigurationSummarySection } from "../../../components/ConfigurationSummarySection/ConfigurationSummarySection";
import { SummaryPageContextProvider } from "./SummaryPageContext";

export const SummaryPageContent: React.FC = () => {
  const [enabled, setEnabled] = useState<boolean>();

  const theme = useTheme();
  const isLgOrLarger = useMediaQuery(theme.breakpoints.up("lg"));

  const LayoutContainer = isLgOrLarger ? FlexContainer : GridContainer;

  const outerContainerProps = isLgOrLarger
    ? ({
        spacing: 2,
        direction: "row",
        marginLeft: "-16px",
        marginRight: "16px",
      } as StackProps)
    : ({ container: true, spacing: 2, wrap: "wrap-reverse" } as GridProps);

  const column1Props = isLgOrLarger
    ? ({ width: 385, spacing: 2 } as StackProps)
    : ({ lg: 4, md: 12, item: true } as GridProps);

  const column2Props = isLgOrLarger
    ? ({ flexGrow: 1, spacing: 2 } as StackProps)
    : ({ lg: 8, md: 12, item: true } as GridProps);

  useGetFeatureGateQuery({
    variables: {
      input: "overview-summary-page",
    },
    onCompleted(data) {
      setEnabled(data.enabled);
    },
  });

  if (enabled === false) {
    return <Navigate to="/overview" />;
  }

  return (
    <SummaryPageContextProvider>
      <Box maxWidth={1600} margin="auto">
        <Alert
          severity="info"
          style={{
            marginTop: -16,
            marginBottom: isLgOrLarger ? undefined : 16,
          }}
        >
          <Typography fontWeight={600}>Summary is in Beta.</Typography>
        </Alert>

        {/* Page Container */}
        <LayoutContainer {...outerContainerProps}>
          {/* Column 1 */}
          <LayoutContainer {...column1Props}>
            <Grid container spacing={2}>
              <Grid item lg={12} xs={6}>
                <SummaryPageCard
                  title="Monitoring"
                  minHeight={615}
                  minWidth={385}
                >
                  <Stack spacing={2}>
                    <AgentMonitoring />
                    <ConfigurationMonitoring />
                  </Stack>
                </SummaryPageCard>
              </Grid>

              <Grid item lg={12} xs={6}>
                <SummaryPageCard
                  title="Recent Activity"
                  minHeight={375}
                  minWidth={385}
                >
                  <RecentActivitySection />
                </SummaryPageCard>
              </Grid>
            </Grid>
          </LayoutContainer>

          {/* Column 2 */}
          <LayoutContainer {...column2Props}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SummaryPageCard title="Data Summary" minHeight={615}>
                  <DataSummarySection />
                </SummaryPageCard>
              </Grid>

              <Grid item xs={12}>
                <SummaryPageCard title="Top 5 Configurations" minHeight={375}>
                  <ConfigurationSummarySection />
                </SummaryPageCard>
              </Grid>

              {/* TODO: Uncomment when source section is implemented 
            <SummaryPageCard title="Top 5 Destinations">
              <DestinationsSummarySection />
            </SummaryPageCard> */}
            </Grid>
          </LayoutContainer>
        </LayoutContainer>
      </Box>
    </SummaryPageContextProvider>
  );
};

const GridContainer: React.FC<GridProps> = ({ children, ...props }) => {
  return <Grid {...props}>{children}</Grid>;
};

const FlexContainer: React.FC<StackProps> = ({ children, ...props }) => {
  return <Stack {...props}>{children}</Stack>;
};

export const SummaryPage = withRequireLogin(
  withRBAC(withEENavBar(SummaryPageContent)),
);
