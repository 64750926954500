import { ChevronDown } from "../../Icons";

import styles from "../snap-shot-console.module.scss";

interface ToggleProps {
  bindplaneID: string;
}

export const Toggle: React.FC<ToggleProps> = ({ bindplaneID }) => {
  return (
    <div className={styles["ch-open"]} style={{ display: "flex" }}>
      <div className={styles.num}>{bindplaneID}</div>
      <ChevronDown className={styles.chevron} />
    </div>
  );
};
