import { Stack, CircularProgress, colors, Typography } from "@mui/material";
import { useState } from "react";
import {
  useGetAuditEventsQuery,
  GetAuditEventsQuery,
  AuditEventAction,
} from "../../../../graphql/generated";
import { trimVersion } from "../../../../utils/version-helpers";
import { useSnackbar } from "notistack";
import { SearchLink } from "../../../../components/SearchLink";

type Activity = { timestamp: Date; message: JSX.Element }[];

/**
 * RecentActivitySection displays the 5 most recent audit events.
 *
 * @returns JSX.Element
 */
export const RecentActivitySection: React.FC = () => {
  const [recentActivity, setRecentActivity] = useState<Activity>([]);

  const { enqueueSnackbar } = useSnackbar();

  const { loading } = useGetAuditEventsQuery({
    variables: {
      sort: "-timestamp",
    },
    onCompleted(data) {
      const messages = makeMessages(data.auditEvents);
      setRecentActivity(messages);
    },
    onError(error) {
      console.error(error);
      enqueueSnackbar("Failed to load recent activity", {
        variant: "error",
      });
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return (
      <Stack
        height={40}
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack>
      {recentActivity.map(({ timestamp, message }, index) => (
        <Stack
          key={`${timestamp.toISOString()}-${index}`}
          borderTop={`1px solid ${colors.grey[400]}`}
          padding={"4px"}
        >
          {message}
          <Typography variant="caption" color="textSecondary">
            {timestamp.toLocaleString()}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

function makeMessages(
  auditEvents: GetAuditEventsQuery["auditEvents"],
): Activity {
  const activity: Activity = [];
  for (const event of auditEvents) {
    const { user } = event;

    const resourceName = trimVersion(event.resourceName);

    var action = event.action?.toLowerCase();
    if (action === AuditEventAction.Pending) {
      action = "created";
    }

    let resourceKind = event.resourceKind?.toLowerCase();

    if (resourceKind === "rollout") {
      resourceKind += " for configuration";
    }

    let forConfig = <></>;
    switch (resourceKind) {
      case "source":
      case "destination":
      case "processor":
        if (event.configuration) {
          forConfig = (
            <>
              {" in configuration "}
              <SearchLink
                path={`/configurations/${trimVersion(event.configuration)}`}
                displayName={trimVersion(event.configuration)}
              />
            </>
          );
        }
    }

    activity.push({
      timestamp: new Date(event.timestamp),
      message: (
        <Typography fontSize={14}>
          {user} {action} {resourceKind.toLowerCase()}{" "}
          {resourceKind === "rollout for configuration" ? (
            <SearchLink
              path={`/configurations/${resourceName}`}
              displayName={resourceName}
            />
          ) : (
            resourceName
          )}
          {forConfig}
        </Typography>
      ),
    });

    if (activity.length >= 5) {
      break;
    }
  }

  return activity;
}
