import { Stack, CircularProgress } from "@mui/material";
import { YamlEditor } from "../YamlEditor";

interface YamlOrLoadingProps {
  value?: null | string;
}
export const YamlOrLoading: React.FC<YamlOrLoadingProps> = ({ value }) => {
  if (value == null) {
    return (
      <Stack
        width="100%"
        height="100%"
        minHeight={200}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Stack>
    );
  }
  return <YamlEditor value={value} readOnly />;
};
