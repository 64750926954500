import {
  Alert,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { gql } from "@apollo/client";
import { useState } from "react";
import {
  useFindApiKeyQuery,
  FindApiKeyQuery,
  useNewApiKeyMutation,
} from "../../../graphql/generated";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import CopyToClipboard from "react-copy-to-clipboard";

import { CopyIcon } from "../../../components/Icons";
import { CodeBlock } from "../../../components/CodeBlock";

import styles from "./account-page.module.scss";

type APIKey = FindApiKeyQuery["findApiKey"];

gql`
  query findApiKey {
    findApiKey {
      id
      createdAt
    }
  }

  mutation newApiKey {
    createAPIKey {
      key
      configureCommand
    }
  }
`;

export const APIKeyTab: React.FC = () => {
  const [apiKey, setApiKey] = useState<APIKey>();
  const [newKey, setNewKey] = useState<string>();
  const [configureCommand, setConfigureCommand] = useState<string>();
  const [dialogOpen, setDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { refetch } = useFindApiKeyQuery({
    onCompleted: (data) => {
      setApiKey(data.findApiKey);
    },
    onError(error) {
      console.log(error);
      enqueueSnackbar("Error retrieving API key", { variant: "error" });
    },
  });

  const [generateNewApiKey] = useNewApiKeyMutation({
    fetchPolicy: "network-only",
    onCompleted(data) {
      setNewKey(data.createAPIKey.key);
      setConfigureCommand(data.createAPIKey.configureCommand);
      setDialogOpen(true);
      refetch();
    },
    onError(error) {
      console.log(error);
      enqueueSnackbar("Error generating new API key", { variant: "error" });
    },
  });

  const loading = apiKey === undefined;

  async function handleGenerateNewAPIKey() {
    await generateNewApiKey();
  }

  return (
    <>
      <Stack>
        <Typography className={styles["tab-header"]} marginBottom={2}>
          API Key
        </Typography>
        <Card>
          <CardContent>
            <Stack>
              {loading && (
                <Stack
                  flexGrow={1}
                  width="100%"
                  height="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Stack>
              )}

              {!loading && (
                <Stack alignItems={"flex-start"} spacing={2}>
                  {apiKey && (
                    <Typography fontSize={18}>
                      You have an existing API Key created{" "}
                      {formatCreatedAt(apiKey.createdAt)}.
                    </Typography>
                  )}

                  {!apiKey && (
                    <Typography fontSize={18}>
                      You do not have an active API Key.
                    </Typography>
                  )}

                  <Button variant="contained" onClick={handleGenerateNewAPIKey}>
                    Generate New API Key
                  </Button>
                </Stack>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Stack>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        TransitionProps={{
          onExited: () => setNewKey(undefined),
        }}
      >
        <DialogTitle>New API Key</DialogTitle>
        <DialogContent>
          <Typography marginBottom={2}>
            Here is your new API Key. Make sure to copy it now as you will not
            have access to it again.
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            marginBottom={2}
          >
            <TextField
              fullWidth
              variant="outlined"
              value={newKey}
              InputProps={{
                readOnly: true,
              }}
            />

            <CopyToClipboard
              text={newKey ?? ""}
              onCopy={() =>
                enqueueSnackbar("API Key copied!", { variant: "success" })
              }
            >
              <IconButton color="primary">
                <CopyIcon />
              </IconButton>
            </CopyToClipboard>
          </Stack>

          <Typography marginBottom={2}>
            Use the following command to set your API Key in the BindPlane CLI:
          </Typography>

          <Alert severity="warning">
            <strong>
              BindPlane version v1.20.0 or greater is required for API Key
              authentication.
            </strong>
          </Alert>
          <CodeBlock
            value={configureCommand ?? ""}
            paperClass={styles.code}
          ></CodeBlock>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            variant="outlined"
            color="secondary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export function formatCreatedAt(date: string) {
  return format(new Date(date), "MM/dd/yyyy");
}
