import { FormHelperText, Stack, TextField } from "@mui/material";
import { isFunction } from "lodash";
import { ChangeEvent, memo } from "react";
import { ParamInputProps } from "./ParameterInput";
import { useValidationContext } from "../ValidationContext";
import { validateFractionField } from "../validation-functions";

const FractionParamInputComponent: React.FC<
  ParamInputProps<number | string>
> = ({ definition, value, readOnly, onValueChange }) => {
  const { errors, setError, touch, touched } = useValidationContext();

  function handleValueChange(e: ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value;
    const newNumberValue = Number(newValue);

    setError(
      definition.name,
      validateFractionField(definition, newNumberValue),
    );

    if (isFunction(onValueChange)) {
      isNaN(newNumberValue)
        ? onValueChange(newValue)
        : onValueChange(newNumberValue);
    }
  }

  return (
    <TextField
      value={value ?? ""}
      onChange={handleValueChange}
      name={definition.name}
      fullWidth
      disabled={readOnly}
      size="small"
      label={definition.label}
      onBlur={() => touch(definition.name)}
      FormHelperTextProps={{
        sx: { paddingLeft: "-2px" },
      }}
      helperText={
        <>
          {errors[definition.name] && touched[definition.name] && (
            <FormHelperText sx={{ marginLeft: 0 }} component="span" error>
              {errors[definition.name]}
            </FormHelperText>
          )}
          <FormHelperText sx={{ marginLeft: 0 }} component="span">
            {definition.description}
          </FormHelperText>
          {definition.documentation && (
            <Stack component={"span"}>
              {definition.documentation.map((d) => (
                <a href={d.url} rel="noreferrer" target="_blank" key={d.url}>
                  {d.text}
                </a>
              ))}
            </Stack>
          )}
        </>
      }
      required={definition.required}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      data-testid={`fraction-param-input-${definition.name}`}
    />
  );
};

export const FractionParamInput = memo(FractionParamInputComponent);
