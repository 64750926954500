import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";

interface LimitConnectedAgentsProps extends DialogProps {
  onClose: () => void;
  children: JSX.Element;
  title?: string;
}

export const LimitConnectedAgentsDialog: React.FC<
  LimitConnectedAgentsProps
> = ({ onClose, children, title, ...dialogProps }) => {
  return (
    <Dialog {...dialogProps}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
