import { createTheme, ThemeOptions } from "@mui/material/styles";
import colors from "../styles/colors";
import { ChevronRight } from "../components/Icons";

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: colors.pixelPointBlue,
    },
    secondary: {
      main: colors.darkGray,
    },
    trace: {
      main: "#54b359",
      contrastText: colors.white,
    },
    debug: {
      main: "#54b399",
      contrastText: colors.white,
    },
    warning: {
      main: "#d68e57",
      contrastText: colors.white,
    },
    fatal: {
      main: "#82392b",
      contrastText: colors.white,
    },
  },
  typography: {
    allVariants: {
      color: colors.black,
    },
    fontFamily: "'Nunito Sans', sans-serif;",
    fontWeightBold: 600,
    button: {
      fontWeight: 700,
    },
  },

  components: {
    MuiPaper: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "0.3rem",
          boxShadow: "none",
          ":hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colors.middleLightGray,
          color: colors.pixelPointBlue,
          height: 10,
          borderRadius: 2,
        },
        barColorPrimary: {
          backgroundColor: colors.pixelPointBlue,
          borderRadius: 2,
        },
      },
    },
    MuiBreadcrumbs: {
      defaultProps: {
        separator: <ChevronRight width="18px" color={colors.middleDarkGray} />,
      },
    },
  },
};

export const theme = createTheme(themeOptions);

// Declarations to extend the theme and component interfaces
declare module "@mui/material/styles" {
  interface Palette {
    debug: Palette["primary"];
    trace: Palette["primary"];
    fatal: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    debug?: PaletteOptions["primary"];
    trace: PaletteOptions["primary"];
    fatal: PaletteOptions["primary"];
  }
}

// Update the Chip's color prop options
declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    fatal: true;
    debug: true;
    trace: true;
  }
}
