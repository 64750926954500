import { Typography, Stack, Button } from "@mui/material";
import { RBACWrapper } from "../../contexts/RBAC";
import { Role } from "../../graphql/generated";

import { classes } from "../../utils/styles";
import mixins from "../../styles/mixins.module.scss";

export interface AgentTableControlsProps {
  setEditing: (editing: boolean) => void;
  configsAvailable: boolean;
  isContainerized: boolean;
  supportsRemoteConfig: boolean;
}

export const AgentTableControls: React.FC<AgentTableControlsProps> = ({
  setEditing,
  configsAvailable,
  isContainerized,
  supportsRemoteConfig,
  children,
}) => {
  return (
    <Typography
      variant="h5"
      classes={{ root: mixins["mb-3"] }}
      fontWeight={400}
    >
      Details
      <Stack
        direction="row"
        display={"inline"}
        style={{ float: "right" }}
        spacing={1}
      >
        {!isContainerized && configsAvailable && supportsRemoteConfig && (
          <RBACWrapper requiredRole={Role.User}>
            <Button
              className={classes([mixins["ml-2"]])}
              variant="outlined"
              size="small"
              onClick={() => setEditing(true)}
              data-testid="choose-another-configuration"
            >
              Change Configuration
            </Button>
          </RBACWrapper>
        )}
        {children}
      </Stack>
    </Typography>
  );
};
