import { ApolloError, NetworkStatus, gql } from "@apollo/client";
import { useMemo, useState } from "react";
import {
  PipelineType,
  ResourceConfiguration,
  SnapshotProcessedQuery,
  useSnapshotProcessedQuery,
  useSnapshotSearchSupportedQuery,
} from "../graphql/generated";

import { SnapshotContext } from "../components/SnapShotConsole/SnapshotContext";
import { v4 } from "uuid";
import { escapeRegExp } from "lodash";

// while the query includes all three pipeline types, only the pipelineType specified will have results
gql`
  query snapshotProcessed(
    $agentID: String!
    $pipelineType: PipelineType!
    $position: String
    $resourceName: String
    $processorsJSON: String
    $searchQuery: String
    $uuid: String!
  ) {
    snapshotProcessed(
      agentID: $agentID
      pipelineType: $pipelineType
      position: $position
      resourceName: $resourceName
      processorsJSON: $processorsJSON
      searchQuery: $searchQuery
      uuid: $uuid
    ) {
      metrics {
        name
        timestamp
        value
        unit
        type
        attributes
        resource
      }
      logs {
        timestamp
        body
        severity
        attributes
        resource
      }
      traces {
        name
        traceID
        spanID
        parentSpanID
        start
        end
        attributes
        resource
      }
      processedMetrics {
        name
        timestamp
        value
        unit
        type
        attributes
        resource
      }
      processedLogs {
        timestamp
        body
        severity
        attributes
        resource
      }
      processedTraces {
        name
        traceID
        spanID
        parentSpanID
        start
        end
        attributes
        resource
      }
    }
  }
`;

export type Metric = SnapshotProcessedQuery["snapshotProcessed"]["metrics"][0];
export type Log = SnapshotProcessedQuery["snapshotProcessed"]["logs"][0];
export type Trace = SnapshotProcessedQuery["snapshotProcessed"]["traces"][0];

export interface SnapshotContextValue {
  logs: Log[];
  metrics: Metric[];
  traces: Trace[];

  setLogs(logs: Log[]): void;
  setMetrics(metrics: Metric[]): void;
  setTraces(traces: Trace[]): void;

  processedLogs: Log[];
  processedMetrics: Metric[];
  processedTraces: Trace[];

  setProcessedLogs(logs: Log[]): void;
  setProcessedMetrics(metrics: Metric[]): void;
  setProcessedTraces(traces: Trace[]): void;

  // true during initial loading and refetching
  loading: boolean;

  // true if a dropdown of agents should be included
  showAgentSelector: boolean;

  // footer is displayed under the snapshot telemetry
  footer: string;
  setFooter(footer: string): void;

  // processedFooter is displayed under the processed telemetry
  processedFooter: string;
  setProcessedFooter(footer: string): void;

  error?: ApolloError;
  setError(error: ApolloError): void;

  agentID?: string;
  setAgentID(agentID: string | undefined): void;

  pipelineType: PipelineType;
  setPipelineType(type: PipelineType): void;

  refresh: () => void;

  // track open snapshot rows
  openRowIDs: string[];
  toggleRow: (id: string) => void;
}

export interface SnapshotProviderProps {
  pipelineType: PipelineType;
  agentID?: string;
  showAgentSelector?: boolean;
  position?: "s0" | "d0";
  resourceName?: string;
  processors?: ResourceConfiguration[];
}

export const EESnapshotContextProvider: React.FC<SnapshotProviderProps> = ({
  children,
  pipelineType: initialPipelineType,
  agentID: initialAgentID,
  showAgentSelector,
  position,
  resourceName,
  processors,
}) => {
  const [pipelineType, setPipelineType] =
    useState<PipelineType>(initialPipelineType);

  const [logs, setLogs] = useState<Log[]>([]);
  const [metrics, setMetrics] = useState<Metric[]>([]);
  const [traces, setTraces] = useState<Trace[]>([]);

  const [processedLogs, setProcessedLogs] = useState<Log[]>([]);
  const [processedMetrics, setProcessedMetrics] = useState<Metric[]>([]);
  const [processedTraces, setProcessedTraces] = useState<Trace[]>([]);

  const [agentID, setAgentID] = useState<string | undefined>(initialAgentID);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [snapshotSearchSupported, setSnapshotSearchSupported] =
    useState<boolean>(false);
  const [searchRegex, setSearchRegex] = useState<RegExp | undefined>();

  const [footer, setFooter] = useState<string>("");
  const [processedFooter, setProcessedFooter] = useState<string>("");

  useSnapshotSearchSupportedQuery({
    variables: { agentID: agentID ?? "" },
    onCompleted: (data) => {
      setSnapshotSearchSupported(data.snapshotSearchSupported);
    },
  });
  const [error, setError] = useState<ApolloError>();

  const processorsJSON = useMemo(() => {
    if (processors == null) {
      return "[]";
    }
    return JSON.stringify(processors);
  }, [processors]);

  const [uuid] = useState<string>(v4());

  const { loading, refetch, networkStatus } = useSnapshotProcessedQuery({
    variables: {
      agentID: agentID ?? "",
      pipelineType,
      position,
      resourceName,
      processorsJSON,
      searchQuery,
      uuid,
    },
    skip: agentID == null,
    onCompleted: (data) => {
      const { snapshotProcessed } = data;
      setLogs(snapshotProcessed.logs.slice().reverse());
      setMetrics(snapshotProcessed.metrics.slice().reverse());
      setTraces(snapshotProcessed.traces.slice().reverse());
      setProcessedLogs(snapshotProcessed.processedLogs.slice().reverse());
      setProcessedMetrics(snapshotProcessed.processedMetrics.slice().reverse());
      setProcessedTraces(snapshotProcessed.processedTraces.slice().reverse());
      setError(undefined);
      setOpenRowIDs([]);
      updateFooterCounts(data);
      if (searchQuery && searchQuery.length > 0) {
        setSearchRegex(new RegExp(`(${escapeRegExp(searchQuery)})`, "g"));
      } else {
        setSearchRegex(undefined);
      }
    },
    onError: (error) => {
      setError(error);
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const anyLoading = useMemo(
    () => loading || networkStatus === NetworkStatus.refetch,
    [loading, networkStatus],
  );

  function updateFooterCounts(data: SnapshotProcessedQuery) {
    const {
      logs,
      metrics,
      traces,
      processedLogs,
      processedMetrics,
      processedTraces,
    } = data.snapshotProcessed;
    var beforeCount, afterCount;
    switch (pipelineType) {
      case PipelineType.Logs:
        beforeCount = logs.length;
        afterCount = processedLogs.length;
        break;
      case PipelineType.Metrics:
        beforeCount = metrics.length;
        afterCount = processedMetrics.length;
        break;
      case PipelineType.Traces:
        beforeCount = traces.length;
        afterCount = processedTraces.length;
        break;
    }
    setFooter(`Showing ${beforeCount} recent ${pipelineType}`);
    setProcessedFooter(
      `Showing ${afterCount} ${pipelineType} after processing`,
    );
  }

  // track open snapshot rows
  const [openRowIDs, setOpenRowIDs] = useState<string[]>([]);
  const toggleRow = (rowID: string): void => {
    if (openRowIDs.includes(rowID)) {
      setOpenRowIDs(openRowIDs.filter((id) => id !== rowID));
    } else {
      setOpenRowIDs([...openRowIDs, rowID]);
      // opened, scroll both into view
      document.querySelectorAll(`[data-row-id="${rowID}"]`).forEach((el) => {
        el.scrollIntoView({ behavior: "smooth", block: "start" });
      });
    }
  };

  return (
    <SnapshotContext.Provider
      value={{
        logs,
        metrics,
        traces,

        setLogs,
        setMetrics,
        setTraces,

        processedLogs,
        processedMetrics,
        processedTraces,

        setProcessedLogs,
        setProcessedMetrics,
        setProcessedTraces,

        loading: anyLoading,
        showAgentSelector: showAgentSelector ?? false,

        footer,
        setFooter,

        processedFooter,
        setProcessedFooter,

        error,
        setError,

        agentID,
        setAgentID,

        searchQuery,
        setSearchQuery,
        snapshotSearchSupported,
        searchRegex,

        pipelineType,
        setPipelineType: (type: PipelineType) => {
          setPipelineType(type);
          setFooter("Searching...");
          setProcessedFooter("Searching...");
        },
        refresh: () => refetch({ agentID: agentID ?? "", pipelineType }),

        openRowIDs,
        toggleRow,
      }}
    >
      {children}
    </SnapshotContext.Provider>
  );
};
