import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useWizard } from "../../../../components/Wizard/WizardContext";
import { PlusCircleIcon } from "../../../../components/Icons";
import {
  DialogResource,
  NewResourceDialog,
} from "../../../../components/ResourceDialog";
import { useState } from "react";
import { gql } from "@apollo/client";
import {
  DestinationType,
  Kind,
  ParameterDefinition,
  ResourceConfiguration,
  SourceType,
  useSourcesAndTypesQuery,
} from "../../../../graphql/generated";
import { AssistedWizardFormValues } from ".";
import { EditResourceDialog } from "../../../../components/ResourceDialog/EditResourceDialog";
import { ConfirmDeleteResourceDialog } from "../../../../components/ConfirmDeleteResourceDialog";
import { classes } from "../../../../utils/styles";
import { BPResourceConfiguration } from "../../../../utils/classes/resource-configuration";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { trimVersion } from "../../../../utils/version-helpers";

import styles from "./assisted-config-wizard.module.scss";
import mixins from "../../../../styles/mixins.module.scss";

type ResourceType = SourceType | DestinationType;

gql`
  query sourcesAndTypes {
    sourceTypes {
      apiVersion
      kind
      metadata {
        id
        name
        version
        displayName
        description
        icon
        additionalInfo {
          message
          documentation {
            text
            url
          }
        }
        resourceDocLink
      }
      spec {
        parameters {
          name
          label
          description
          relevantIf {
            name
            operator
            value
          }
          documentation {
            text
            url
          }
          advancedConfig
          required
          type
          validValues
          default
          options {
            creatable
            multiline
            trackUnchecked
            sectionHeader
            subHeader
            horizontalDivider
            gridColumns
            labels
            metricCategories {
              label
              column
              metrics {
                name
                description
                kpi
              }
            }
            password
            sensitive
          }
        }
        supportedPlatforms
        version
        telemetryTypes
      }
    }
    sources {
      metadata {
        id
        version
        name
      }
      spec {
        type
        parameters {
          name
          value
        }
        disabled
      }
    }
  }
`;

export const StepTwo: React.FC = (props) => {
  const { formValues, setValues, goToStep } =
    useWizard<AssistedWizardFormValues>();

  const [open, setOpen] = useState(false);
  const [editingSourceIx, setEditingSourceIx] = useState(-1);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { data } = useSourcesAndTypesQuery({
    onError() {
      enqueueSnackbar(
        "There was an error retrieving Sources and SourceTypes.",
        {
          variant: "error",
        },
      );
    },
  });

  function onSave(values: { [name: string]: any }, sourceType: ResourceType) {
    const sourceConfig = new BPResourceConfiguration();
    sourceConfig.setParamsFromMap(values);
    sourceConfig.type = sourceType.metadata.name;

    const sources = [...formValues.sources, sourceConfig];
    setValues({ sources: sources });
    setOpen(false);
  }

  function onEditSourceSave(values: { [key: string]: any }) {
    const newSource = new BPResourceConfiguration(
      formValues.sources[editingSourceIx],
    );

    // Replace the parameters with edited values
    newSource.setParamsFromMap(values);

    const newSources = [...formValues.sources];
    newSources[editingSourceIx] = newSource;

    setValues({ sources: newSources });
    setEditingSourceIx(-1);
  }

  function deleteSelectedSource() {
    const newSources = [...formValues.sources];
    newSources.splice(editingSourceIx, 1);

    setValues({ sources: newSources });
  }

  function onSourceRemove() {
    setRemoveModalOpen(false);
    deleteSelectedSource();
    setEditingSourceIx(-1);
  }

  function renderSourceAccordion(
    s: ResourceConfiguration,
    index: number,
  ): JSX.Element {
    const sourceType = data?.sourceTypes.find(
      (st: SourceType) => st.metadata.name === s.type,
    );

    if (sourceType == null) {
      // TODO (dsvanlani) error toast and exit
      console.error(
        `sourceType not found for source ${s.name} of type ${s.type}`,
      );
      return <></>;
    }
    const displayName = sourceType.metadata.displayName;
    const icon = sourceType.metadata.icon;

    return (
      <Accordion
        key={`accordion-${index}-${s.type}`}
        data-testid="source-accordion"
      >
        <AccordionSummary>
          <Stack direction={"row"} alignItems="center" spacing={1}>
            <span
              className={styles.icon}
              style={{ backgroundImage: `url(${icon})` }}
            />
            <Typography fontWeight={600}>{s.name || displayName}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Table>
            <TableBody>
              {s.parameters?.map((param, ix) => {
                const definition = sourceType.spec.parameters.find(
                  (def: ParameterDefinition) => def.name === param.name,
                );
                const label = definition?.label ?? param.name;
                const type = definition?.type;

                if (param.value == null) return null;
                return (
                  <TableRow key={`accordion-${index}-${param.name}-${ix}`}>
                    <TableCell
                      key={`accordion-${index}-${param.name}-${ix}-key`}
                      width={"20%"}
                    >
                      {label}
                    </TableCell>
                    <TableCell
                      key={`accordion-${index}-${param.name}-${ix}-value`}
                      classes={{ root: styles["break-word-cell"] }}
                    >
                      {type === "map" ? (
                        Object.entries(param.value).map(([k, v], j) => (
                          <Typography
                            key={`accordion-${index}-${param.name}-${ix}-value-node-${j}`}
                            fontSize={13}
                            fontFamily="monospace"
                          >
                            {k}: {v}
                          </Typography>
                        ))
                      ) : (
                        <span
                          key={`accordion-${index}-${param.name}-${ix}-value-node`}
                        >
                          {String(param.value)}
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Stack
            direction="row"
            className={classes([mixins["float-right"], mixins["my-2"]])}
          >
            <Button
              color="error"
              onClick={() => {
                setRemoveModalOpen(true);
              }}
            >
              Remove
            </Button>
            <Button onClick={() => setEditingSourceIx(index)}>Edit</Button>
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
  }

  function openResourceDialog() {
    setOpen(true);
  }

  const editingSourceType = findSourceType(
    formValues.sources[editingSourceIx]?.type,
    data?.sourceTypes,
  );

  function onChooseExistingSource(resource: DialogResource) {
    const sourceConfig = new BPResourceConfiguration();
    sourceConfig.disabled = resource.spec.disabled ?? false;
    sourceConfig.name = resource.metadata.name;
    sourceConfig.type = trimVersion(resource.spec.type);
    sourceConfig.parameters = resource.spec.parameters;

    const sources = [...formValues.sources, sourceConfig];
    setValues({ sources: sources });
    setOpen(false);
  }

  function findDisplayName() {
    return formValues?.sources[editingSourceIx]?.parameters?.find((p) => {
      return p.name === "displayName";
    })?.value;
  }

  function resourceInLibrary(): boolean {
    return formValues?.sources[editingSourceIx]?.name ? true : false;
  }

  return (
    <>
      <div className={styles.container} data-testid="step-two">
        {/* ---------------------------------- Copy ---------------------------------- */}
        <Stack spacing={2} marginBottom={2}>
          <Typography variant="h6" fontWeight={600}>
            Add Sources from which you'd like to collect telemetry.
          </Typography>
          <Typography>
            A Source is a combination of OpenTelemetry receivers and processors
            that allows you to collect telemetry from a specific technology.
            Ensuring the right combination of these components is one of the
            most challenging aspects of building an OpenTelemetry configuration
            file. With BindPlane, we handle that all for you.
          </Typography>
        </Stack>

        <div>
          <div className={mixins["mb-3"]}>
            {formValues.sources.map((s, ix) => renderSourceAccordion(s, ix))}
          </div>
          <Button
            variant="contained"
            endIcon={<PlusCircleIcon />}
            onClick={openResourceDialog}
            data-testid="add-source-button"
          >
            Add Source
          </Button>

          <NewResourceDialog
            platform={
              isEmpty(formValues.secondaryPlatform)
                ? formValues.platform
                : formValues.secondaryPlatform
            }
            title="Choose a Source"
            kind={Kind.Source}
            open={open}
            onClose={() => setOpen(false)}
            resourceTypes={data?.sourceTypes ?? []}
            resources={data?.sources ?? []}
            onSaveNew={onSave}
            onSaveExisting={onChooseExistingSource}
          />
        </div>
      </div>

      <EditResourceDialog
        resourceTypeDisplayName={
          formValues?.sources[editingSourceIx]?.name ??
          editingSourceType?.metadata.displayName ??
          ""
        }
        displayName={
          formValues?.sources[editingSourceIx]?.displayName ??
          findDisplayName() ??
          ""
        }
        description={editingSourceType?.metadata.description ?? ""}
        parameterDefinitions={editingSourceType?.spec.parameters ?? []}
        additionalInfo={editingSourceType?.metadata.additionalInfo}
        resourceDocLink={editingSourceType?.metadata.resourceDocLink ?? ""}
        fullWidth
        maxWidth="sm"
        parameters={formValues.sources[editingSourceIx]?.parameters ?? []}
        open={editingSourceIx !== -1}
        onClose={() => setEditingSourceIx(-1)}
        onCancel={() => {
          setEditingSourceIx(-1);
        }}
        onDelete={() => setRemoveModalOpen(true)}
        onSave={onEditSourceSave}
        kind={Kind.Source}
        // If resource is in library (is reusable), don't allow editing and do show that it's in library
        readOnly={resourceInLibrary()}
        showLibraryBookmark={resourceInLibrary()}
        resourceNameField={formValues?.sources[editingSourceIx]?.name ?? ""}
        libraryResources={data?.sources}
      />

      <ConfirmDeleteResourceDialog
        open={removeModalOpen}
        onDelete={onSourceRemove}
        onCancel={() => setRemoveModalOpen(false)}
        action="remove"
      >
        <Typography>Are you sure you want to remove this source?</Typography>
      </ConfirmDeleteResourceDialog>

      <Stack direction={"row"} justifyContent="space-between">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => goToStep(0)}
        >
          Back
        </Button>
        <Button variant="contained" onClick={() => goToStep(2)}>
          Next
        </Button>
      </Stack>
    </>
  );
};

function findSourceType(type?: string | null, sourceTypes?: SourceType[]) {
  if (sourceTypes == null || type == null) {
    return;
  }
  return sourceTypes.find((st) => st.metadata.name === type);
}
